import ProtectedRoute from "app/utils/ProtectedRoute";
import VendorFollowupList from "./components/VendorFollowupList";
import MainContentVendor from "./components/VendorScore/pages/demo/MainContent/MainContent";
import VendorFollowUp from "./VendorFollowUp";
import Vendor2AData from "./components/Vendor2AData";
import GSTIntegrationDashBoard from "./components/BhilosaDashboard";
import VendorEInvoiceData from "./components/VendorE-InvoiceData/VendorE-InvoiceData";
import InvitePage from "./components/InvitePage/InvitePage";
import BhilosaExistingUser from "./components/BhilosaExistingUserPage/BhilosaExistingUser";
import MemberSetting from "./components/AddMember/AddMember";
import NavbarPage from "./components/Navbar/Navbar";
import { Settings } from "@mui/icons-material";
import Settings_page from "./components/setting";
import VendorManagementTAble from "./components/Vendor2AData";
// import EmailConfirmation from "./components/EmailConfirmation/EmailConfirmation";

//eslint-disable-next-line
export default {
  path: "/vendorfollowup",
  element: (
    <ProtectedRoute>
      <VendorFollowUp />
    </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "/vendorfollowup/gstintegration",
      element: <GSTIntegrationDashBoard />,
      exact: true,
    },
    {
      path: "/vendorfollowup/gstintegration/settings",
      element: <MemberSetting />,
      exact: true,
    },
    {
      path: "/vendorfollowup/vendorlist",
      element: <VendorFollowupList />,
      exact: true,
    },
    // {
    //   path: "/vendorfollowup/vendorscore",
    //   element: <MainContentVendor />,
    //   exact: true,
    // },
    {
      path: "/vendorfollowup/vendor2A",
      element: <Vendor2AData />,
      exact: true,
    },
    {
      path: "/vendorfollowup/vendoreinvoice",
      element: <VendorEInvoiceData/>,
      exact: true,
    },
    {
      path: "/vendorfollowup/invitepage",
      element: <InvitePage/>,
      exact: true,
    },
    {
      path: "/vendorfollowup/existingusers",
      element: <BhilosaExistingUser/>,
      exact: true,
    },
    {
      path: "/vendorfollowup/memebersetting",
      element: <NavbarPage/>,
      exact: true,
    },
    {
      path: "/vendorfollowup/vendormangementtable",
      element: <VendorManagementTAble/>,
      exact: true,
    },
    
    
  ],
};
