export const API_ENDPOINT_SETTING_WORKSPACE_LIST = `${process.env.REACT_APP_API_URL}/meta/workspace/listinfo`;
export const API_ENDPOINT_SETTING_SEARCH_MEMBERS = `${process.env.REACT_APP_API_URL}/meta/user/search`;
export const API_ENDPOINT_SETTING_INVITE_MEMBER = `${process.env.REACT_APP_API_URL}/auth/user/internal/create`;
export const API_ENDPOINT_SETTING_VERIFY_INVITE = `${process.env.REACT_APP_API_URL}/auth/invite/verify`;
export const API_ENDPOINT_SETTING_INVITE_SIGNUP = `${process.env.REACT_APP_API_URL}/auth/invite/register`;
export const API_ENDPOINT_SETTING_MEMBERS_LIST = `${process.env.REACT_APP_API_URL}/meta/user/list`;
export const API_ENDPOINT_SETTING_INVITE_MEMBER_CREATE = `${process.env.REACT_APP_API_URL}/auth/user/internal/invitecreate`;
export const API_ENDPOINT_FETCH_DB_TABLE_PREVIEW = `${process.env.REACT_APP_API_URL}/finalytics/tables/data/preview`;
export const API_ENDPOINT_UPDATE_WORKSPACE_META_EMAIL = `${process.env.REACT_APP_API_URL}/meta/workspace/meta/email`;
export const API_ENDPOINT_SETTING_INVITE_MEMBERV2 = `${process.env.REACT_APP_API_URL}/auth/user/internal/create`;
export const API_ENDPOINT_SETTING_MEMBER_REMOVE_FROM_WORKSPACE = `${process.env.REACT_APP_API_URL}/meta/user/workspace/map/remove/`;
export const API_ENDPOINT_SETTING_MEMBER_UPDATE_WORKSPACE_ROLE = `${process.env.REACT_APP_API_URL}/meta/user/workspace/role/update/`;
export const API_ENDPOINT_SETTING_WORKSPACE_CREDS_LIST = `${process.env.REACT_APP_API_URL}/meta/workspace/mmt/creds`;
export const API_ENDPOINT_SETTING_INVITE_MEMBER_LEGACY = `${process.env.REACT_APP_API_URL}/auth/user/internal/createlegacy`;
export const API_ENDPOINT_SETTING_WORKSPACE_CREDS_ADD = `${process.env.REACT_APP_API_URL}/meta/workspace/mmt/creds`;
export const API_ENDPOINT_SETTING_WORKSPACE_CREDS_REMOVE = `${process.env.REACT_APP_API_URL}/meta/workspace/mmt/creds/`;
export const API_ENDPOINT_SETTING_WORKSPACE_REPORT_ADD = `${process.env.REACT_APP_API_URL}/meta/workspace/report/add`;
export const API_ENDPOINT_SETTING_MEMBERS_LIST_ALL = `${process.env.REACT_APP_API_URL}/meta/user/list/all`;
export const API_ENDPOINT_SETTING_REMOVE_WORKSPACE_FROM_GROUP = `${process.env.REACT_APP_API_URL}/meta/workspace/group/remove/`;
