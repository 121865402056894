import React from "react";
import "./ContactPopup.css"; // Create a CSS file for styling

const ContactPopup = ({ message, emoji, onClose }) => {
    return (
        <div className="popup-overlay">
          <div className="popup-container">
            <span className="popup-close" onClick={onClose}>
              &times;
            </span>
            <div className="popup-content">
              <h2>{emoji} {message}</h2>
              <p>Thank you for adding the contact. Your contribution is appreciated!</p>
              <button className="popup-button" onClick={onClose}>
                Continue
              </button>
            </div>
          </div>
        </div>
      );
};

export default ContactPopup;
