import TextField from "app/shared/TextField";
import React, { useEffect, useState } from "react";
import "./CreateWorkspace.scss";
import { Avatar, Button, Popover, Select, Tag, message } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";
import {
  EditOutlined,
  CloseOutlined,
  ArrowRightOutlined,
} from "@ant-design/icons";
import MemberSearch from "app/shared/MemberSearch";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { API_ENDPOINT_SETTING_INVITE_MEMBER } from "../../settings.constants";
import {
  API_ENDPOINT_ONBOARDING_CREATE_WORKSPACE,
  API_ENDPOINT_ONBOARDING_UPDATE_WORKSPACE,
} from "app/scenes/Onboarding/onboarding.constants";
import AddBusiness from "app/scenes/Onboarding/components/AddBusiness";
import { useNavigate } from "react-router-dom";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import DynamicChipInput from "app/shared/DynamicChipInput";
import { MarginTwoTone, Preview } from "@mui/icons-material";

export default function UpdateWorkspace(props: any) {
  const [workspaceName, setWorkspaceName] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [isLoading, setLoading] = useState(false);
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [allowedDomain, setAllowedDomain] = useState([]);
  useEffect(() => {
    if (props.workspace) {
      setWorkspaceName(props.workspace.name);
      setAllowedDomain(props.workspace.domains);
    }
  }, [props.workspace]);

  const fetchUserInfo = async () => {
    const response = await apiGet(API_ENDPOINT_GET_USER_INFO);
    if (response.status) {
      setUserDetails({
        ...userDetails,
        workspaces: response.data.workspaces,
      });
    }
  };

  const handleUpdateWorkspace = async () => {
    setLoading(true);
    const response: any = await apiPut(
      API_ENDPOINT_ONBOARDING_UPDATE_WORKSPACE + props.workspace.id,
      {
        name: workspaceName,
        domains: allowedDomain,
      }
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      // setWorkspaceCreated(true);
      // fetchUserInfo();
      props.onSuccess && props.onSuccess();
      setUserDetails({
        ...userDetails,
        currentWorkspace: {
          ...userDetails.currentWorkspace,
          name: workspaceName,
        },
      });
    } else {
      messageApi.error({
        type: "error",
        content: response.message || "Something went wrong",
      });
    }
    setLoading(false);
  };

  return (
    <div className="CreateWorkspace">
      <div className="workspaceBasicInfo">
        <div style={{ width: "100%" }}>
          <TextField
            style={{ height: 40 }}
            placeholder="Workspace Name (eg Acme Corporation)"
            value={workspaceName}
            defaultValue={workspaceName}
            onChange={(e: any) => setWorkspaceName(e.target.value)}
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "flex-start",
            flexDirection: "column",
            width: "100%",
            marginTop: 12,
          }}
        >
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.800"), marginBottom: 6 }}
          >
            Allowed Domain
          </Typography>
          <DynamicChipInput
            onChange={(data: any) => setAllowedDomain(data)}
            emailData={allowedDomain}
          />
        </div>

        <Button
          size="large"
          style={{ marginTop: 24 }}
          type="primary"
          loading={isLoading}
          onClick={handleUpdateWorkspace}
        >
          Update
        </Button>
      </div>

      {contextHolder}
    </div>
  );
}
