import PageHeader from "app/shared/PageHeader";
import { useEffect, useRef, useState } from "react";
import "./SchemaSourceDetail.scss";
import { colorPicker } from "app/utils/color.helper";
import { useNavigate, useParams } from "react-router-dom";
import { Button, Input, List, message, Select } from "antd";
import { API_ENDPOINT_FETCH_DB_TABLES } from "app/scenes/FinkCRM/fink.crm.constants";
import { apiGet } from "app/services/apiServices";
import Loader from "app/shared/Loader";
import EmptyState from "app/shared/EmptyState";
import { AgTableClient } from "app/shared/AgTable";
import { DatabaseOutlined, SelectOutlined} from "@ant-design/icons";
import SearchInput from "app/shared/SearchInput";
import { AnyARecord } from "dns";

export default function SchemaSourceDetail(props: any) {
  const [sourceData, setSourceData] = useState<any>(null);
  const [isLoading, setLoading] = useState(false);
  const [selectedDatabase, setSelectedDatabase] = useState("");
  const [zaId, setZAId] = useState("");
  const [ZaViewId, setZaViewId] = useState("");
  const [reportType, setReportType] = useState("");

  let { sourceId } = useParams();
  const [selectedTableName, setSelectedTableName] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const navigate = useNavigate();
  const gridRef: any = useRef();
  useEffect(() => {
    if (sourceId === "zohoanalytics") return;
    fetchTableOFDB();
  }, []);
  useEffect(() => {
    if (selectedTableName) {
      console.log(selectedTableName, "*********");
    
    }
  }, [selectedTableName]); 
  const fetchTableOFDB = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_DB_TABLES);
    console.log("response in finkanalytics",response)
    if (response.status) {
      setSourceData(response.data);
    }
    setLoading(false);
  };
  console.log(selectedDatabase,"selected database")

  const sourceNameMap: any = {
    postgre: "Postgre",
    mongodb: "MongoDB",
    zohoanalytics: "Zoho Analytics",
  };

  const getTableDataProcessed = (data: any) => {
    let rowMainData: any = [];
    data.forEach((element: any, key: any) => {
      rowMainData.push({
        sno: key + 1,
        table_name: element,
      });
    });
    return rowMainData;
  };

  const handleSearchWorkspace = (query: string) => {
    if (gridRef.current && gridRef.current.onTableSearch) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  const handleZASchema = () => {
    if (zaId && ZaViewId && reportType) {
      navigate("/FA/schema/setup", {
        state: {
          zaId: zaId,
          zaViewId: ZaViewId,
          sourceType: sourceId,
          reportType: reportType,
        },
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Please enter your valid ZA Info",
      });
    }
  };
  console.log("selectedTableName",selectedTableName)


  return (
    <div className="SchemaSourceDetail">
      <div className="Header">
        <PageHeader
          goBack
          title={`${sourceNameMap?.[sourceId || ""]} Configuration`}
          rightActions={
            <SearchInput
              placeholder="Search Table"
              onSearch={handleSearchWorkspace}
            />
          }
        />
      </div>

      <div className="MainContent">
        {isLoading ? (
          <Loader />
        ) : sourceId !== "zohoanalytics" ? (
          <>
            <div className="DatabaseListContainer">
              <List
                size="small"
                dataSource={
                  Object.keys(sourceData?.[sourceId || ""] || {}) || []
                }
                renderItem={(item) => (
                  <List.Item onClick={() => setSelectedDatabase(item)}>
                    <DatabaseOutlined
                      style={{
                        marginRight: 4,
                        color: colorPicker("neutral.800"),
                      }}
                    />
                    {item}
                  </List.Item>
                )}
              />
            </div>
            <div className="TableViewContainer">
              {selectedDatabase ? (
                <AgTableClient
                  //@ts-ignore
                  rowData={getTableDataProcessed(
                    sourceData?.[sourceId || ""]?.[selectedDatabase] || []
                  )}
                  ref={gridRef}
                  hideToolbar
                  columnDefs={[
                    { field: "sno", headerName: "S.NO" },
                    // { field: "table_name", headerName: "Table Name" },
                    {
                      field: "table_name",
                      headerName: "Table Name",
                      cellRenderer: (params: any) => {
                        console.log(params.data.table_name,"params")
                        return (
                          <div style={{ display: 'flex', alignItems: 'center', justifyContent:"space-between" }}>
                            <div>
                            <span>{params?.data?.table_name}</span>
                            </div>
                            <div>
                            <SelectOutlined
                              style={{ marginLeft: 10, fontSize: '16px', cursor: 'pointer' }} 
                              onClick={() => {
                                const dataBase=selectedDatabase
                                // navigate(`/FA/schema/source/detail/table/${params?.data?.table_name}`);
                                const tableName=params.data.table_name
                                setSelectedTableName(tableName);
                                console.log(selectedTableName,"************")
                                // navigate(`/FA/schema/source/detail/table/:${params?.data?.table_name}`);
                                navigate(`/FA/schema/source/detail/table/${dataBase}/${tableName}`);
                              
                              }}
                            />
                            </div>
                          </div>
                        );
                      },
                    },
                    {
                      field: "actions",
                      headerName: "Actions",
                      cellRenderer: (params: any) => {
                        return (
                          <div>
                            <Button
                              type="primary"
                              size="small"
                              onClick={() =>{
                                
                                navigate("/FA/schema/setup", {
                                  state: {
                                    dbType: sourceId,
                                    database: selectedDatabase,
                                    table: params?.data?.table_name,
                                  },
                                })
                              }
                            }
                            >
                              Schema View
                            </Button>
                          </div>
                        );
                      },
                    },
                    
                  ]
                  
                }

                  autoResize
                />
              ) : (
                <EmptyState />
              )}
            </div>
          </>
        ) : (
          <div className="ThirdPartySourceContainer">
            <Input
              style={{ width: 400, marginBottom: 12 }}
              placeholder="Enter your ZA Table Name"
              onChange={(e: any) => setZAId(e.target.value)}
            />
            <Input
              style={{ width: 400, marginBottom: 12 }}
              placeholder="Enter your ZA View ID"
              onChange={(e: any) => setZaViewId(e.target.value)}
            />

            <Select
              options={[
                {
                  value: "AIRLINE",
                  label: "Airline Recon",
                },
                {
                  value: "HOTEL",
                  label: "Hotel Recon",
                },
                {
                  value: "OTHER",
                  label: "Other",
                },
              ]}
              style={{ width: 400, marginBottom: 12 }}
              onChange={(dataType: string) => setReportType(dataType)}
              placeholder="Select Report Type"
            />
            <Button
              type="primary"
              style={{ marginLeft: 8 }}
              onClick={handleZASchema}
            >
              Generate Schema
            </Button>
          </div>
        )}
      </div>
      {contextHolder}
    </div>
  );
}
