import { apiDelete, apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import {
  forwardRef,
  useEffect,
  useId,
  useImperativeHandle,
  useRef,
  useState,
} from "react";

import Loader from "app/shared/Loader";
import { PlusOutlined, ExportOutlined, CopyOutlined } from "@ant-design/icons";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Avatar,
  Button,
  Empty,
  Modal,
  Popconfirm,
  Popover,
  Spin,
  Tabs,
  Tag,
  message,
} from "antd";
import SearchInput from "app/shared/SearchInput";
import Typography from "app/shared/Typography";
import "../../Settings.scss";
import PageHeader from "app/shared/PageHeader";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import TableViewSelector from "app/shared/TableViewSelector";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import {
  API_ENDPOINT_SETTING_MEMBER_REMOVE_FROM_WORKSPACE,
  API_ENDPOINT_SETTING_MEMBERS_LIST,
  API_ENDPOINT_SETTING_WORKSPACE_CREDS_LIST,
  API_ENDPOINT_SETTING_WORKSPACE_CREDS_REMOVE,
  API_ENDPOINT_SETTING_WORKSPACE_LIST,
} from "../../settings.constants";
import { colorPicker } from "app/utils/color.helper";
import TextField from "app/shared/TextField";
import InviteMember from "../InviteMember";
import UpdateMember from "../UpdateMember";
import InviteMemberV2 from "../InviteMemberV2";
import { useNavigate } from "react-router-dom";
const WorkspaceCredential = forwardRef((props: any, ref: any) => {
  const [tableData, setTableData] = useState([]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [removingUser, setRemovingUser] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);

  const copyDataToBoard = (text: string) => {
    navigator?.clipboard?.writeText(text);
    messageApi.open({
      type: "success",
      content: "Copied",
    });
  };

  const handleMoveToEditPage = async (workspaceInfo: any) => {
    let newWorksapceData = { ...userDetails, currentWorkspace: workspaceInfo };
    setUserDetails(JSON.parse(JSON.stringify(newWorksapceData)));
    localStorage.setItem("currentWorkspace", workspaceInfo.id);
    navigate("/settings/workspace/meta");
  };

  const initialColDefs = [
    {
      field: "organisation_id",
      headerName: "Organisation Id",
      enableRowGroup: true,
      minWidth: 240,
    },

    {
      field: "organisation_name",
      headerName: "Organisation Name",
      enableRowGroup: true,
      minWidth: 240,
    },

    {
      field: "expense_client_id",
      headerName: "Expense Client Id",
      enableRowGroup: true,
      minWidth: 240,
    },

    {
      field: "external_org_id",
      headerName: "External Org Id",
      enableRowGroup: true,
      minWidth: 240,
    },
    // {
    //   field: "admin_emails",
    //   headerName: "Admin Emails",
    //   enableRowGroup: true,
    //   minWidth: 200,
    //   cellRenderer: (params: any) => {
    //     return (
    //       <div style={{ width: "100%", height: "100%" }}>
    //         {(params.data.admin_emails || []).map((item: any, key: any) => {
    //           return <Tag key={item}>{item}</Tag>;
    //         })}
    //       </div>
    //     );
    //   },
    // },
    {
      field: "status",
      headerName: "Status",
      enableRowGroup: true,
      minWidth: 240,
      cellRenderer: (params: any) => {
        return (
          <div style={{ width: "100%", height: "100%" }}>
            <Tag color={params.data?.status === "PENDING" ? "yellow" : "green"}>
              {params.data?.status}
            </Tag>
          </div>
        );
      },
    },
  ];

  const removeCredentialFromWorkspace = async (credentalId: string) => {
    setRemovingUser(credentalId);
    const response = await apiDelete(
      API_ENDPOINT_SETTING_WORKSPACE_CREDS_REMOVE + credentalId
    );

    if (response.status) {
      message.success("Credential removed from workspace");
      const result = tableData.filter((item: any) => item.id !== credentalId);
      setTableData(result); // Update state with filtered data
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setRemovingUser("");
  };

  useEffect(() => {
    fetchCredentialsList();
  }, [userDetails.currentWorkspace]);

  const fetchCredentialsList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_SETTING_WORKSPACE_CREDS_LIST);

    if (response.status) {
      setTableData(response.data);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleMemberShipSuccess = () => {
    setShowModal(false);
    fetchCredentialsList();
  };

  useImperativeHandle(ref, () => ({
    fetchCredentialsList() {
      fetchCredentialsList();
    },
  }));
  const handleSearchWorkspace = (query: string) => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  console.log("userDetails:", userDetails);
  return (
    <div className="ScreenContainer">
      <div className="TableContainer">
        {isLoading ? (
          <Loader />
        ) : tableData.length === 0 ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty description="No credential has been added to this workspace,please add from above button" />
          </div>
        ) : userDetails.account_type === "PARTNER" &&
          userDetails.currentWorkspace.role === "GENERAL" ? (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              width: "100%",
              height: "100%",
              justifyContent: "center",
            }}
          >
            <Empty description="You don't have permission to view credential of this workspace" />
          </div>
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={tableData}
            columnDefs={[
              ...initialColDefs,

              ...(userDetails.currentWorkspace?.role !== ""
                ? [
                    {
                      field: "action",
                      headerName: "Action",
                      cellRenderer: (params: any) => {
                        return (
                          <div
                            style={{
                              width: "100%",
                              display: "flex",
                              alignItems: "center",
                              height: "100%",
                            }}
                          >
                            <Popconfirm
                              title="Remove credential from workspace"
                              description="Are you sure want to remove?"
                              onConfirm={() =>
                                removeCredentialFromWorkspace(params.data?.id)
                              }
                              okText="Yes"
                              cancelText="No"
                            >
                              <Button
                                size="small"
                                danger
                                loading={removingUser === params.data?.id}
                              >
                                Remove
                              </Button>
                            </Popconfirm>
                          </div>
                        );
                      },
                    },
                  ]
                : []),
            ]}
            groupPanel
            // onRowSaved={handleRowEdit}
            ref={gridRef}
            footer={false}
            autoResize
            showStatusBar
          />
        )}
      </div>

      <Modal
        title={showModalFor === "CREATE" ? "Add Members" : "Edit Member Detail"}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        {showModalFor === "CREATE" ? (
          <InviteMemberV2
            onClose={() => setShowModal(false)}
            onSuccess={handleMemberShipSuccess}
          />
        ) : (
          <UpdateMember
            data={selectedUser}
            success={() => {
              fetchCredentialsList();
              setShowModalFor("");
              setShowModal(false);
            }}
            onClose={() => setShowModal(false)}
          />
        )}
      </Modal>
      {contextHolder}
    </div>
  );
});

export default WorkspaceCredential;
