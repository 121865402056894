import TextField from "app/shared/TextField";
import React, { useState } from "react";
import {
  Avatar,
  Button,
  Checkbox,
  Input,
  Popover,
  Select,
  Tag,
  message,
} from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";

import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiPost } from "app/services/apiServices";
import DynamicChipInput from "app/shared/DynamicChipInput";
import {
  API_ENDPOINT_SETTING_WORKSPACE_CREDS_ADD,
  API_ENDPOINT_SETTING_WORKSPACE_REPORT_ADD,
} from "app/scenes/Settings/settings.constants";

export default function AddWorksapceReport(props: any) {
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState<any>(
    userInfo || { workspaces: [] }
  );
  const [reportType, setReportType] = useState("LEGACY");
  const [reportURL, setReportURL] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [reportName, setReportName] = useState("");
  const [addForAll, setAddForAll] = useState(false);
  const [criteriaKey, setCriteriaKey] = useState("");
  console.log(userDetails);

  const { Option } = Select;
  const handleAddReport = async () => {
    const payload = {
      report_name: reportName,
      report_url: reportURL,
      report_type: reportType,
      all_workspace: addForAll,
      criteriaKey: criteriaKey,
    };

    const response = await apiPost(
      API_ENDPOINT_SETTING_WORKSPACE_REPORT_ADD,
      payload
    );
    if (response.status) {
      message.success("Report has been added to the worksapce");
      setReportName("");
      setReportURL("");
      setReportType("");
      props.onSuccess && props.onSuccess();
    } else {
      message.error("Could not add report to workspace");
    }
  };
  return (
    <div className="InviteMember">
      <div className="AddUserContainer">
        <div style={{ marginBottom: 12 }}>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
          >
            Report Type
          </Typography>
          <Select
            defaultValue={reportType}
            style={{
              width: "100%",
            }}
            onChange={(item: string) => setReportType(item)}
            options={[
              {
                value: "LEGACY",
                label: "Legacy",
              },
              {
                value: "NEW",
                label: "New",
              },
            ]}
          />
        </div>
        <div style={{ marginBottom: 12 }}>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
          >
            Report Name
          </Typography>
          <Input
            placeholder="Report Name"
            onChange={(e: any) => setReportName(e.target.value)}
          />
        </div>

        <div>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
          >
            Report URL
          </Typography>
          <Input
            placeholder="Report URL"
            onChange={(e: any) => setReportURL(e.target.value)}
          />
        </div>
        {reportType === "LEGACY" ? (
          <div style={{ marginTop: 12 }}>
            <Typography
              variant="xs"
              style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
            >
              Zoho Criteria Key (ie: for data filter)
            </Typography>
            <Input
              placeholder="Zoho Criteria Key"
              onChange={(e: any) => setCriteriaKey(e.target.value)}
            />
          </div>
        ) : null}
      </div>
      <div style={{ marginTop: 12 }}>
        <Checkbox onChange={() => setAddForAll(!addForAll)}>
          Add For All Workspace
        </Checkbox>
      </div>

      <div className="BottomAction">
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          type="primary"
          style={{ marginLeft: 6 }}
          onClick={handleAddReport}
          loading={isLoading}
        >
          Add
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
