import TextField from "app/shared/TextField";
import React, { useState } from "react";
import { Avatar, Button, Input, Popover, Select, Tag, message } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";
import {
  EditOutlined,
  CloseOutlined,
  DeleteTwoTone,
  ClusterOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import MemberSearch from "app/shared/MemberSearch";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiPost } from "app/services/apiServices";
import DynamicChipInput from "app/shared/DynamicChipInput";
import { API_ENDPOINT_SETTING_WORKSPACE_CREDS_ADD } from "app/scenes/Settings/settings.constants";
const OPTIONS = [
  "Apples",
  "Nails",
  "Bananas",
  "Helsicopters",
  "Appales",
  "Naisls",
  "Bansanas",
  "Heliacopters",
];
// const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

export default function AddWorkspaceCredential(props: any) {
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState<any>(
    userInfo || { workspaces: [] }
  );
  const [expenseId, setExpenseId] = useState("");
  const [expenseOrgId, setExpenseOrgId] = useState("");
  const [isLoading, setLoading] = useState(false);
  const [adminEmails, setAdminEmails] = useState<any[]>([]);
  const [orgId, setOrgId] = useState("");
  const [orgName, setOrgName] = useState("");

  console.log(userDetails);

  const { Option } = Select;
  const handleAddCredential = async () => {
    if (!expenseId || !expenseOrgId) {
      message.error(
        "Please provide the neccessary information to add your credential [Expense Org Id, Expense Client Id]"
      );
      return;
    }
    const payload = {
      expenseClientId: expenseId,
      expenseOrgId: expenseOrgId,
      emails: adminEmails,
      orgId: orgId,
      orgName: orgName,
    };
    const response = await apiPost(
      API_ENDPOINT_SETTING_WORKSPACE_CREDS_ADD,
      payload
    );
    if (response.status) {
      message.success("Credential has been added to the worksapce");
      setExpenseId("");
      setExpenseOrgId("");
      setAdminEmails([]);
      props.onSuccess && props.onSuccess();
    } else {
      message.error("Could not add credential to workspace");
    }
    console.log("payload:", payload);
  };
  return (
    <div className="InviteMember">
      <Typography variant="xs" style={{ marginBottom: 24 }}>
        <b>*</b> Add MMT Booking API credentials to fetch data for{" "}
        <b>{userDetails.currentWorkspace?.name}</b>
      </Typography>
      <div className="AddUserContainer">
        <div style={{ marginBottom: 12 }}>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
          >
            Organisation Name
          </Typography>
          <Input
            value={orgName}
            placeholder="Organisation Name"
            onChange={(e: any) => setOrgName(e.target.value)}
          />
        </div>

        <div style={{ marginBottom: 12 }}>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
          >
            Organisation ID
          </Typography>
          <Input
            value={orgId}
            placeholder="Organisation ID"
            onChange={(e: any) => setOrgId(e.target.value)}
          />
        </div>

        <div style={{ marginBottom: 12 }}>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
          >
            Expense Client ID
          </Typography>
          <Input
            value={expenseId}
            placeholder="Expense Client ID"
            onChange={(e: any) => setExpenseId(e.target.value)}
          />
        </div>

        <div>
          <Typography
            variant="xs"
            style={{ color: colorPicker("neutral.800"), marginBottom: 3 }}
          >
            External Org ID
          </Typography>
          <Input
            placeholder="Expense Org ID"
            value={expenseOrgId}
            onChange={(e: any) => setExpenseOrgId(e.target.value)}
          />
        </div>
      </div>

      {/* <div
        style={{
          display: "flex",
          justifyContent: "flex-start",
          flexDirection: "column",
          width: "100%",
          marginTop: 12,
        }}
      >
        <Typography
          variant="xs"
          style={{ color: colorPicker("neutral.800"), marginBottom: 6 }}
        >
          Admin Emails
        </Typography>
        <DynamicChipInput
          onChange={(data: any) => setAdminEmails(data)}
          emailData={adminEmails}
          title="Add Email"
          type="EMAIL"
        />
      </div> */}

      <div className="BottomAction">
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          type="primary"
          style={{ marginLeft: 6 }}
          onClick={handleAddCredential}
          loading={isLoading}
        >
          Add
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
