import './Header.scss'

const Header = () => {
  return (
    <div className="vendorHeader">
  <div className="logo">
  <svg xmlns="http://www.w3.org/2000/svg" width="166" height="35" viewBox="0 0 166 35" fill="none">
  <path d="M21.0208 34.2886H16.1038V13.8262H7.50808V34.2886H2.57733V13.8262H0V10.3217H2.57733V9.77966C2.57733 8.91156 2.57733 7.77706 2.66873 6.95948C3.12113 2.50414 6.06404 0.000887425 12.9826 0.000887425C14.0293 -0.0100823 15.0745 0.0806713 16.1038 0.271882V4.0015C10.0443 3.54219 8.00618 4.54809 7.64517 7.2167C7.5532 7.97872 7.50741 8.74565 7.50808 9.51326V10.3308H21.0208V34.2886Z" fill="#03A5B7"/>
  <path d="M48.8207 20.6858V34.2885H43.8411V21.2501C43.8411 20.1169 43.8411 19.0709 43.7497 17.846C43.5256 15.1208 42.0761 13.2123 38.6832 13.2123C35.2904 13.2123 32.4325 15.9374 32.4325 23.4752V34.2885H27.4575V10.1706H32.4325V14.1666L34.0649 11.4139C35.9671 9.95958 37.723 9.55127 40.1282 9.55127C45.2906 9.55127 48.2308 12.2305 48.6835 16.4559C48.7749 17.6625 48.8207 18.7543 48.8207 20.6858Z" fill="#03A5B7"/>
  <path d="M85.1812 9.80559V10.7232C80.847 10.3562 77.8177 13.311 77.8177 21.212V34.2885H76.8276V10.3929H77.8177V15.5271L78.7666 12.8017C79.9204 10.9275 81.9492 9.77914 84.141 9.75971C84.4883 9.75096 84.8359 9.76629 85.1812 9.80559Z" fill="#A3A6B7"/>
  <path d="M103.455 28.4245L102.41 30.833C100.734 33.5306 97.9216 34.9069 94.7016 34.9069C90.1671 34.9069 87.373 32.7736 87.373 28.4842C87.373 22.901 94.4497 20.4925 101.884 20.4925H103.427V19.3088C103.427 18.1711 103.427 17.2627 103.336 16.2168C103.019 12.6292 100.661 10.7116 96.7582 10.7116C93.4054 10.7116 89.8235 12.4824 89.2785 16.9324H88.28C88.5548 12.9366 92.045 9.75732 96.7582 9.75732C101.471 9.75732 104.013 12.0512 104.334 16.0699C104.426 17.2949 104.426 17.9325 104.426 19.2033V26.5436C104.426 29.2686 104.558 31.1312 104.833 34.2646H103.789L103.455 28.4245ZM103.409 24.7911V21.5247C96.4238 21.0705 88.4586 23.0203 88.4586 28.4842C88.4586 31.801 90.4968 33.9894 94.8711 33.9894C99.9598 33.9894 103.409 29.5164 103.409 24.814V24.7911Z" fill="#A3A6B7"/>
  <path d="M112.288 6.79715V10.6306H117.124V11.5856H112.288V34.357H111.31V11.5718H108.734V10.6306H111.31V6.79715C111.3 5.99422 111.33 5.19127 111.402 4.39147C111.671 1.38895 113.685 0.0713283 115.512 0.0713283C116.227 0.0517628 116.941 0.128972 117.636 0.300879V1.25122C114.11 0.388108 112.612 2.11892 112.393 4.52461C112.318 5.27975 112.283 6.03831 112.288 6.79715Z" fill="#A3A6B7"/>
  <path d="M127.908 33.6954V34.4941C127.148 34.5926 126.381 34.6386 125.614 34.6318C123.48 34.6318 122.347 33.6311 122.163 31.5425C122.071 30.45 122.071 29.6789 122.071 28.3293V11.4277H119.212V10.6244H122.071V5.70361H123.072V10.6244H127.775V11.4415H123.095V28.5267C123.095 29.8028 123.095 30.4822 123.14 31.1202C123.319 33.4659 124.366 34.1957 127.908 33.6954Z" fill="#A3A6B7"/>
  <path d="M134.686 32.7883C134.695 33.6677 134.173 34.4654 133.365 34.8063C132.557 35.1472 131.624 34.9635 131.004 34.3415C130.384 33.7196 130.201 32.7832 130.541 31.9724C130.881 31.1616 131.675 30.6377 132.552 30.647C133.122 30.6343 133.672 30.8558 134.075 31.2602C134.477 31.6645 134.698 32.2166 134.686 32.7883Z" fill="#03A5B7"/>
  <path d="M165.5 6.8397C165.506 7.39385 165.158 7.89643 164.62 8.11121C164.082 8.32599 163.46 8.21024 163.047 7.81838C162.634 7.42652 162.513 6.83649 162.739 6.32563C162.965 5.81477 163.495 5.4847 164.078 5.49056C164.458 5.48255 164.824 5.62212 165.093 5.87689C165.361 6.13166 165.508 6.47949 165.5 6.8397Z" fill="#A3A6B7"/>
  <path d="M164.602 33.9459L164.602 34.4145H164.106H163.539C163.539 34.4145 163.539 29.4935 163.539 28.2042V12.0573V11.5801H163.696H163.963H164.573V12.0704V28.3927C164.573 29.6119 164.555 33.102 164.602 33.7116V33.9459Z" fill="#A3A6B7"/>
  <path d="M154.991 28.3845L153.94 30.7717C152.254 33.4453 149.425 34.8094 146.186 34.8094C141.624 34.8094 138.814 32.6951 138.814 28.4436C138.814 22.9099 145.932 20.5228 153.41 20.5228H154.963V19.3497C154.963 18.222 154.963 17.3217 154.871 16.285C154.553 12.7292 152.18 10.8286 148.254 10.8286C144.882 10.8286 141.279 12.5837 140.73 16.9943H139.726C140.002 13.0339 143.513 9.88281 148.254 9.88281C152.996 9.88281 155.553 12.1563 155.875 16.1395C155.967 17.3535 155.967 17.9856 155.967 19.2451V26.5203C155.967 29.2212 156.107 31.5199 156.383 34.6255H155.318L154.991 28.3845ZM154.945 24.7833V21.5458C147.918 21.0957 139.906 23.0282 139.906 28.4436C139.906 31.7311 141.956 33.9 146.356 33.9C151.475 33.9 154.945 29.4667 154.945 24.806V24.7833Z" fill="#A3A6B7"/>
  <path d="M56.383 24.3727C56.3621 24.3916 56.3502 24.4185 56.3502 24.4467V33.9403C56.3502 33.9955 56.3054 34.0403 56.2502 34.0403H55.4594C55.4041 34.0403 55.3594 33.9955 55.3594 33.9403V1.19766C55.3594 1.14243 55.4041 1.09766 55.4594 1.09766H56.2502C56.3054 1.09766 56.3502 1.14243 56.3502 1.19766V23.0118C56.3502 23.0988 56.4536 23.1443 56.5177 23.0856L70.2704 10.4902C70.2888 10.4733 70.3129 10.4639 70.3379 10.4639H71.5262C71.6177 10.4639 71.6611 10.5765 71.5934 10.638L59.2657 21.8225C59.2228 21.8614 59.2218 21.9286 59.2636 21.9687L71.6515 33.8682C71.7165 33.9306 71.6723 34.0403 71.5822 34.0403H70.3847C70.3589 34.0403 70.3341 34.0303 70.3155 34.0125L58.4446 22.629C58.4067 22.5927 58.3471 22.5918 58.3082 22.6271L56.383 24.3727Z" fill="#03A5B7"/>
  <path d="M72.3437 13.1977L63.0362 21.9732C63.0046 22.003 63.0039 22.053 63.0345 22.0837L63.4012 22.4505L72.3784 31.304C72.3928 31.3182 72.4122 31.3261 72.4325 31.3261H73.3592C73.4278 31.3261 73.4621 31.2432 73.4136 31.1947L64.3022 22.0836C64.2716 22.053 64.2722 22.0033 64.3034 21.9735L73.3712 13.3094C73.4214 13.2614 73.3875 13.1768 73.318 13.1768H72.3965C72.3769 13.1768 72.358 13.1843 72.3437 13.1977Z" fill="#A3A6B7"/>
</svg>
  </div>
  {/* <div className="help">
    <span>Having trouble? <a href="#">Get help</a></span>
  </div> */}
</div>

  )
}

export default Header