import { atom } from "recoil";

export const workspaceInfo: any = atom({
  key: "workspaceInfo",
  default: null,
});

// Atom for storing 2A GST data
export const gst2ADataState = atom({
  key: "gst2ADataState", // Unique ID for the atom
  default: null, // Default value (when not yet fetched)
});

export const gst2BDataState = atom({
  key: "gst2BDataState", // Unique ID for the atom
  default: null, // Default value (when not yet fetched)
});

export const emailInfo: any = atom({
  key: "emailInfo",
  default: null,
});

export const userInfo: any = atom({
  key: "userInfo",
  default: {},
});

export const workspaceReports: any = atom({
  key: "workspaceReports",
  default: {},
});

export const onboardInfo: any = atom({
  key: "onboardInfo",
  default: {},
});

export const tabViewStates: any = atom({
  key: "tabViewStates",
  default: {
    dashboard: [],
    gst_recon: [
      {
        title: "GST Recon",
        id: 1,
      },
    ],
    air_recon: [
      {
        title: "Air Recon",
        id: 1,
      },
    ],
    reports: [
      {
        title: "Reports",
        id: 1,
        type: "TABLE",
      },
    ],
    invoices: [
      {
        title: "Invoice Explorer",
        id: 1,
        type: "VIEW",
      },
    ],
  },
});

export const uploadInfo: any = atom({
  key: "uploadInfo",
  default: null,
});

export const uploadProgressInfo: any = atom({
  key: "uploadProgressInfo",
  default: null,
});

export const currentPDFData: any = atom({
  key: "currentPDFData",
  default: "",
});
