import Typography from "app/shared/Typography";
import "./GSTAddDone.scss";
import { colorPicker } from "app/utils/color.helper";
import { Carousel } from "react-responsive-carousel";
import AirOnboarIllustration1 from "static/images/illustration/AirOnboardIllustration1.svg";
import doneIllustration from "static/images/doneIllustration.svg";
import { useState } from "react";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import AppLogo from "static/images/FinLogo.png";
import Logo from "static/images/Finkr@ftLogo.png";
import { getSubdomain } from "app/utils/generic.helper";

export default function GSTAddDone(props: any) {
  const navigate = useNavigate();
  const handleBack = () => {
    navigate(-1);
  };

  const handleRedirection = () => {
    const domain = getSubdomain();
    if (domain !== "bhilosa" && domain !== "followup") {
      navigate("/integrations");
    } else {
      navigate("/credential/gst/landing");
    }
  };
  return (
    <div className="GSTAddDone">
      {/* <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginTop: "5px",
        }}
        onClick={handleBack}
      >
        <div style={{ cursor: "pointer" }}>Back</div>
      </div> */}
      <div className="TopLogo">
        <img alt="AppLogo" src={Logo} />
        {/* <div className="MiniDivder" /> */}
      </div>
      <Typography
        style={{
          marginBottom: "1px",
          color: colorPicker("neutral.700"),
          textAlign: "right",
        }}
      >
        Having trouble?{" "}
        <a style={{ color: colorPicker("primary.700"), cursor: "pointer" }}>
          Get help
        </a>
      </Typography>
      <div className="MainContainer">
        <div className="SuccessBlockContainer">
          <img src={doneIllustration} alt="doneIllustration" />
          <Typography
            style={{ marginTop: "24px", color: "#3F4047", fontSize: "24px" }}
            variant="h6"
          >
            GST Integration succesful!
          </Typography>
          <Typography
            style={{
              marginTop: 17,
              color: colorPicker("neutral.900"),
              textAlign: "center",
              fontSize: "16px",
              fontWeight: "400",
            }}
            variant="caption"
          >
            Please give us some time to calculate your claimable amount.
          </Typography>
          <Typography
            style={{
              color: colorPicker("neutral.800"),
              marginTop: 20,
              fontSize: "16px",
            }}
          >
            Help us, to know about your organization
          </Typography>
          <Button
            style={{
              marginTop: 24,
              width: "480px",
              height: "56px",
              fontSize: "20px",
            }}
            type="primary"
            onClick={handleRedirection}
          >
            Continue
          </Button>
        </div>
      </div>
    </div>
  );
}
