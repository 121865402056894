import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import { AgTableClient } from "app/shared/AgTable";
import { useEffect, useId, useRef, useState } from "react";
import { CaretRightOutlined } from "@ant-design/icons";

import Loader from "app/shared/Loader";
import { PlusOutlined, UserOutlined, EditOutlined } from "@ant-design/icons";
import CircleIcon from "@mui/icons-material/Circle";
import {
  Avatar,
  Button,
  Collapse,
  Empty,
  Modal,
  Popconfirm,
  Popover,
  Spin,
  Tabs,
  Tag,
  message,
  theme,
} from "antd";
import SearchInput from "app/shared/SearchInput";
import Typography from "app/shared/Typography";
import "../../Settings.scss";
import PageHeader from "app/shared/PageHeader";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import TableViewSelector from "app/shared/TableViewSelector";
import WorkspacesOutlinedIcon from "@mui/icons-material/WorkspacesOutlined";
import {
  API_ENDPOINT_SETTING_MEMBER_REMOVE_FROM_WORKSPACE,
  API_ENDPOINT_SETTING_MEMBERS_LIST,
  API_ENDPOINT_SETTING_WORKSPACE_LIST,
} from "../../settings.constants";
import { colorPicker } from "app/utils/color.helper";
import TextField from "app/shared/TextField";
import InviteMember from "../InviteMember";
import UpdateMember from "../UpdateMember";
import InviteMemberV2 from "../InviteMemberV2";
import { useNavigate } from "react-router-dom";
import MemberSetting from "../MemberSetting";
import WorkspaceCredential from "../WorkspaceCredential";
import Reports from "app/scenes/Reports";
import AddWorkspaceCredential from "./components/AddWorkspaceCredential";
import AddWorksapceReport from "./components/AddWorksapceReport";
import UpdateWorkspace from "../UpdateWorkspace";

const modelTitleMap: any = {
  MEMBER: "Manage Workspace Member",
  CREDENTIAL: "Manage Workspace Credential",
  REPORT: "Manage Workspace Report",
};
export default function WorkspaceMetaSetting(props: any) {
  const [tableData, setTableData] = useState([]);
  const [columnDefs, setColumnDefs] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showModalFor, setShowModalFor] = useState("");
  const [messageApi, contextHolder] = message.useMessage();
  const [removingUser, setRemovingUser] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [selectedUser, setSelectedUser] = useState<any>(null);
  const [activeTab, setActiveTab] = useState("1");
  const navigate = useNavigate();
  const gridRef = useRef<any>(null);
  const memberRef = useRef<any>(null);
  const credentialRef = useRef<any>(null);
  const reportRef = useRef<any>(null);

  const { token } = theme.useToken();
  const panelStyle = {
    marginBottom: 24,
    background: token.colorFillAlter,
    borderRadius: token.borderRadiusLG,
    border: "none",
    boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px;",
  };
  const copyDataToBoard = (text: string) => {
    navigator?.clipboard?.writeText(text);
    messageApi.open({
      type: "success",
      content: "Copied",
    });
  };

  const handleMoveToEditPage = async (workspaceInfo: any) => {
    let newWorksapceData = { ...userDetails, currentWorkspace: workspaceInfo };
    setUserDetails(JSON.parse(JSON.stringify(newWorksapceData)));
    localStorage.setItem("currentWorkspace", workspaceInfo.id);
    navigate("/settings/workspace/meta");
  };

  const initialColDefs = [
    {
      field: "name",
      headerName: "Name",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => (
        <div
          onClick={() => {
            handleMoveToEditPage(params.data);
          }}
        >
          {params.data?.name}
        </div>
      ),
    },

    {
      field: "email",
      headerName: "Email",
      enableRowGroup: true,
      minWidth: 200,
    },
    {
      field: "role",
      headerName: "Workspace Role",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tag color="blue" style={{ display: "flex", alignItems: "center" }}>
              {params.data?.workspacerole || "GENERAL"}
            </Tag>
          </div>
        );
      },
    },
    {
      field: "account_type",
      headerName: "Account Level",
      enableRowGroup: true,
      minWidth: 200,
      cellRenderer: (params: any) => {
        return (
          <div
            style={{
              height: "100%",
              width: "100%",
              display: "flex",
              alignItems: "center",
            }}
          >
            <Tag
              color={params.data?.account_type === "PARTNER" ? "green" : "blue"}
              style={{ display: "flex", alignItems: "center" }}
            >
              {(params.data?.account_type === "PARTNER"
                ? "PARTNER LEVEL"
                : "CLIENT LEVEL") || "GENERAL"}
            </Tag>
          </div>
        );
      },
    },
  ];

  const removeUserFormWorkspace = async (userId: string) => {
    setRemovingUser(userId);
    const response = await apiPut(
      API_ENDPOINT_SETTING_MEMBER_REMOVE_FROM_WORKSPACE + userId
    );

    if (response.status) {
      message.success("User removed from workspace");
      const result = tableData.filter((item: any) => item.id !== userId);
      setTableData(result); // Update state with filtered data
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setRemovingUser("");
  };

  useEffect(() => {
    fetchUsersList();
  }, [userDetails.currentWorkspace]);

  const fetchUsersList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_SETTING_MEMBERS_LIST);

    if (response.status) {
      setTableData(response.data);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const handleMemberShipSuccess = () => {
    setShowModal(false);
    fetchUsersList();
  };

  const handleSearchWorkspace = (query: string) => {
    if (gridRef.current && gridRef.current.exportDataToExcel) {
      // Call the child component's function
      gridRef.current.onTableSearch({ target: { value: query } });
    }
  };

  console.log("userDetails:", userDetails);
  const modelContent: any = {
    WORKSPACE_UPDATE: (
      <UpdateWorkspace
        workspace={userDetails.currentWorkspace}
        onSuccess={() => {
          setShowModal(false);
          setShowModalFor("");
        }}
      />
    ),
    MEMBER: (
      <InviteMemberV2
        onClose={() => setShowModal(false)}
        onSuccess={() => {
          // handleMemberShipSuccess
          memberRef.current && memberRef.current.fetchUsersList();
          setShowModal(false);
          setShowModalFor("");
        }}
        workspaceId={userDetails.currentWorkspace?.id}
      />
    ),
    CREDENTIAL: (
      <AddWorkspaceCredential
        onSuccess={() => {
          credentialRef.current && credentialRef.current.fetchCredentialsList();
          setShowModal(false);
          setShowModalFor("");
        }}
      />
    ),
    REPORT: (
      <AddWorksapceReport
        onSuccess={() => {
          reportRef.current && reportRef.current.fetchReportList();
          setShowModal(false);
          setShowModalFor("");
        }}
      />
    ),
  };
  return (
    <div className="ScreenContainer">
      <PageHeader
        goBack
        rightActions={
          <div style={{ display: "flex", alignItems: "center" }}>
            <div style={{ marginRight: 12 }}>
              <SearchInput
                placeholder={`Search ${
                  activeTab == "1"
                    ? " members"
                    : activeTab === "2"
                    ? " credentials"
                    : "reports"
                }`}
                onSearch={handleSearchWorkspace}
              />
            </div>
            {(userDetails.currentWorkspace.role === "ADMIN" ||
              userDetails.account_type === "PARTNER") &&
            activeTab === "1" ? (
              <Button
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowModalFor("MEMBER");
                  setShowModal(true);
                }}
              >
                Add Members
              </Button>
            ) : activeTab === "2" ? (
              <Button
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowModalFor("CREDENTIAL");
                  setShowModal(true);
                }}
              >
                Add Credential
              </Button>
            ) : (
              <Button
                type="primary"
                size="small"
                icon={<PlusOutlined />}
                onClick={() => {
                  setShowModalFor("REPORT");
                  setShowModal(true);
                }}
              >
                Add Report
              </Button>
            )}
          </div>
        }
      />
      <div className="TableContainer">
        <div className="TabViewContainer">
          <div className="TabContainer">
            <div>
              <div
                style={{
                  height: "100%",
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Typography variant="h6" weight={600}>
                  {userDetails?.currentWorkspace?.name}
                </Typography>
                <EditOutlined
                  style={{ marginLeft: 6 }}
                  onClick={() => {
                    setShowModalFor("WORKSPACE_UPDATE");
                    setShowModal(true);
                  }}
                />
              </div>
              <Tabs
                defaultActiveKey="1"
                activeKey={activeTab}
                tabBarStyle={{
                  borderBottom: "none",
                  margin: 0,
                }}
                onChange={(tabItem: string) => setActiveTab(tabItem)}
                items={[
                  {
                    key: "1",
                    label: "Members",
                  },
                  {
                    key: "2",
                    label: "API Credentials",
                  },
                  ...(userDetails.email.includes("finkraft.ai") ||
                  userDetails.email.includes("kgrp.in")
                    ? [
                        {
                          key: "3",
                          label: "Reports",
                        },
                      ]
                    : []),
                ]}
              />
            </div>
          </div>
          <div className="TabContentContainer">
            {activeTab === "1" ? (
              <MemberSetting noHeader ref={memberRef} useWorkspace />
            ) : activeTab === "2" ? (
              <WorkspaceCredential ref={credentialRef} />
            ) : (
              <Reports noHeader ref={reportRef} />
            )}
          </div>
        </div>
      </div>

      <Modal
        title={modelTitleMap[showModalFor]}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        {modelContent[showModalFor]}
      </Modal>
      {contextHolder}
    </div>
  );
}
