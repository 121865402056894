import Dashboard from "app/scenes/Dashboard";
import MasterLayout from ".";
import ProtectedRoute from "app/utils/ProtectedRoute";
import credentialsRoutes from "app/scenes/Credentials/credentials.routes";
import reconcilationRoutes from "app/scenes/Reconcilation/reconcilation.routes";
import Invoices from "app/scenes/Invoices";
import Reports from "app/scenes/Reports";
import FinkTest from "app/scenes/FinkTest/FinkTest";
//@ts-ignore
import BookingData from "app/scenes/BookingData";
import { Navigate } from "react-router-dom";
import settingsRoutes from "app/scenes/Settings/settings.routes";
import Integrations from "app/scenes/Integrations";

import Flights from "app/scenes/Flights";
import Hotels from "app/scenes/Hotels";
import HotelBookingViewClient from "app/scenes/HotelBookingViewClient/HotelBookingViewClient";
import Flights2B from "app/scenes/Flights2B";

import finkanalyticsRoutes from "app/scenes/FinkAnalytics/finkanalytics.routes";
import AgTestMaster from "app/scenes/AppTour/components/TestAgNew";
import FinkCRM from "app/scenes/FinkCRM";
import finkCrmRoutes from "app/scenes/FinkCRM/fink.crm.routes";
import Uploads from "app/scenes/Uploads";
import ReportManager from "app/scenes/ReportManager";
import reportManageRoutes from "app/scenes/ReportManager/report.manage.routes";
import BoxUI from "app/scenes/BoxUI";
import { getSubdomain } from "app/utils/generic.helper";
import AppDashboard from "app/scenes/AppDashboard";
import HotelDemo from "app/scenes/HotelDemo";
import IntegrationNew from "app/scenes/IntegrationNew";
import ReportView from "app/scenes/ReportManager/components/ReportView";
import ReportLegacyView from "app/scenes/Reports/ReportLegacyView";
// import Onboarding from "app/scenes/Onboarding";
const domain = getSubdomain();
export default {
  path: "",
  element: (
    <ProtectedRoute>
      <MasterLayout />
    </ProtectedRoute>
  ),
  strict: true,
  children: [
    {
      path: "",
      element: <Navigate to="/dashboard" />, // Default redirect to the first child route
      exact: true,
    },
    {
      path: "/dashboard",
      element: domain === "app" ? <AppDashboard /> : <Dashboard />,
      exact: true,
    },
    {
      path: "/fink",

      element: <FinkTest />,
      exact: true,
    },
    // {
    //   path: "/invoices",

    //   element: <Invoices />,
    //   exact: true,
    // },

    {
      path: "/bookings",

      element: <BookingData />,
      exact: true,
    },
    {
      path: "/integrationnew",
      element: <IntegrationNew />,
      exact: true,
    },
    {
      path: "/flights",
      element: <Flights />,
      exact: true,
    },
    {
      path: "/flights/invoicevs2b",

      element: <Flights2B />,
      exact: true,
    },
    {
      path: "/hotels",

      element: <Hotels />,
      exact: true,
    },
    {
      path: "/hotel",

      element: <Hotels />,
      exact: true,
    },
    {
      path: "/ag_test",
      element: <AgTestMaster />,
      exact: true,
    },

    {
      path: "/integrations",

      element: <Integrations />,
      exact: true,
    },

    {
      path: "/hotel_followup",

      element: <HotelBookingViewClient />,
      exact: true,
    },
    {
      path: "/bookingflat",
      element: <FinkTest />,
      exact: true,
    },
    {
      path: "/uploads",
      element: <Uploads />,
      exact: true,
    },
    {
      path: "/invoices",

      element: <BoxUI />,
      exact: true,
    },
    {
      path: "/report/view/:reportId",
      element: <ReportView viewMode />,
      exact: true,
    },
    {
      path: "/report/legacy/view",
      element: <ReportLegacyView />,
      exact: true,
    },

    {
      path: "/reports",

      element: <Reports />,
      exact: true,
    },

    credentialsRoutes,
    reconcilationRoutes,
    settingsRoutes,
    finkanalyticsRoutes,
    // finkCrmRoutes,
    reportManageRoutes,
  ],
};
