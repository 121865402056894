import Typography from "app/shared/Typography";
import "./GSTAddReviewFinal.scss";
import { colorPicker } from "app/utils/color.helper";

import {
  Button,
  Checkbox,
  message,
  Segmented,
  Statistic,
  StatisticProps,
  Tag,
  Upload,
  Modal,
} from "antd";
import {
  SwapOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  ArrowRightOutlined,
  SendOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
} from "@ant-design/icons";
import { AgTableClient } from "app/shared/AgTable";
import { STATE_GSTIN_ICON } from "app/shared/AgTable/stateIconMap";
import Uploader from "app/shared/Uploader";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_ENDPOINT_GST_CREDENTIAL_ADD_TEMP_CREDS,
  API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE_NEW,
  API_ENDPOINT_GST_CREDENTIAL_ENTITY_INFO,
} from "../../gstcredflow.constants";
import { apiPost } from "app/services/apiServices";
import CountUp from "react-countup";
import * as csv from "csvtojson";
import * as XLSX from "xlsx";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import AppLogo from "static/images/FinLogo.png";
import color from "theme/color";
import TextArea from "antd/es/input/TextArea";
import checks from "../../../AirlineCredentialFlow/components/AirlineConfirmPage/checks.svg";
import select from "../../../AirlineCredentialFlow/components/AirlineConfirmPage/select.svg";
import Logo from "static/images/Finkr@ftLogo.png";
import { downloadSampelCrendentialCSV } from "app/utils/generic.helper";
import {
  uploadFileToFirebase,
  uploadJsonToFirestore,
} from "app/config/firebase";
import { Mixpanel } from "app/config/Mixpanel";

export default function GSTAddReviewFinal(props: any) {
  const location = useLocation();
  const stateInfo = location.state || "";
  const [activeTab, setActiveTab] = useState("Failed");
  const [gstinData, setGSTINData] = useState<any[]>([]);
  const [activeGSTIN, setActiveGSTIN] = useState(0);
  const [totalPan, setTotalPan] = useState<any[]>([]);
  const [totalGSTIN, setTotalGSTIN] = useState<any[]>([]);

  const navigate = useNavigate();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [verificationComplate, setVerificationComplete] = useState(true);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
  const [isCustomSelected, setIsCustomSelected] = useState(false); // State for "Custom" option
  const gridRef: any = useRef();

 

  useEffect(() => {
    console.log("state info:", stateInfo);
  
    // Set total PAN
    if (stateInfo.pans && stateInfo.pans.length > 0) {
      setTotalPan(stateInfo.pans);
      console.log("Total PANs set:", stateInfo.pans);
    }
  
    const result = Object.keys(stateInfo.gstinResList||{}).map((pan:any) => {
      return stateInfo.gstinResList[pan].map((gstinObj:any) => ({
        gstin: gstinObj.gstin,
        pan: pan
      }));
    }).flat();
    setGSTINData(result)
    console.log("state info for gstin list:", result);
  
    // Track the page visit with Mixpanel
    Mixpanel.track("User Landed On Add Credential Page", {
      Screen: "Integration",
    });
  }, []);
  
  

  const formatter: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator="," />
  );

  const fetchEntityInfo = async (gstinInfo: any = gstinData) => {
    const response = await apiPost(API_ENDPOINT_GST_CREDENTIAL_ENTITY_INFO, {
      gstins: gstinInfo,
    });
    setVerificationComplete(true);

    console.log("response:", response);
    if (response.status) {
      setGSTINData(response.data);
      let totalActive = 0;
      let pans: any = [];
      response.data.forEach((gstInfo: any) => {
        if (gstInfo.entityInfi?.lgnm) {
          totalActive += 1;
        }
        if (gstInfo.gstin.length === 15) {
          let parsedPAN = gstInfo.gstin.substring(2, 12);
          if (!pans.includes(parsedPAN)) {
            pans.push(parsedPAN);
          }
        }
        setTotalPan(pans);
        setActiveGSTIN(totalActive);
      });
    }
    setVerificationComplete(false);
  };

  const validateJSON = (data: any) => {
    if (!Array.isArray(data) || data.length === 0) {
      return false;
    }

    // Required keys to validate
    const requiredKeys = ["gstin", "username", "password"];

    // Validate each object in the array
    return data.every((obj) => {
      // Ensure each required key exists and is not empty in the object
      return requiredKeys.every(
        (key) => obj.hasOwnProperty(key) && obj[key].trim() !== ""
      );
    });
  };

  const handleUploadTempCredential = async (jsonData: any) => {
    const response = await apiPost(
      API_ENDPOINT_GST_CREDENTIAL_ADD_TEMP_CREDS,
      jsonData
    );
  };

  const handleUpload = async (file: any) => {
    Mixpanel.track("User Uploaded CSV File During Add Credential", {
      Screen: "Integration",
    });
    if (!file.originFileObj) {
      const reader = new FileReader();

      await uploadFileToFirebase(
        file,
        `gst_credential_file/${userDetails.currentWorkspace?.name}/`
      );

      reader.onload = async (e: any) => {
        const binaryString = e.target.result;
        let jsonArray: any[];

        try {
          // Parse the file based on its type
          if (file.type.includes("csv")) {
            //@ts-ignore
            jsonArray = await csv().fromString(binaryString);
          } else if (
            file.type.includes("sheet") ||
            file.type.includes("excel") ||
            file.name.endsWith(".xls") ||
            file.name.endsWith(".xlsx")
          ) {
            const workbook = XLSX.read(binaryString, { type: "binary" });
            const firstSheetName = workbook.SheetNames[0];
            const worksheet = workbook.Sheets[firstSheetName];
            jsonArray = XLSX.utils.sheet_to_json(worksheet);
          } else {
            console.error("Unsupported file format");
            message.error(
              "Unsupported file format. Please upload a valid file.",
              2000
            );
            return;
          }

          // Trim whitespace from each value in the JSON array
          jsonArray = jsonArray.map((row: any) =>
            Object.fromEntries(
              Object.entries(row).map(([key, value]) => [
                key,
                typeof value === "string" ? value.trim() : value,
              ])
            )
          );

          // Validate the JSON structure
          if (!validateJSON(jsonArray)) {
            message.error(
              "Invalid File Uploaded, make sure it should have [gstin, username, password] columns"
            );
            return;
          }

          // Filter out duplicates based on gstin
          const gstinSet = new Set(gstinData.map((data) => data.gstin));
          const uniqueData = jsonArray.filter((row) => {
            if (gstinSet.has(row.gstin)) return false;
            gstinSet.add(row.gstin);
            return true;
          });

          if (uniqueData.length === 0) {
            message.warning(
              "No unique records to add. All gstins are duplicates."
            );
            return;
          }

          // Update the data state and Firestore
          const finalData = [...gstinData, ...uniqueData];
          setGSTINData(finalData);
          fetchEntityInfo(finalData);
          await handleUploadTempCredential(finalData);

          console.log("Processed JSON Array", uniqueData);
        } catch (error) {
          console.error("Error processing the file:", error);
          message.error("An error occurred while processing the file.", 2000);
        }
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleAddGSTCredential = async () => {
    Mixpanel.track("User Submitted GST Credential", {
      Screen: "Integration",
    });
    setLoading(true);
    const payload = {
      credentials: gstinData,
    };

    const response = await apiPost(
      API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE_NEW,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Credential added",
      });
      navigate("/credential/gst/add/done");
      navigate("/credential/gst/add/done");
      Mixpanel.track("GST Credential Succesfully Captured", {
        Screen: "Integration",
      });
    } else {
      Mixpanel.track("GST Credential Could Not Be Captured", {
        Screen: "Integration",
      });
      messageApi.error({
        type: "error",
        content: "Could not add credential",
      });
    }
    setLoading(false);
  };

  const handleBack = () => {
    navigate(-1);
  };

  const showModal = () => setIsModalVisible(true);

  const handleModalClose = () => setIsModalVisible(false);

  const issues = [
    "Issue_01_regarding some common thing",
    "Issue_02_regarding GST cred",
    "Issue_03_regarding Airline",
    "Issue_04_regarding sign up",
    "Issue_05_regarding some common thing",
    "Custom",
  ];

  const handleCheckboxChange = (issue: string) => {
    if (issue === "Custom") {
      setIsCustomSelected(!isCustomSelected);
      if (!isCustomSelected) {
        setSelectedIssues([issue]);
      } else {
        setSelectedIssues([]);
      }
    } else {
      setSelectedIssues((prev) =>
        prev.includes(issue)
          ? prev.filter((item) => item !== issue)
          : [...prev, issue]
      );
    }
  };

  const dropdownContent = (
    <div
      style={{
        border: "1px solid ##E8EAF1",
        borderRadius: "4px",
        marginTop: "8px",
        padding: "16px 16px",
        backgroundColor: "#F8F9FB",
        display: "flex",
        gap: "16px",
        flexDirection: "column",
      }}
    >
      {issues.map((issue, index) =>
        issue === "Custom" || !isCustomSelected ? ( // Show all options except "Custom" if not selected
          <div style={{ display: "flex", gap: "15px" }} key={index}>
            <Checkbox
              checked={selectedIssues.includes(issue)}
              onChange={() => handleCheckboxChange(issue)}
            />
            <Typography>{issue}</Typography>
          </div>
        ) : null
      )}
    </div>
  );
  function generateOTP() {
    return Math.floor(1000 + Math.random() * 9000).toString();
  }

  const handleAddRow = () => {
    const newItem = {
      id: generateOTP(),
      gstin: "",
      username: "",
      password: "",
      status: "EMPTY",
    };

    // gridRef.current &&
    //   gridRef.current?.api.applyTransaction({ add: [newItem] });
    setGSTINData((prevState) => [...prevState, newItem]);
  };

  const handleSaveRow = async (rowItem: any) => {
    let updatedData: any = [];

    setGSTINData((prevArray) => {
      const index = prevArray.findIndex((item) => item.gstin === rowItem.gstin);

      if (index !== -1) {
        // Update existing GSTIN object
        updatedData = [...prevArray];
        updatedData[index] = { ...updatedData[index], ...rowItem };
      } else {
        // Add new GSTIN object
        updatedData = [...prevArray, rowItem];
      }

      return updatedData; // Update state
    });

    try {
      // API call with updated data
      const response = await fetchEntityInfo(updatedData);
      console.log("API response:", response);
    } catch (error) {
      console.error("Error calling API:", error);
    }
  };

  const handleDeleteRow = (gstin: string) => {
    setGSTINData((prevData) =>
      prevData.filter((row: any) => row.gstin !== gstin)
    );
  };

  return (
    <div className="GSTAddReviewFinal">
      <div
        className="MainContainer"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "5px 30px",
          }}
        >
          <div
            style={{ color: "#000", cursor: "pointer" }}
            onClick={handleBack}
          >
            Back
          </div>
        </div>
        <div className="TopLogo">
          <img alt="AppLogo" src={Logo} />
          {/* <div className="MiniDivder" /> */}
          <Typography
            style={{
              // marginBottom: '2px',
              color: colorPicker("neutral.700"),
              textAlign: "right",
            }}
          >
            {/* Having trouble?{" "}
            <a
              style={{ color: colorPicker("primary.700"), cursor: "pointer" }}
              onClick={showModal}
            >
              Get help
            </a> */}
          </Typography>
        </div>

        <div className="RightContainer">
          <Tag color="cyan">GSTIN credentials</Tag>
          <Typography variant="h5" style={{ marginTop: 12, color: "#212121" }}>
            Summary of{" "}
            <span style={{ color: colorPicker("primary.700") }}>
              {userDetails?.currentWorkspace?.name}
            </span>
          </Typography>

          <div className="SummaryContainer">
            <div
              className="SummaryCard"
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <Typography
                variant="h4"
                style={{ color: colorPicker("primary.700") }}
                weight={600}
              >
                <Statistic value={totalPan.length} formatter={formatter} />
              </Typography>
              <Typography variant="caption">Number of PAN</Typography>
            </div>

            <div
              className="SummaryCard"
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <Typography
                variant="h4"
                style={{ color: colorPicker("primary.700") }}
                weight={600}
              >
                <Statistic value={gstinData.length} formatter={formatter} />
                {/* {gstinData.length} */}
              </Typography>
              <Typography variant="caption">Total GSTIN</Typography>
            </div>

            <div
              className="SummaryCard"
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <Typography
                variant="h4"
                style={{ color: colorPicker("primary.700") }}
                weight={600}
              >
                <Statistic value={activeGSTIN} formatter={formatter} />
              </Typography>
              <Typography variant="caption">Active</Typography>
            </div>

            <div
              className="SummaryCard"
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <Typography
                variant="h4"
                style={{ color: "#E53232" }}
                weight={600}
              >
                <Statistic
                  value={
                    verificationComplate
                      ? gstinData.length > activeGSTIN
                        ? gstinData.length - activeGSTIN
                        : 0
                      : 0
                  }
                  formatter={formatter}
                />
              </Typography>
              <Typography variant="caption" style={{ color: "#E53232" }}>
                Failed
              </Typography>
            </div>
          </div>

          <div style={{ marginTop: 12 }}>
            <Segmented
              options={["Failed", "Active"]}
              value={activeTab}
              onChange={(value: any) => {
                console.log(value); // string
              }}
              size="small"
            />

            <Button
              size="small"
              style={{ marginLeft: 12 }}
              onClick={handleAddRow}
            >
              Add Row
            </Button>
          </div>

          <div className="TableContainer">
            <AgTableClient
              // @ts-ignore
              rowData={gstinData}
              enableDirectEdit
              onRowSaved={handleSaveRow}
              // ref={gridRef}
              columnDefs={[
                {
                  field: "gstin",
                  headerName: "GSTIN",
                  editable: true,
                  cellRenderer: (params: any) => {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{ width: 24, marginRight: 6 }}
                          src={
                            STATE_GSTIN_ICON[
                              params?.data?.gstin?.substring(0, 2)
                            ]
                          }
                        />
                        {params.data.gstin}
                      </div>
                    );
                  },
                },
                { field: "username", headerName: "Username", editable: true },
                { field: "password", headerName: "Password", editable: true },
                {
                  field: "entityInfi.lgnm",
                  headerName: "Entity Name",
                  cellRenderer: (params: any) => {
                    return params.data?.entityInfi?.lgnm ? (
                      params.data?.entityInfi.lgnm
                    ) : params.data?.entityInfi &&
                      !params.data?.entityInfi.lgnm ? (
                      <Tag color="red">Not Found</Tag>
                    ) : params.data?.status === "EMPTY" ? (
                      ""
                    ) : (
                      <Tag color="orange" icon={<LoadingOutlined />}>
                        Loading
                      </Tag>
                    );
                  },
                },
                {
                  field: "entityInfi.tradeNam",
                  headerName: "Legal Trade Name",
                  cellRenderer: (params: any) => {
                    return params.data?.entityInfi?.tradeNam ? (
                      params.data?.entityInfi.tradeNam
                    ) : params.data?.entityInfi &&
                      !params.data?.entityInfi.tradeNam ? (
                      <Tag color="red">Not Found</Tag>
                    ) : params.data?.status === "EMPTY" ? (
                      ""
                    ) : (
                      <Tag color="orange" icon={<LoadingOutlined />}>
                        Loading
                      </Tag>
                    );
                  },
                },

                {
                  headerName: "Action",
                  cellRenderer: (params: any) => {
                    return (
                      <DeleteOutlined
                        style={{
                          fontSize: 22,
                          color: "red",
                          cursor: "pointer",
                        }}
                        onClick={() => handleDeleteRow(params.data.gstin)}
                      />
                    );
                  },
                },
              ]}
              hideToolbar
              autoResize
              rowIdKey={"id"}
              useRowId
            />
          </div>
          <div className="UploadContainer">
            <div className="Header">
              <Typography variant="caption" style={{ color: "#212121" }}>
                GST Credentials
              </Typography>
              {gstinData.length > 0 ?
              <Button
                icon={<DownloadOutlined />}
                size="small"
                type="primary"
                onClick={downloadSampelCrendentialCSV}
              >
               Download Table
               
              </Button>:   <Button
                icon={<DownloadOutlined />}
                size="small"
                type="primary"
                onClick={downloadSampelCrendentialCSV}
              >
                Download sample file
               
              </Button>}
            </div>
            <div className="UploaderWidget">
              <Uploader colored onSelect={handleUpload} />
            </div>
            <div className="UploaderFooter">
              <Typography
                variant="caption"
                style={{ color: colorPicker("neutral.700") }}
              >
                <InfoCircleOutlined style={{ marginRight: 4 }} />
                The file should contain GSTIN, Username & Password
              </Typography>
            </div>

            <div className="ActionContainer">
              <Button
                icon={<SendOutlined />}
                style={{
                  width: "48%",
                  color: "#0A8394",
                  borderColor: "#0A8394",
                }}
                size="large"
                onClick={() => navigate("/credential/gst/invite/user")}
                loading={isLoading}
              >
                Invite user for the failed credentials
              </Button>
              <Button
                icon={<ArrowRightOutlined />}
                style={{ width: "48%" }}
                type="primary"
                size="large"
                onClick={handleAddGSTCredential}
                loading={isLoading}
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      {contextHolder}
      <Modal
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        title=""
      >
        <span style={{ display: "flex", gap: "10px" }}>
          <QuestionCircleOutlined
            style={{ color: "#0A8394", fontSize: "24px" }}
          />
          <Typography
            style={{
              color: "#0A8394",
              fontSize: "24px",
              fontWeight: "400",
              fontFamily: "Noto Sans",
            }}
          >
            Help center
          </Typography>
        </span>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "20px",
          }}
        >
          <Typography style={{ color: "#212121" }}>
            Choose the issue here
          </Typography>
          <img
            src={select}
            onClick={() => setDropdownVisible(!dropdownVisible)}
            style={{ cursor: "pointer" }}
          />
        </div>
        {dropdownVisible && dropdownContent}

        {/* Conditionally render the text field when "Custom" is selected */}
        {isCustomSelected && (
          <div style={{ marginTop: "10px" }}>
            <TextArea
              placeholder="Custom issue description"
              style={{ width: "100%" }}
            />
          </div>
        )}

        <Button
          type="primary"
          style={{
            width: "100%",
            height: "40px",
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            marginTop: "24px",
            fontSize: "16px",
          }}
        >
          <img
            src={checks}
            style={{ width: "26px", height: "26px", marginRight: "8px" }}
          />
          Submit
        </Button>
      </Modal>
    </div>
  );
}
