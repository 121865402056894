import { handleCellRenderer as clientCellRenderer } from "app/shared/AgTable/AgUtility";
import { handleCellRenderer } from "app/shared/AgTable/AgServerUtility";
import { Mixpanel } from "app/config/Mixpanel";

const openSidebarRenderer = (params: any) => {
  params.api.openToolPanel("pdfRenderer", { paramData: params });
};
export const getProcessedDefinition = (
  columnArray: any[],
  type: "SERVER" | "CLIENT" = "SERVER",
  pdfFunction: (data: any) => void,
  userMeta: any = {}
) => {
  // Common Excel Style
  const commonExcelStyle = {
    id: "headerStyle1",
    font: {
      bold: true,
      color: { rgb: "FF0000" }, // Red color
    },
    alignment: {
      horizontal: "Center",
      vertical: "Center",
    },
    fill: {
      fgColor: { rgb: "D3D3D3" }, // Gray background color
    },
  };

  // Helper function to add cellRendererParams
  const addCellRendererParams = (columnData: any) => {
    if (columnData.formatType === "INVOICE") {
      return {
        ...columnData,
        cellRendererParams: () => ({
          label: "View Invoice",
          handleClick: (params: any) => {
            pdfFunction(params.data?.[columnData.field]);
            openSidebarRenderer(params);
          },
        }),
      };
    }
    return columnData;
  };

  // Recursive processing of columns
  const processColumns: any = (columns: any[]) =>
    columns
      .map((columnData: any) => {
        // Hide logic
        console.log(
          "userMeta:",
          userMeta,
          columnData.hide === "CORPORATE" &&
            (userMeta.account_type === "CLIENT" ||
              userMeta.currentWorkspace.role === "GENERAL")
        );
        const updatedColumn = {
          ...columnData,
        };
        if (
          columnData.hide === "CORPORATE" &&
          (userMeta.account_type === "CLIENT" ||
            userMeta.currentWorkspace.role === "GENERAL")
        ) {
          return null;
        } else {
          updatedColumn.hide = false;
        }

        // Add cellRendererParams
        const columnWithRendererParams = addCellRendererParams(updatedColumn);

        // Process children if present
        if (columnWithRendererParams.children?.length > 0) {
          return {
            ...columnWithRendererParams,
            children: processColumns(columnWithRendererParams.children),
          };
        }

        return {
          ...columnWithRendererParams,
          excelStyle: commonExcelStyle,
          ...(type === "SERVER" ? handleCellRenderer() : clientCellRenderer()), // Server or client-specific logic
        };
      })
      .filter((column) => column !== null); // Remove null columns

  return processColumns(columnArray);
};

export const getSubdomain = () => {
  const { hostname } = window.location; // Get the hostname (e.g., sub.domain.com)
  const parts = hostname.split("."); // Split the hostname into parts (e.g., ['sub', 'domain', 'com'])

  if (parts.length > 2) {
    // If there are more than two parts, the first part is the subdomain
    return parts[0];
  } else {
    // No subdomain exists
    return "mmt";
  }
};

export const downloadSampelCrendentialCSV = async () => {
  Mixpanel.track("User Downloaded Sample Credential CSV", {
    Screen: "Integration",
  });

  let csvString = `gstin,username,password
                    09AABCG3241G1ZL,testuser1,user_password
                    09AABCG3141G1ZL,testuser2,user_password
                    09AABCG8111G1ZL,testuser3,user_password`;
  try {
    // Convert the CSV string to a Blob
    const csvBlob = new Blob([csvString], { type: "text/csv" });

    // Convert the Blob object to a data URL
    const csvDataUrl = URL.createObjectURL(csvBlob);

    // Create a temporary anchor element
    const link = document.createElement("a");
    link.href = csvDataUrl;
    link.setAttribute("download", "sample_credentials.csv");

    // Trigger the download by programmatically clicking the anchor element
    document.body.appendChild(link);
    link.click();

    // Clean up
    document.body.removeChild(link);
  } catch (error) {
    console.error("Error downloading CSV file:", error);
  }
};
