import PageHeader from "app/shared/PageHeader";
import "./Dashboard.scss";
import CustomReconTabs from "../Reconcilation/components/CustomReconTabs";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import {
  tabViewStates,
  userInfo,
  workspaceReports,
} from "app/config/States/users";
import Loader from "app/shared/Loader";
import { Empty } from "antd";
import Typography from "app/shared/Typography";
import EmptyState from "app/shared/EmptyState";
import ZAViewSelector from "app/shared/ZAViewSelector";
import { Mixpanel } from "app/config/Mixpanel";
export default function AppDashboard(props: any) {
  const [activeKey, setActiveKey] = useState("");
  const [reports, setWorkspaceReports] = useRecoilState<any>(workspaceReports);
  const [tabViews, setTabView] = useRecoilState<any>(tabViewStates);
  const [isLoading, setLoading] = useState(false);
  Mixpanel.track("User Landed On Dashboard", { Screen: "Dashboard" });

  useEffect(() => {
    if (reports?.dashboards && reports?.dashboards.length > 0) {
      setActiveKey(reports.dashboards[0].embedurl);
    }
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, [reports]);

  console.log("reports", reports);
  return isLoading ? (
    <Loader />
  ) : (
    <div className="Dashboard">
      <div className="ScreenContainer">
        <PageHeader
          title="Dashboard"
          leftActions={
            <ZAViewSelector
              activeKey={activeKey}
              items={reports?.dashboards || []}
              onViewChange={(data: any, fullObj: any) => setActiveKey(data)}
            />
          }
        />

        <div className="TableContainer">
          {!reports?.dashboards || reports?.dashboards?.length === 0 ? (
            <EmptyState
              title="No Dashboards Available"
              description={"Try changing workspace for dashboard view"}
            />
          ) : (
            <div
              style={{
                height: "100%",
              }}
            >
              <iframe
                height="100%"
                style={{ width: "100%" }}
                scrolling="no"
                title="Iframe"
                src={activeKey}
              ></iframe>
            </div>
          )}
        </div>
      </div>
    </div>
  );
}
