import { Tag } from "antd";
import moment from "moment";
import React, { useState } from "react";
import { FormattedNumber } from "react-intl";
import { CheckCircleFilled, CloseCircleFilled } from "@ant-design/icons";
import { get as getDataWithLodash } from "lodash";
import "./AgTable.scss";
import CorporateFareRoundedIcon from "@mui/icons-material/CorporateFareRounded";
import Typography from "../Typography";
import airlineLogos from "./HelperTools/IconMaps";
import AirlinesRoundedIcon from "@mui/icons-material/AirlinesRounded";
import GroupRoundedIcon from "@mui/icons-material/GroupRounded";
import ToolParamInvoker from "./HelperTools/ToolParamInvoker";
import { STATE_GSTIN_ICON } from "./HelperTools/stateIconMap";
function formatFileSize(bytes: any) {
  if (bytes === 0) return "0 Bytes";
  const k = 1024;
  const sizes = ["B", "KB", "MB", "GB", "TB"];
  const i = Math.floor(Math.log(bytes) / Math.log(k));
  return parseFloat((bytes / Math.pow(k, i)).toFixed(2)) + " " + sizes[i];
}

export const handleAgCellDataFormat = (
  formatType: string,
  formatData: any,
  formatProps: any,
  fullParamData: any
) => {
  switch (formatType) {
    case "CUSTOM_RENDER":
      return formatProps?.cellRenderer(formatData, fullParamData) || "";
    case "CHIP":
      return <Tag color={formatProps?.chipColor}>{formatData}</Tag>;
    case "CHIP_SHORT_TEXT":
      return (
        <Tag color={formatProps?.chipColor}>
          {formatData.toString().slice(0, 8)}
        </Tag>
      );
    case "ENTITY":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <CorporateFareRoundedIcon style={{ fontSize: 19, marginRight: 4 }} />
          {formatData}
        </div>
      );
    case "PERSON":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <GroupRoundedIcon style={{ fontSize: 19, marginRight: 4 }} />
          {formatData}
        </div>
      );
    case "INVOICE":
      return ToolParamInvoker;
    case "AIRLINE":
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          <img
            src={airlineLogos[formatData] || airlineLogos["NA"]}
            alt="airlineLogo"
            style={{
              width: 30,
              height: 30,
              marginRight: 4,
              objectFit: "contain",
            }}
          />

          {formatData}
        </div>
      );
    case "AMOUNT":
      let amountData =
        typeof formatData === "string"
          ? parseFloat(formatData.replace(/,/g, ""))
          : formatData;
      return (
        <div
          style={{
            textAlign: "right",
            width: "100%",
            color: amountData < 0 ? "#dd5061" : "",
            fontWeight: amountData < 0 ? 700 : 400,
          }}
        >
          <FormattedNumber
            value={amountData || 0}
            style="currency"
            currency="INR"
          />
        </div>
      );
    case "NEGATIVE_NUMBER":
      return (
        <div
          style={{
            textAlign: "right",
            width: "100%",
            color: "#dd5061",
            fontWeight: 700,
          }}
        >
          {formatData}
        </div>
      );
    case "FILE_SIZE":
      return formatData ? formatFileSize(formatData) : "0 B";
    case "GSTIN_STATE":
      let newFormatData =
        formatData.length > 2 ? formatData.substring(0, 2) : formatData;
      return (
        <div style={{ display: "flex", alignItems: "center" }}>
          {formatData ? (
            <img
              src={STATE_GSTIN_ICON[newFormatData] || STATE_GSTIN_ICON["NA"]}
              style={{
                width: 30,
                height: 30,
                marginRight: 4,
                objectFit: "contain",
              }}
              alt="stateIcon"
            />
          ) : (
            <AirlinesRoundedIcon />
          )}
          {formatData}
        </div>
      );
    case "DATE":
      return moment(formatData).format(
        formatProps?.dateFormat || "DD-MM-YYYY"
      ) == "Invalid date"
        ? "-"
        : moment(formatData).format(formatProps?.dateFormat || "DD-MM-YYYY");
    case "DATETIME":
      return moment(formatData).format(
        formatProps?.dateFormat || "DD-MM-YYYY HH:MM A"
      ) == "Invalid date" ? (
        <Tag color="red">Date Unavailable</Tag>
      ) : (
        moment(formatData).format(
          formatProps?.dateFormat || "DD-MM-YYYY HH:MM A"
        )
      );

    case "TAGS":
      let tags: any = [];
      (formatData || []).forEach((element: any) => {
        tags.push(
          <Tag
            // onClick={() => applyFilter(fieldKey, element)}
            color={formatProps?.chipColor}
            id="filterTag"
            style={{ fontSize: 10 }}
          >
            {element}
          </Tag>
        );
      });
      return tags;
    case "404":
      return formatData ? (
        formatData
      ) : (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            color: "#dd5061",
            fontWeight: 700,
          }}
        >
          Missing
        </div>
      );
    case "MATCH":
      return (
        <div style={{ height: "100%", display: "flex", alignItems: "center" }}>
          <Tag
            color={
              formatProps?.matchColor?.matchLogic?.[formatData] === "MATCH"
                ? "green"
                : "red"
            }
            style={{
              alignItems: "center",
              display: "flex",
              width: "fit-content",
              fontSize: 10,
            }}
          >
            {/* {formatProps?.matchLogic?.icon?.[formatData] || (
              <CloseCircleFilled />
            )} */}
            {formatProps?.matchColor?.matchLogic?.[formatData] === "MATCH" ? (
              <CheckCircleFilled style={{ color: "#43a522", marginRight: 6 }} />
            ) : (
              <CloseCircleFilled style={{ color: "#dd5061", marginRight: 6 }} />
            )}
            <span />
            {formatData || formatProps?.defaultText || "NA"}
          </Tag>
        </div>
      );
    case "MATCH_COMPARE":
      let isMatched = true;
      (formatProps?.compareObjects || []).forEach((objectPath: string) => {
        if (formatData !== getDataWithLodash(fullParamData, objectPath, "NA")) {
          isMatched = false;
          return;
        }
      });

      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isMatched ? (
            <CheckCircleFilled style={{ color: "#43a522", marginRight: 6 }} />
          ) : (
            <CloseCircleFilled style={{ color: "#dd5061", marginRight: 6 }} />
          )}

          <span />
          {formatData || formatProps?.defaultText || "NA"}
        </div>
      );

    case "MATCH_COMPARE_STATUS":
      let isDataMatch = true;
      (formatProps?.compareObjects || []).forEach((objectPath: string) => {
        if (formatData !== getDataWithLodash(fullParamData, objectPath, "NA")) {
          isMatched = false;
          return;
        }
      });

      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
          }}
        >
          {isDataMatch ? (
            <CheckCircleFilled style={{ color: "#43a522", marginRight: 6 }} />
          ) : (
            <CloseCircleFilled style={{ color: "#dd5061", marginRight: 6 }} />
          )}

          <span />
          {isDataMatch ? "Match" : "Mismatch"}
        </div>
      );

    case "MATCH_COMPARE_TEXT":
      let isTextMatched = true;
      (formatProps?.compareObjects || []).forEach((objectPath: string) => {
        if (formatData !== getDataWithLodash(fullParamData, objectPath, "NA")) {
          isTextMatched = false;
          return;
        }
      });

      return (
        <div
          style={{
            height: "100%",
            display: "flex",
            alignItems: "center",
            color: isTextMatched ? "" : "#dd5061",
            fontWeight: isTextMatched ? 400 : 700,
          }}
        >
          {formatData || formatProps?.defaultText || "NA"}
        </div>
      );
    case "NUMBER":
      return <FormattedNumber value={formatData || 0} />;

    default:
      return typeof formatData === "number" ? (
        <FormattedNumber value={formatData || 0} style="currency" />
      ) : (
        formatData
      );
  }
};

const handleValueGetter = (params: any, dataKey: string, defaultValue: any) => {
  if (params.colDef.formatType === "CUSTOM_RENDER") {
    return params?.colDef?.formatProps?.valueGetterData
      ? params?.colDef?.formatProps?.valueGetterData(params.data, params)
      : getDataWithLodash(params?.data, dataKey, "NA");
  } else {
    return getDataWithLodash(params?.data, dataKey, "NA");
  }
};
export const handleCellRenderer = () => {
  return {
    valueGetter: (params: any) => {
      if (params.colDef.colId && params.colDef.colId === "ag-Grid-AutoColumn") {
        // If the node is a group, return the group key
        return params.value;
      } else if (params.colDef.column && params.column.aggregationActive) {
        // If the node is a group, return the group key
        return params.value;
      } else if (params.node.group) {
        return "";
      } else {
        // If the node is a leaf node, return the individual value
        return handleValueGetter(params, params?.colDef?.field, "NA");
      }
    },
    cellRenderer: (params: any) => {
      return params.colDef.formatType === "INVOICE" ? (
        <ToolParamInvoker {...params} />
      ) : params.colDef.colId &&
        params.colDef.colId === "ag-Grid-AutoColumn" ? (
        handleAgCellDataFormat(
          params?.colDef?.formatType,
          params?.value,
          params?.colDef?.formatProps,
          params?.data
        )
      ) : params.colDef.colId && params.colDef.colId.includes("pivot") ? (
        handleAgCellDataFormat(
          params?.colDef?.formatType,
          params?.value,
          params?.colDef?.formatProps,
          params?.data
        )
      ) : params.column && params.column.aggregationActive ? (
        handleAgCellDataFormat(
          params?.colDef?.formatType,
          params?.value,
          // getDataWithLodash(params?.data, params?.colDef?.field, "NA"),
          params?.colDef?.formatProps,
          params?.data
        )
      ) : params.node.group ? (
        "-"
      ) : (
        handleAgCellDataFormat(
          params?.colDef?.formatType,
          getDataWithLodash(params?.data, params?.colDef?.field, "NA"),
          params?.colDef?.formatProps,
          params?.data
        )
      );
    },
  };
};

export function generateColumnDefsFromPayload(payload: any) {
  const columnDefs: any = [];

  // Add row group columns (from rowGroupCols)
  payload.rowGroupCols.forEach((col: any) => {
    columnDefs.push({
      field: col.field,
      headerName: col.displayName || col.field, // Use displayName if provided, otherwise fallback to field name
      rowGroup: true, // Mark this column as a group column
      hide: true, // Hide the column since it's used for grouping
    });
  });

  // Add value columns (from valueCols) for aggregation
  payload.valueCols.forEach((col: any) => {
    columnDefs.push({
      field: col.field,
      headerName: col.displayName || col.field, // Use displayName if provided
      aggFunc: col.aggFunc, // Set the aggregation function (e.g., 'sum')
      enableValue: true, // Mark this column for aggregation
    });
  });

  // Handle pivot columns if any (from pivotCols)
  if (payload.pivotCols && payload.pivotCols.length > 0) {
    payload.pivotCols.forEach((col: any) => {
      columnDefs.push({
        field: col.field,
        headerName: col.displayName || col.field,
        pivot: true, // Mark this column as a pivot column
      });
    });
  }

  return columnDefs;
}
