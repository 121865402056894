import { Select } from "antd";
import BubbleChartIcon from "@mui/icons-material/BubbleChart";
import SummarizeIcon from "@mui/icons-material/Summarize";
import { useEffect, useState } from "react";
export default function ZAViewSelector(props: any) {
  const [items, setItems] = useState([
    {
      id: 1,
      title: props.title || "Tab",
      type: "VIEW",
      viewData: null,
    },
  ]);

  const getItemList = (data: any) => {
    let options: any = [];
    data.forEach((itemData: any) => {
      if (!itemData.hide)
        options.push({ value: itemData.embedurl, label: itemData.report_name });
    });
    return options;
  };

  return (
    <div style={{ display: "flex", alignItems: "center" }}>
      <Select
        placeholder="Dasbhoard"
        variant="filled"
        style={{ width: 240, marginLeft: 12 }}
        options={getItemList(props.items)}
        size="small"
        onChange={props.onViewChange}
        value={props.activeKey}
      />
    </div>
  );
}
