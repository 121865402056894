import PageHeader from "app/shared/PageHeader";
import "./Reports.scss";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import Loader from "app/shared/Loader";
import { AgTableClient } from "app/shared/AgTable";
import moment from "moment";
import { Button, Input, message, Modal, Popconfirm, Select, Tag } from "antd";
import { EyeOutlined, PlusOutlined, DeleteOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import { apiDelete, apiPost, apiPut } from "app/services/apiServices";

import {
  API_ENDPOINT_REPORT_LEGACY_LIST,
  API_ENDPOINT_REPORT_MANAGER_LIST,
} from "../ReportManager/report.manager.constant";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import {
  API_ENDPOINT_REPORTS_REMOVE_FROM_WORKSPACE,
  API_ENDPOINT_REPORTS_REMOVE_FROM_WORKSPACE_NEW,
} from "./reports.constants";
import AddWorksapceReport from "../Settings/components/WorkspaceMetaSetting/components/AddWorksapceReport";
const Reports = forwardRef((props: any, ref: any) => {
  const [showModal, setShowModal] = useState(false);
  const [reportList, setReportList] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [selectedWorkspace, setSelectedWorkspace] = useState<any>(null);
  const [creating, setCreating] = useState(false);
  const [selectedReport, setSelectedReport] = useState<any>(null);
  const [reportName, setReportName] = useState("");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [showModalFor, setShowModalFor] = useState("");

  useEffect(() => {
    fetchReportList();
  }, [userDetails.currentWorkspace]);

  const fetchLegacyReport = async (newReportList: any) => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_REPORT_LEGACY_LIST, {
      workspace_id: userDetails.currentWorkspace?.id,
    });
    if (response.status) {
      let reportLists: any = [];
      response.data.forEach((reportItem: any) => {
        (reportItem.reports || []).forEach((reportData: any) => {
          reportLists.push({
            report_version_type: "LEGACY",
            report_name: reportData.report_name,
            report_link: reportData.embedurl,
            criteria: reportData.criteria || "workspace",
          });
        });
      });
      setReportList([...newReportList, ...reportLists]);
    } else {
    }
    setLoading(false);
  };

  useImperativeHandle(ref, () => ({
    fetchReportList() {
      fetchReportList();
    },
  }));

  const fetchReportList = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_REPORT_MANAGER_LIST, {
      workspace_id: userDetails.currentWorkspace?.id,
    });
    if (response.status) {
      fetchLegacyReport(response.data);
    } else {
    }
    // setLoading(false);
  };

  const handleRemoveWorkspace = async (type: string, reportName: string) => {
    const payload = {
      workspaceId: userDetails?.currentWorkspace?.id,
      type: type,
      report_name: reportName,
    };
    const response = await apiDelete(
      API_ENDPOINT_REPORTS_REMOVE_FROM_WORKSPACE,
      payload
    );
    if (response.status) {
      message.success("Report has been removed");
      fetchReportList();
    } else {
      message.error("Could not remove report from workspaces");
    }
  };

  const handleNewRemoveWorkspace = async (type: string, reportId: string) => {
    const payload = {
      workspaceId: userDetails?.currentWorkspace?.id,
      type: type,
      reportId: reportId,
    };
    const response = await apiDelete(
      API_ENDPOINT_REPORTS_REMOVE_FROM_WORKSPACE_NEW,
      payload
    );
    if (response.status) {
      message.success("Report has been removed");
      fetchReportList();
    } else {
      message.error("Could not remove report from workspaces");
    }
  };

  const navigate = useNavigate();

  const columnDefs = [
    {
      field: "report_name",
      headerName: "Report Name",
      width: 200,
    },

    {
      field: "report_version_type",
      headerName: "Report Version",
      width: 200,
      cellRenderer: (params: any) => {
        return (
          <Tag
            color={
              params.data.report_version_type === "LEGACY" ? "yellow" : "green"
            }
          >
            {params.data.report_version_type}
          </Tag>
        );
      },
    },
    // {
    //   field: "created_on",
    //   headerName: "Created On",
    //   width: 200,
    // },
    {
      field: "Action",
      headerName: "Action",
      width: 400,

      cellRenderer: (params: any) => (
        <div>
          <Button
            size="small"
            icon={<EyeOutlined />}
            onClick={() => {
              if (params.data.report_version_type === "LEGACY") {
                navigate("/report/legacy/view", {
                  state: {
                    report_link: params.data.report_link,
                    zoho_criteria: params.data?.criteria,
                  },
                });
              } else {
                navigate("/report/view/" + params.data?._id, {
                  state: {
                    dbType: params.data?.meta?.dbType,
                    database: params.data?.meta?.database,
                    table: params.data?.meta?.table,
                    report_name: params.data?.report_name,
                    selectedWorkspaceId: params.data?.workspace_id,
                  },
                });
              }
            }}
          >
            View
          </Button>
          <Popconfirm
            placement="topLeft"
            title={
              "Are you sure want to remove the report from current selected workspace"
            }
            okText="Yes"
            cancelText="No"
            onConfirm={() =>
              params.data.report_version_type === "LEGACY"
                ? handleRemoveWorkspace("SINGLE", params.data?.report_name)
                : handleNewRemoveWorkspace("SINGLE", params.data?.id)
            }
          >
            <Button
              size="small"
              icon={<DeleteOutlined />}
              style={{ marginLeft: 12 }}
              danger
            >
              Remove Report
            </Button>
          </Popconfirm>
          <Popconfirm
            placement="topLeft"
            title={"Are you sure want to remove the report from all workspaces"}
            okText="Yes"
            cancelText="No"
            onConfirm={() =>
              params.data.report_version_type === "LEGACY"
                ? handleRemoveWorkspace("ALL", params.data?.report_name)
                : handleNewRemoveWorkspace("ALL", params.data?.id)
            }
          >
            <Button
              size="small"
              icon={<DeleteOutlined />}
              danger
              style={{ marginLeft: 12 }}
            >
              Remove From All Workspace
            </Button>
          </Popconfirm>
        </div>
      ),
    },
  ];

  const handleSelectWorkspace = (spaceInfo: any, fullObj: any) => {
    setSelectedWorkspace(spaceInfo);
  };

  const { Option } = Select;
  const copyDataToBoard = (data: any) => {
    navigator?.clipboard?.writeText(data);
    messageApi.open({
      type: "success",
      content: "copied",
    });
  };
  return (
    <div className="ReportSelection">
      {props.noHeader ? null : (
        <div className="HeaderContainer">
          <PageHeader
            title="Report List"
            rightActions={
              userDetails.email.includes("finkraft.ai") ||
              userDetails.email.includes("kgrp.in") ? (
                <div>
                  <Button
                    icon={<PlusOutlined />}
                    size="small"
                    type="primary"
                    onClick={() => navigate("/reportmanager/create")}
                  >
                    Create New Report
                  </Button>

                  <Button
                    type="primary"
                    size="small"
                    icon={<PlusOutlined />}
                    onClick={() => {
                      setShowModalFor("REPORT");
                      setShowModal(true);
                    }}
                    style={{ marginLeft: 12 }}
                  >
                    Add Legacy Report
                  </Button>
                </div>
              ) : null
            }
          />
        </div>
      )}
      <div className="MainContainer">
        {isLoading ? (
          <Loader />
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={reportList}
            columnDefs={columnDefs}
            hideToolbar
            autoResize
          />
        )}
      </div>
      {contextHolder}
      <Modal
        title={
          showModalFor === "REPORT"
            ? "Add Legacy(ZA) Report"
            : `Dupliate - ${selectedReport?.report_name}`
        }
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
      >
        {showModalFor === "REPORT" ? (
          <AddWorksapceReport
            onSuccess={() => {
              fetchReportList();
              setShowModal(false);
              setShowModalFor("");
            }}
          />
        ) : (
          <div style={{ marginTop: 24 }}>
            <Input
              placeholder="Report Name"
              onChange={(e: any) => setReportName(e.target.value)}
            />

            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "flex-end",
              }}
            >
              <Button
                style={{ marginTop: 24 }}
                size="small"
                type="primary"
                loading={creating}
                //@ts-ignore
                onClick={() => handleCreateDuplicateReport(selectedReport?._id)}
              >
                Create Duplicate
              </Button>
            </div>
          </div>
        )}
      </Modal>
    </div>
  );
});
export default Reports;
