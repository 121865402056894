import PageHeader from "app/shared/PageHeader";
import "../../FinkAnalytics.scss";
import {
  Button,
  Checkbox,
  Input,
  message,
  Modal,
  Popconfirm,
  Segmented,
  Select,
  Switch,
  Tag,
} from "antd";
import SearchTable from "../SearchTable";
import { useEffect, useState } from "react";
import { AgTableClient } from "app/shared/AgTable";
import {
  EyeOutlined,
  PlusOutlined,
  DeleteOutlined,
  SyncOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { apiDelete, apiGet, apiPost, apiPut } from "app/services/apiServices";
import {
  API_ENDPOINT_INVOICE_VALIDATE_SCHEMA,
  API_ENDPOINT_REPORT_VALIDATE_SCHEMA,
  API_ENDPOINT_ZA_TABLE_DELETE_SCHEMA,
  API_ENDPOINT_ZA_TABLE_GET_SCHEMA_LIST,
  API_ENDPOINT_ZA_TABLE_GET_TABLE_DATA_REF,
  API_ENDPOINT_ZA_TABLE_INIT_SYNC,
} from "../../finkanalytics.constants";
import Loader from "app/shared/Loader";
import { useNavigate } from "react-router-dom";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";

export default function SchemaList(props: any) {
  const [showModal, setShowModal] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [isLoading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState("REPORT");
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [validating, setValidating] = useState("");

  const [messageApi, contextHolder] = message.useMessage();

  const fetchSchemaList = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_ZA_TABLE_GET_SCHEMA_LIST);

    setTableData(response.data);
    setLoading(false);
  };

  useEffect(() => {
    fetchSchemaList();
  }, []);

  const navigate = useNavigate();

  const handleDeleteSchema = async (schemaId: any) => {
    const response = await apiDelete(
      API_ENDPOINT_ZA_TABLE_DELETE_SCHEMA + schemaId
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Schema deleted",
      });
      fetchSchemaList();
    } else {
      messageApi.error({
        type: "error",
        content: "Could not delete schema",
      });
    }
  };

  const handleValidateReportSchema = async (schemaData: any) => {
    setValidating(`REPORT_${schemaData.moduleId}`);
    const payload = {
      workspace_id: [userDetails?.currentWorkspace?.id],
      moduleId: schemaData.moduleId,
      table_name: schemaData.table_name,
    };
    const response = await apiPost(
      API_ENDPOINT_REPORT_VALIDATE_SCHEMA,
      payload
    );

    if (response.status) {
      //success
      message.success("Validation status updated");
      fetchSchemaList();
    } else {
      //success
      message.error("Validation status could not be updated");
    }
    setValidating("");
  };

  const handleValidateInvoiceSchema = async (schemaData: any) => {
    setValidating(`INVOICE_${schemaData.moduleId}`);
    const payload = {
      table_name: schemaData.table_name,

      filenameTags: ["Workspace"],
      groupingPayload: {
        startRow: 0,
        endRow: 100000,
        rowGroupCols: [],
        valueCols: [],
        pivotCols: [],
        pivotMode: false,
        groupKeys: [],
        filterModel: {},
        sortModel: [],
      },
      moduleId: schemaData.moduleId,
      columnLinks: ["assetlink"],
      dbType: "postgre",
      database: "gstservice_db",
      tableName: "airline_recon_tatacap",
      workspace_id: [userDetails?.currentWorkspace?.id],

      type: "RECON_REPORT_INVOICES",
    };
    const response = await apiPost(
      API_ENDPOINT_INVOICE_VALIDATE_SCHEMA,
      payload
    );

    if (response.status) {
      //success
      message.success("Validation status updated");
      fetchSchemaList();
    } else {
      //success
      message.error("Validation status could not be updated");
    }
    setValidating("");
  };

  const handleInitiateSync = async (moduleId: string) => {
    const response = await apiPut(API_ENDPOINT_ZA_TABLE_INIT_SYNC + moduleId);
    if (response.status) {
      message.success("ZA Sync has been started");
    } else {
      message.error("Could initiate za sync");
    }
  };
  const columnDefs = [
    {
      field: "name",
      headerName: "Name",
      width: 200,
    },
    {
      field: "moduleId",
      headerName: "Module ID",
      width: 150,
      suppressMenu: true,
      formatType: "CHIP",
    },
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      suppressMenu: true,
      width: 200,
      cellRenderer: (params: any) => {
        return (
          <Tag>
            {params.data?.lastUpdatedBy && params.data?.lastUpdatedBy?.email}
          </Tag>
        );
      },
    },
    {
      field: "createdAt",
      headerName: "Created At",
      suppressMenu: true,
      width: 200,
      formatType: "DATE",
    },
    {
      field: "updatedAt",
      headerName: "Last Updated At",
      suppressMenu: true,
      width: 200,
      formatType: "DATE",
    },
    {
      field: "reportCheckerStatus",
      headerName: "Report Download Status",
      suppressMenu: true,
      width: 200,
      cellRenderer: (params: any) => {
        return params.data.reportCheckerStatus ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag color={"green"}>Active</Tag>
            <Button
              size="small"
              onClick={() => handleValidateReportSchema(params.data)}
              loading={validating === `REPORT_${params.data?.moduleId}`}
            >
              Retry Verification
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag color={"red"}>Error</Tag>
            <Button
              size="small"
              onClick={() => handleValidateReportSchema(params.data)}
              loading={validating === `REPORT_${params.data?.moduleId}`}
            >
              Retry Verification
            </Button>
          </div>
        );
      },
    },
    {
      field: "invoiceCheckerStatus",
      headerName: "Invoice Download Status",
      suppressMenu: true,
      width: 200,
      cellRenderer: (params: any) => {
        return params.data.invoiceCheckerStatus ? (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag color={"green"}>Active</Tag>
            <Button
              size="small"
              onClick={() => handleValidateInvoiceSchema(params.data)}
              loading={validating === `INVOICE_${params.data?.moduleId}`}
            >
              Retry Verification
            </Button>
          </div>
        ) : (
          <div style={{ display: "flex", alignItems: "center" }}>
            <Tag color={"red"}>Error</Tag>
            <Button
              size="small"
              onClick={() => handleValidateInvoiceSchema(params.data)}
              loading={validating === `INVOICE_${params.data?.moduleId}`}
            >
              Retry Verification
            </Button>
          </div>
        );
      },
    },
    {
      field: "lastUpdatedBy",
      headerName: "Last Updated By",
      suppressMenu: true,
      width: 200,
      cellRenderer: (params: any) => {
        return (
          <Tag>
            {params.data?.lastUpdatedBy && params.data?.lastUpdatedBy?.email}
          </Tag>
        );
      },
    },

    {
      field: "lastSyncAt",
      headerName: "Last ZA Sync",
      suppressMenu: true,
      width: 200,
      cellRenderer: (params: any) => {
        return (
          <Tag
            color={
              params.data?.status === "PASS"
                ? "green"
                : params.data?.status === "PENDING"
                ? "yellow"
                : "red"
            }
            icon={
              params.data?.status === "PENDING" ? <LoadingOutlined /> : null
            }
          >
            {params.data?.status}
          </Tag>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      suppressMenu: true,
      width: 440,
      cellRenderer: (params: any) => (
        <div>
          <Button
            size="small"
            icon={<EyeOutlined />}
            onClick={() => navigate("/fa/schema/edit/" + params?.data?.viewId)}
          >
            View Schema
          </Button>
          <Button
            size="small"
            icon={<EyeOutlined />}
            onClick={() =>
              navigate("/FA/schema/preview/" + params?.data?.viewId)
            }
            style={{ marginLeft: 12 }}
          >
            Table Preview
          </Button>
          <Popconfirm
            title="Are you sure want to delete?"
            onConfirm={() => handleDeleteSchema(params?.data?.viewId)}
            onCancel={() => null}
            okText="Yes"
            cancelText="No"
          >
            <Button
              size="small"
              icon={<DeleteOutlined />}
              danger
              style={{ marginLeft: 12 }}
            >
              Delete
            </Button>
          </Popconfirm>
          {params.data?.status === "PENDING" ? (
            <Button>Sycn in progress</Button>
          ) : (
            <Popconfirm
              title="Are you sure want to sycn data now?"
              onConfirm={() => handleInitiateSync(params?.data?.moduleId)}
              onCancel={() => null}
              okText="Yes"
              cancelText="No"
            >
              <Button
                size="small"
                icon={<SyncOutlined />}
                // danger
                style={{ marginLeft: 12 }}
              >
                Sync ZA
              </Button>
            </Popconfirm>
          )}
        </div>
      ),
    },
  ];

  const getProcessedData = (type: string) => {
    if (type === "CRM") {
      return (tableData || []).filter((item: any) => item.moduleId === "CRM");
    } else {
      return (tableData || []).filter((item: any) => item.moduleId !== "CRM");
    }
  };

  return (
    <div className="FASetup">
      <div className="Header">
        <PageHeader
          title="Fink Analytics "
          rightActions={
            <>
              <Button
                size="small"
                type="primary"
                onClick={() => navigate("/FA/schema/upload/history")}
                style={{ marginRight: 24 }}
              >
                Column Mapping only
              </Button>
              <Button
                size="small"
                type="primary"
                icon={<PlusOutlined />}
                onClick={() => navigate("/FA/schema/source")}
              >
                {" "}
                Add Schema
              </Button>
            </>
          }
        />
      </div>
      <div
        className="SearchBoxContainer"
        style={{ justifyContent: "flex-start", padding: 12 }}
      >
        {/* <SearchTable
          placeholder="Search ZA Table"
          style={{ width: 400, height: 40 }}
          onChange={handleSearch}

        /> */}

        <Segmented
          options={["Report", "CRM"]}
          onChange={(value) => setActiveTab(value)}
        />
      </div>
      <div style={{ height: "100%", width: "100%" }}>
        {isLoading ? (
          <Loader />
        ) : (
          <AgTableClient
            // @ts-ignore
            rowData={getProcessedData(activeTab)}
            columnDefs={columnDefs}
            hideToolbar
            // autoResize
          />
        )}
      </div>
      {contextHolder}
    </div>
  );
}
