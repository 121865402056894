import React, { useEffect, useState } from "react";
import "./Navbar.css"; // Import the CSS file
import UserGrid from "../AddMember/AddMember";
import ScheduleFollowUps from "../../ScheduleFollowUps/ScheduleFollowUps";
import { Modal } from "antd";
import InviteMember from "app/scenes/Settings/components/InviteMember";
import { userInfo, workspaceInfo } from "app/config/States/users";
import { useRecoilState } from "recoil";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import { apiGet } from "app/services/apiServices";
import { useNavigate } from "react-router-dom";
import Exemption from "../../Exempted_list/exemption";

const NavbarPage = () => {
  const [activeTab, setActiveTab] = useState("Members");
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [workspaces, setWorkspaces] = useState([]);
  const [userDetails, setUserDetails] = useRecoilState(
    userInfo || { workspaces: [] }
  );

  const [searchQuery, setSearchQuery] = useState("");
  const [workspace, setWorkspace] = useRecoilState(workspaceInfo);

  const [selectedWorkspace, setSelectedWorkspace] = useState(workspace || null);
  const [selectedWorkspaceId, setSelectedWorkspaceId] = useState();

  useEffect(() => {
    // Fetch workspace data on mount
    const fetchUserInfo = async () => {
      try {
        const response = await apiGet(API_ENDPOINT_GET_USER_INFO, false);
        console.log("API Response:", response);
        if (response.status && response.data?.workspaces) {
          setWorkspaces(response.data.workspaces);
          setUserDetails({
            ...userDetails,
            workspaces: response.data.workspaces,
          });
          setWorkspace(response.data?.workspaces?.[0]?.name);
          setSelectedWorkspace(response.data?.workspaces?.[0]?.name);
          setFilteredOptions(response.data.workspaces); // Initialize filtered options
          setSelectedWorkspaceId(response.data?.workspaces?.[0]?.id);
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  const [showModal, setShowModal] = useState(false);

  const handleOpenModal = () => {
    setShowModal(true);
  };

  const handleMemberShipSuccess = () => {
    console.log("Membership successfully updated");
    setShowModal(false); // Close the modal on success
  };

  // Filter options based on the search query
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredOptions(
      workspaces.filter((workspace) =>
        workspace.name.toLowerCase().includes(query)
      )
    );
  };

  // Handle selection of a workspace
  const handleSelectOption = (workspace) => {
    setSelectedWorkspace(workspace.name);
    setWorkspace(workspace.name);
    setIsDropdownOpen(false);
    setSearchQuery("");
    setFilteredOptions(workspaces); // Reset the filtered options
  };

  const navigate = useNavigate();

  const goBack = () => {
    navigate(-1); // This will navigate the user back to the previous page
  };

  return (
    <div>
      {/* Navbar */}
      <div className="navbar">
        <div className="navbar-left">
          <button className="vendor-back-btn" onClick={goBack}>
            <svg
              xmlns="https://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
              fill="none"
            >
              <path
                d="M17.0305 10.7422L5.60281 10.7422L12.4427 16.6797C12.552 16.7754 12.4856 16.9531 12.3411 16.9531H10.6126C10.5364 16.9531 10.4641 16.9258 10.4075 16.877L3.02664 10.4727C2.95904 10.4141 2.90482 10.3416 2.86767 10.2602C2.83051 10.1789 2.81128 10.0904 2.81128 10.001C2.81128 9.91151 2.83051 9.8231 2.86767 9.74172C2.90482 9.66034 2.95904 9.58789 3.02664 9.5293L10.4505 3.08594C10.4798 3.06055 10.5149 3.04688 10.552 3.04688L12.3391 3.04687C12.4837 3.04687 12.5501 3.22656 12.4407 3.32031L5.60281 9.25781L17.0305 9.25781C17.1165 9.25781 17.1868 9.32812 17.1868 9.41406V10.5859C17.1868 10.6719 17.1165 10.7422 17.0305 10.7422Z"
                fill="#0A8394"
              />
            </svg>
          </button>
          <h2 className="settinghead">Settings</h2>
          <div className="vendorfollowup-dropdown-container">
            <div
              className={`vendorfollowup-dropdown-header ${
                isDropdownOpen ? "active" : ""
              }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedWorkspace || "Select a workspace"}
              <span className="vendorfollowup-dropdown-icon">
                {isDropdownOpen ? "▲" : "▼"}
              </span>
            </div>

            {isDropdownOpen && (
              <div className="vendorfollowup-dropdown-body">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search workspaces..."
                  className="vendorfollowup-dropdown-search"
                />
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((workspace) => (
                    <div
                      key={workspace.id}
                      className="vendorfollowup-dropdown-item"
                      onClick={() => handleSelectOption(workspace)}
                    >
                      {workspace.name}
                    </div>
                  ))
                ) : (
                  <div className="vendorfollowup-dropdown-item no-options">
                    No workspaces found
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
        <div className="navbar-right">
          <button className="add-member-btn" onClick={handleOpenModal}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="18"
              height="18"
              viewBox="0 0 25 24"
              fill="none"
            >
              <path
                d="M16.25 19H22.25M19.25 16V22M6.25 21V19C6.25 17.9391 6.67143 16.9217 7.42157 16.1716C8.17172 15.4214 9.18913 15 10.25 15H14.25M8.25 7C8.25 8.06087 8.67143 9.07828 9.42157 9.82843C10.1717 10.5786 11.1891 11 12.25 11C13.3109 11 14.3283 10.5786 15.0784 9.82843C15.8286 9.07828 16.25 8.06087 16.25 7C16.25 5.93913 15.8286 4.92172 15.0784 4.17157C14.3283 3.42143 13.3109 3 12.25 3C11.1891 3 10.1717 3.42143 9.42157 4.17157C8.67143 4.92172 8.25 5.93913 8.25 7Z"
                stroke="white"
                stroke-width="1.33333"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
            </svg>{" "}
            Add Member
          </button>
        </div>
      </div>

      {/* Tabs */}
      <div className="tabs">
        <div
          className={`tab ${activeTab === "Members" ? "active-tab" : ""}`}
          onClick={() => setActiveTab("Members")}
        >
          Members
        </div>
        <div
          className={`tab ${
            activeTab === "Schedule follow-ups" ? "active-tab" : ""
          }`}
          onClick={() => setActiveTab("Schedule follow-ups")}
        >
          Schedule follow-ups
        </div>
        <div
          className={`tab ${
            activeTab === "Your Exempted Vendors" ? "active-tab" : ""
          }`}
          onClick={() => setActiveTab("Your Exempted Vendors")}
        >
          Your Exempted Vendors
        </div>
      </div>

      {/* Tab Content */}
      <div className="tab-content">
        {activeTab === "Members" && (
          <UserGrid selectedWorkspace={selectedWorkspaceId} />
        )}
        {activeTab === "Schedule follow-ups" && <ScheduleFollowUps />}
        {activeTab === "Your Exempted Vendors" && <Exemption />}
      </div>
      <Modal
        title="Add Members"
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={600}
      >
        <InviteMember
          onClose={() => setShowModal(false)}
          onSuccess={handleMemberShipSuccess}
        />
      </Modal>
    </div>
  );
};

export default NavbarPage;
