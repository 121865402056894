import Typography from "app/shared/Typography";
import "./GSTAddReview.scss";
import { colorPicker } from "app/utils/color.helper";
import { Carousel } from "react-responsive-carousel";
import AirOnboarIllustration1 from "static/images/illustration/AirOnboardIllustration1.svg";
import AirOnboarIllustration2 from "static/images/illustration/AirOnboardIllustration2.svg";
import AirOnboarIllustration3 from "static/images/illustration/AirOnboardIllustration3.svg";
import ISOCert from "static/images/ISO.svg";
import GDPRCert from "static/images/GDPR.svg";
import SOCCert from "static/images/SOC.svg";
import { Button, Checkbox, Tag ,Modal} from "antd";
import * as csv from "csvtojson";
import * as XLSX from "xlsx";
import {
  SwapOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  ArrowRightOutlined,
  SendOutlined,
  LoadingOutlined,
  ArrowLeftOutlined,
  QuestionCircleOutlined
} from "@ant-design/icons";
import { AgTableClient } from "app/shared/AgTable";
import { STATE_GSTIN_ICON } from "app/shared/AgTable/stateIconMap";
import Uploader from "app/shared/Uploader";
import { useLocation, useNavigate } from "react-router-dom";
import { downloadSampelCrendentialCSV } from "app/utils/generic.helper";
import { apiPost } from "app/services/apiServices";
import { API_ENDPOINT_GST_CREDENTIAL_ENTITY_INFO } from "../../gstcredflow.constants";
import AppLogo from "static/images/FinLogo.png";
import { useEffect, useState } from "react";
import { Padding } from "@mui/icons-material";
import color from "theme/color";
import TextArea from "antd/es/input/TextArea";
import checks from '../../../AirlineCredentialFlow/components/AirlineConfirmPage/checks.svg';
import select from '../../../AirlineCredentialFlow/components/AirlineConfirmPage/select.svg';
import Logo from 'static/images/Finkr@ftLogo.png'

export default function GSTAddReview(props: any) {
  const [gstinData, setGSTINData] = useState<any[]>([]);
  const location = useLocation();
  const stateInfo = location.state;
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(true);
  const [selectedIssues, setSelectedIssues] = useState<string[]>([]);
  const [isCustomSelected, setIsCustomSelected] = useState(false); // State for "Custom" option


  useEffect(() => {
    if (stateInfo) setGSTINData([stateInfo]);
  }, []);

  const handleUpload = async (file: any) => {
    if (!file.originFileObj) {
      const reader = new FileReader();

      reader.onload = async (e: any) => {
        const binaryString = e.target.result;
        let jsonArray;

        if (file.type.includes("csv")) {
          //@ts-ignore
          jsonArray = await csv().fromString(binaryString);
        } else if (
          file.type.includes("sheet") ||
          file.type.includes("excel") ||
          file.name.endsWith(".xls") ||
          file.name.endsWith(".xlsx")
        ) {
          const workbook = XLSX.read(binaryString, { type: "binary" });
          const firstSheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[firstSheetName];
          jsonArray = XLSX.utils.sheet_to_json(worksheet);
        } else {
          console.error("Unsupported file format");
          return;
        }

        // Trim whitespace from each value in the JSON array
        jsonArray = jsonArray.map((row: any) => {
          return Object.fromEntries(
            Object.entries(row).map(([key, value]) => [
              key,
              typeof value === "string" ? value.trim() : value,
            ])
          );
        });

        setGSTINData([...jsonArray, ...gstinData]);

        console.log("jsonArray", jsonArray);
      };

      reader.readAsBinaryString(file);
    }
  };

  const handleBack = () => {
    navigate(-1);
  };

  const showModal = () => setIsModalVisible(true);
  
  const handleModalClose = () => setIsModalVisible(false);
  
    const issues = [
      "Issue_01_regarding some common thing",
      "Issue_02_regarding GST cred",
      "Issue_03_regarding Airline",
      "Issue_04_regarding sign up",
      "Issue_05_regarding some common thing",
      "Custom"
    ];

    const handleCheckboxChange = (issue: string) => {
      if (issue === "Custom") {
        setIsCustomSelected(!isCustomSelected);
        if (!isCustomSelected) {
          setSelectedIssues([issue]); 
        } else {
          setSelectedIssues([]); 
        }
      } else {
        setSelectedIssues((prev) =>
          prev.includes(issue)
            ? prev.filter((item) => item !== issue)
            : [...prev, issue]
        );
      }
    };
  
    const dropdownContent = (
      <div
        style={{
          border: "1px solid ##E8EAF1",
          borderRadius: "4px",
          marginTop: "8px",
          padding: "16px 16px",
          backgroundColor: "#F8F9FB",
          display: "flex",
          gap: "16px",
          flexDirection: "column",
        }}
      >
        {issues.map((issue, index) => (
          issue === "Custom" || !isCustomSelected ? ( // Show all options except "Custom" if not selected
            <div style={{ display: "flex", gap: "15px" }} key={index}>
              <Checkbox
                checked={selectedIssues.includes(issue)}
                onChange={() => handleCheckboxChange(issue)}
              />
              <Typography>{issue}</Typography>
            </div>
          ) : null
        ))}
      </div>
    );
  

  return (
    <div className="GSTAddReview">
      <Typography
        style={{
          marginBottom: '2px',
          color: colorPicker("neutral.700"),
          textAlign: "right",
        }}
      >
        {/* Having trouble?{" "}
        <a style={{ color: colorPicker("primary.700"), cursor: "pointer" }} onClick={showModal} >
          Get help
        </a> */}
      </Typography>
      <div className="MainContainer">
        <div className="LeftContainer">
          <div className="TopLogo">
            <img alt="AppLogo" src={Logo} />
            {/* <div className="MiniDivder" /> */}
          </div>
          <div className="CarouselContainer">
            <Carousel showArrows={false} showThumbs={false} showStatus={false}>
              <div>
                <img
                  src={AirOnboarIllustration1}
                  style={{ width: 288, height: 288 }}
                />
              </div>
              <div>
                <img
                  src={AirOnboarIllustration2}
                  style={{ width: 288, height: 288 }}
                />
              </div>
              <div>
                <img
                  src={AirOnboarIllustration3}
                  style={{ width: 288, height: 288 }}
                />
              </div>
            </Carousel>
          </div>

          <Typography variant="h5" style={{ marginTop: 44 }}>
            500+ companies already saved millions now it’s your turn...
          </Typography>
          <Typography style={{ marginTop: 26 }}>
            Easily track and manage your travel-related expenses with clear,
            vendor-based summaries, optimizing ITC claims and ensuring GST
            compliance.
          </Typography>
          <div className="CompanyContainer" style={{ marginTop: '30px', display: 'flex', gap: '35px' }}>
            <img
              src={
                "https://storage.googleapis.com/assets.cdp.blinkx.in/Blinkx_Website/icons/zee-media-corporation-ltd.png"
              }
              alt="ISO Image"
            />

            <img
              src={
                "https://www.pngplay.com/wp-content/uploads/13/Bajaj-Auto-PNG-HD-Quality.png"
              }
              alt="ISO Image"
            />
            <img
              src={
                "https://w7.pngwing.com/pngs/695/521/png-transparent-comic-book-comics-retail-ikea-coupon-others-thumbnail.png"
              }
              alt="ISO Image"
            />
            <img
              src={
                "https://cdn.iconscout.com/icon/free/png-256/free-unilever-logo-icon-download-in-svg-png-gif-file-formats--company-british-uk-logos-icons-2357829.png?f=webp"
              }
              alt="ISO Image"
            />
            <img
              src={"https://media-publications.bcg.com/BCG_MONOGRAM.png"}
              alt="ISO Image"
            // style={{ marginLeft: 48, marginRight: 48 }}
            />
            <img
              src={
                "https://upload.wikimedia.org/wikipedia/commons/thumb/c/ce/Coca-Cola_logo.svg/1200px-Coca-Cola_logo.svg.png"
              }
              alt="ISO Image"
            />
          </div>

          <div className="CertificateContainer" style={{ marginTop: "50px", display: 'flex', gap: '40px' }}>
            <img src={ISOCert} alt="ISO Image" />
            <img src={SOCCert} alt="ISO Image" />
            <img
              src={GDPRCert}
              alt="ISO Image"
            />
          </div>
        </div>
        <div className="RightContainer" style={{ borderLeft: '1px solid  #E8EAF1', paddingLeft: '15px' }}>
          <span style={{ display: 'inline-flex', alignItems: 'center', marginBottom: 16, cursor: 'pointer' }} onClick={handleBack}>
            <ArrowLeftOutlined style={{ fontSize: '14px', marginRight: 2, color: '#0A8394', fontWeight: '400' }} />
            <Typography style={{ marginLeft: '3px', color: '#0A8394', fontFamily: 'Noto Sans', }}>
              Back
            </Typography>
          </span>
          <Tag color="cyan">Last step ahead</Tag>
          <Typography variant="h5" style={{ marginTop: "20px", marginBotttom: "30px" }}>
            To find out how much 2 entities can save on
          </Typography>

          <div className="PanGstSelectionContainer" style={{ marginTop: '25px' }}>
            <Typography
              variant="caption"
              style={{ color: colorPicker("primary.700") }}
            >
              Entity Name:{" "}
              <span style={{ color: colorPicker("neutral.700"), marginLeft: '5px' }}>
                Schnider Limited
              </span>
            </Typography>
            <Typography
              variant="caption"
              style={{ color: colorPicker("primary.700"), marginLeft: 24 }}
            >
              PAN:{" "}
              <span style={{ color: colorPicker("neutral.700"), marginLeft: '5px' }}>
                AA23221PA1
              </span>
            </Typography>
            <SwapOutlined
              style={{ color: colorPicker("primary.700"), marginLeft: 12 }}
            />
          </div>
          <div className="TableContainer">
            <AgTableClient
              // @ts-ignore
              rowData={gstinData}
              columnDefs={[
                {
                  field: "gstin",
                  headerName: "GSTIN",
                  cellRenderer: (params: any) => {
                    return (
                      <div style={{ display: "flex", alignItems: "center" }}>
                        <img
                          style={{ width: 24, marginRight: 6 }}
                          src={
                            STATE_GSTIN_ICON[params.data.gstin.substring(0, 2)]
                          }
                        />
                        {params.data.gstin}
                      </div>
                    );
                  },
                },
                { field: "username", headerName: "Username" },
                { field: "password", headerName: "Password" },
              ]}
              hideToolbar
              autoResize
            />
          </div>
          <div className="UploadContainer">
            <div className="Header">
              <Typography variant="caption" style={{ color: '#212121' }}>GST Credentials</Typography>
              <Button
                icon={<DownloadOutlined />}
                size="small"
                type="primary"
                onClick={downloadSampelCrendentialCSV}
              >
                Download sample file
              </Button>
            </div>

            <div className="UploaderWidget">
              <Uploader colored onSelect={handleUpload} />
            </div>


            <div className="UploaderFooter">
              <Typography
                variant="caption"
                style={{ color: colorPicker("neutral.700") }}
              >
                <InfoCircleOutlined style={{ marginRight: 4 }} />
                The file should contain GSTIN, Username & Password
              </Typography><br />
            </div>


            <div className="ActionContainer" style={{ display: 'flex', gap: '25px' }}>
              <Button
                icon={<SendOutlined />}
                style={{ width: "48%", color: '#0A8394', borderColor: '#0A8394' }}
                size="large"
                onClick={() => navigate("/credential/gst/invite/user")}
              >
                Invite user who has credentials
              </Button>
              <Button
                icon={<ArrowRightOutlined />}
                style={{ width: "48%" }}
                type="primary"
                size="large"
                onClick={() =>
                  navigate("/credential/gst/add/review/final", {
                    state: gstinData,
                  })
                }
              >
                Submit
              </Button>
            </div>
          </div>
        </div>
      </div>
      <Modal
          visible={isModalVisible}
          onCancel={handleModalClose}
          footer={null}
          title=""
        >
          <span style={{ display: "flex", gap: "10px" }}>
            <QuestionCircleOutlined style={{ color: "#0A8394", fontSize: "24px" }} />
            <Typography
              style={{
                color: "#0A8394",
                fontSize: "24px",
                fontWeight: "400",
                fontFamily: "Noto Sans",
              }}
            >
              Help center
            </Typography>
          </span>
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              marginTop: "20px",
            }}
          >
            <Typography style={{ color: "#212121" }}>Choose the issue here</Typography>
            <img
              src={select}
              onClick={() => setDropdownVisible(!dropdownVisible)}
              style={{ cursor: "pointer" }}
            />
          </div>
          {dropdownVisible && dropdownContent}
  
          {/* Conditionally render the text field when "Custom" is selected */}
          {isCustomSelected && (
            <div style={{ marginTop: "10px" }}>
              <TextArea
                placeholder="Custom issue description"
                style={{ width: "100%" }}
              />
            </div>
          )}
  
          <Button
            type="primary"
            style={{
              width: "100%",
              height: "40px",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              marginTop: "24px",
              fontSize: "16px",
            }}
          >
            <img
              src={checks}
              style={{ width: "26px", height: "26px", marginRight: "8px" }}
            />
            Submit
          </Button>
        </Modal>
    </div>
  );
}
