import {
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Tag,
  notification,
} from "antd";
import { apiGet, apiPost } from "app/services/apiServices";
import TextField from "app/shared/TextField";
import Typography from "app/shared/Typography";
import { useEffect, useState } from "react";
import AppLogo from "static/images/Logo.svg";
import { ArrowRightOutlined } from "@ant-design/icons";
import { colorPicker } from "app/utils/color.helper";
import "../../GSTCredentialFlow.scss";
import { useLocation, useNavigate } from "react-router-dom";

export default function GSTAddPan(props: any) {
  const [pan, setPan] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const [existingPan, setExistingPan] = useState([]);
  const queryParams = new URLSearchParams(location.search);
  const workspace = queryParams.get("workspace") || "DefaultWorkspace";
  const panState = location.state;

  useEffect(() => {
    if (panState?.pan) {
      setExistingPan(panState.pan);
    }
  }, []);

  const isValidPan = (pan: string) => {
    const panRegex = /^[A-Z]{5}[0-9]{4}[A-Z]$/;
    return panRegex.test(pan);
  };

  const handleContinue = async () => {
    const length = pan.trim().length;

    // if (length === 10 || length === 15) {
    //   const payload = {
    //     workspace_name: workspace, // Replace with actual workspace name or dynamic value
    //     [length === 10 ? "pan" : "gstin"]: pan, // Dynamic key
    //   };

    //   try {
    //     // Example API call (replace with your actual API endpoint and method)
    //     const response = await fetch(
    //       "https://scraper-apis.finkraftai.com/add/panorgstin",
    //       {
    //         method: "POST",
    //         headers: {
    //           "Content-Type": "application/json",
    //         },
    //         body: JSON.stringify(payload),
    //       }
    //     );

    //     if (!response.ok) {
    //       throw new Error("Failed to process PAN/GSTIN");
    //     }

    //     const result = await response.json();
    //     notification.success({
    //       message: "Success",
    //       description: "PAN/GSTIN processed successfully!",
    //     });

    //     // Navigate to the next page
    //     navigate(
    //       "/credential/gst/entity_view2?workspace=Piramal%20enterprises"
    //     );
    //   } catch (error) {
    //     let errorMessage = "Something went wrong!";

    //     // Narrow down the error type
    //     if (error instanceof Error) {
    //       errorMessage = error.message;
    //     }

    //     notification.error({
    //       message: "Error",
    //       description: errorMessage,
    //     });
    //   }
    // } else {
    //   notification.error({
    //     message: "Invalid Input",
    //     description: "Please enter a valid PAN or GSTIN .",
    //   });
    // }


    // console.log("pnanon", existingPan);
    // let finalPan: any = existingPan;
    // if (!finalPan.includes(pan)) {
    //   finalPan.push(pan);
    // }
    // navigate("/credential/gst/entity_view2", {
    //   state: { pan: finalPan },
    // });

    if (!isValidPan(pan)) {
      notification.error({
        message: "Invalid PAN",
        description: "Please enter a valid 10-character PAN (e.g., ABCDE1234F).",
      });
      return;
    }

    let finalPan: any = existingPan;
    if (!finalPan.includes(pan)) {
      finalPan.push(pan);
    }
    navigate("/credential/gst/entity_view2", {
      state: { pan: finalPan },
    });
  };

  return (
    <div className="AirlineStart">
      <Tag color="cyan">Onboarding</Tag>
      <Typography variant="h5" style={{ marginTop: 12 }}>
        Let’s add PAN/GSTIN
      </Typography>

      <Typography style={{ marginTop: 12 }}>
        To check claimable amount
      </Typography>

      <div style={{ marginTop: 12 }}>
        <Typography variant="xs" style={{ marginBottom: 4, color: "gray" }}>
          PAN/GSTIN
        </Typography>
        <Input
          placeholder="Enter PAN/GSTIN here"
          onChange={(e) => setPan(e.target.value)}
        />
      </div>

      <Button
        style={{ width: "100%", marginTop: 42 }}
        type="primary"
        icon={<ArrowRightOutlined />}
        iconPosition="end"
        // onClick={() => navigate("/credential/gst/entity_view2", { state: pan })}

        onClick={handleContinue}
      >
        Submit
      </Button>
    </div>
  );
}
