import React, { useEffect, useRef, useState } from "react";
import "./BhilosaDashboard.scss";
import { AgCharts } from "ag-charts-enterprise";
// import 'ag-grid-enterprise';
import BhilosaLogo from "../../../assets/images/BhilosaLogo.png";
import FinkraftLogo from "../../../assets/images/finkraftlogo.png";
import { Button, Modal } from "antd";
import tickIcon from "../../../assets/images/tick.png";
import phoneIcon from "../../../assets/images/phone.png";
import GstinLogo from "../../../assets/images/GstinLogo.png";
import GSTPortal from "../../../assets/images/GSTPage.png";
import warninging from "../../../assets/images/warning.png";
import pivot from "../../../assets/images/pivot.png";
import logout from "../../../assets/images/logout.png";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
import { API_ENDPOINT_GET_USER_INFO } from "app/scenes/Auth/auth.constants";
import { apiGet } from "app/services/apiServices";
import "./App.css";
import { useRecoilState } from "recoil";
import { gst2ADataState, gst2BDataState, userInfo, workspaceInfo } from "app/config/States/users";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRightFromBracket } from '@fortawesome/free-solid-svg-icons';
import { faGear } from '@fortawesome/free-solid-svg-icons';



import { AgGridReact } from "ag-grid-react";
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";


const getMonthOptions = () => {
  const months = [];
  const startMonth = new Date(2024, 3); // April 2024 (months are 0-indexed, so 3 = April)
  const currentMonth = new Date(); // Current date

  // Loop from April 2024 to the current month
  let monthDate = startMonth;
  while (monthDate <= currentMonth) {
    const monthLabel = monthDate.toLocaleString('default', { month: 'short' }) + '-' + monthDate.getFullYear();
    const monthValue = `${(monthDate.getMonth() + 1).toString().padStart(2, '0')}${monthDate.getFullYear()}`;
    months.push({ value: monthValue, label: monthLabel });
    monthDate.setMonth(monthDate.getMonth() + 1); // Move to the next month
  }

  return [{ value: "All", label: "All Months" }, ...months];
};

const GSTIntegration = () => {
  const [workspaces, setWorkspaces] = useState([]);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [filteredOptions, setFilteredOptions] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const { state } = useLocation();
  const [workspace, setWorkspace] = useRecoilState(workspaceInfo);
  const { GstinStatus, workspace: selectedWorkspaceFromLocation } = state || {};

  const [isModalOpen, setIsModalOpen] = useState(false);

  const openModal = () => setIsModalOpen(true);
  const closeModal = () => setIsModalOpen(false);

  const [selectedWorkspace, setSelectedWorkspace] = useState(
    workspace || selectedWorkspaceFromLocation || null
  );

  const hasGstin = true;

  const [counts, setCounts] = useState({
    exempted: 0,
    eligible: 0,
    notEligible: 0,
  });
  const [percentages, setPercentages] = useState({
    exempted: 0,
    eligible: 0,
    notEligible: 0,
  });

  const chartRef = useRef(null);

  const [totalVendors, setTotalVendors] = useState(0);
  const [missingContactCount, setMissingContactCount] = useState(0);
  const [compliantVendors, setCompliantVendors] = useState(0);
  const [nonCompliantVendors, setNonCompliantVendors] = useState(0);




  useEffect(() => {
    let chartInstance;

    if (counts && percentages && counts.exempted !== undefined) {
      const { exempted, eligible, notEligible } = counts;
      const totalVendors = exempted + eligible + notEligible; // Calculate total vendors

      if (chartRef.current) {
        chartInstance = AgCharts.create({
          container: chartRef.current,
          data: [
            {
              category: "Eligible",
              value: eligible,
              label: `${percentages.eligible}%`,
            },
            {
              category: "Not-eligible",
              value: notEligible,
              label: `${percentages.notEligible}%`,
            },
            {
              category: "Exempted",
              value: exempted,
              label: `${percentages.exempted}%`,
            },
          ],
          series: [
            {
              type: "donut",
              angleKey: "value",
              labelKey: "category",
              fills: ["#006D77", "#83C5BE", "#EDF6F9"],
              strokeWidth: 0,
              innerRadiusOffset: -50,
              innerLabels: [
                {
                  text: totalVendors.toString(), // Dynamic total vendors count
                  fontSize: 20,
                  color: "black",
                },
                {
                  text: "Vendors", // Label
                  fontSize: 12,
                  color: "#718096",
                },
              ],
              tooltip: {
                renderer: (params) => {
                  return {
                    content: `${params.datum.category}: ${params.datum.label}`,
                  };
                },
              },
            },
          ],
          legend: {
            enabled: true, // Enable the legend
            position: "right", // Legend is placed on the right
            spacing: 20, // Adjust spacing between legend items
            item: {
              marker: {
                shape: "circle",
                size: 12, // Adjust size of the circle in the legend
                strokeWidth: 0,
              },
              paddingX: 10, // Space between marker and text
              paddingY: 12, // Space between legend items
              label: {
                fontSize: 14,
                color: "#4A5568",
                formatter: ({ datum }) => {
                  if (datum) {
                    // Format legends as: "Category: Count"
                    return `${datum.category}: ${datum.value}`;
                  }
                  return "";
                },
              },
            },
          },
          background: {
            fill: "transparent",
          },
          padding: {
            right: 50, // Adjust padding to accommodate legend
          },
        });
      }
    }

    // Clean up chart instance on unmount or re-render
    return () => {
      if (chartInstance) {
        chartInstance.destroy();
      }
    };
  }, [counts, percentages]); // Re-render when counts or percentages change

  const OnboardingView = () => (
    <div className="onboarding-dashboard">
      <div className="dashboard-header">
        <img src={FinkraftLogo} alt="logo" />
        {/* <div className="dashboard-right-header">
          <div
            className="vendorfollowup-dropdown-container"
            style={{ marginTop: "45px" }}
          >
            <div
              className={`vendorfollowup-dropdown-header ${
                isDropdownOpen ? "active" : ""
              }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedWorkspace || "Select a workspace"}
              <span className="vendorfollowup-dropdown-icon">
                {isDropdownOpen ? "▲" : "▼"}
              </span>
            </div>

            {isDropdownOpen && (
              <div className="vendorfollowup-dropdown-body">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search workspaces..."
                  className="vendorfollowup-dropdown-search"
                />
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((workspace) => (
                    <div
                      key={workspace.id}
                      className="vendorfollowup-dropdown-item"
                      onClick={() => handleSelectOption(workspace)}
                    >
                      {workspace.name}
                    </div>
                  ))
                ) : (
                  <div className="vendorfollowup-dropdown-item no-options">
                    No workspaces found
                  </div>
                )}
              </div>
            )}
          </div>
          <Button className="header-btn">
            <img
              src={phoneIcon}
              alt=""
              style={{ width: "19px", padding: "0px" }}
            />
            Contact{" "}
          </Button>

          
        </div>
      </div>

      <div className="content-grid">
        {/* First Row */}
        <div className="card integration-section">
          <span className="integration-badge">Integration</span>

          <div className="title-container">
            <img
              src={GstinLogo}
              alt="logo"
              style={{ width: "35px", marginTop: "5px" }}
            />
            <h2>GST integration</h2>
          </div>

          <p className="description">
            We will match your invoices with GSTR 2B and generate a report for
            the claimable amount. To proceed, we require you to authenticate
            with GST Portal Credentials.
          </p>

          <div className="button-group">
            <button className="invite-button">
              <span>→</span>
              Invite user who has GST credentials 
            </button>
            <button className="add-pan-button">Add PAN / GSTIN </button>
          </div>

          <div className="info-text">ⓘ Invite user who has credentials</div>
        </div> 

        <div className="card portal-preview">
          <img src={GSTPortal} alt="GST Portal" />
        </div>

        {/* Second Row */}
        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Summary</h3>

          <p className="content">
            Stay ahead of GST regulations with a secure platform that provides
            real-time insights into your vendor ecosystem with seamless GST
            integration, monitor e-invoice eligibility in single place.
          </p>

          <Button
            type="primary"
            disabled
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#9DCDD4",
              color: "#fff",
              border: "none",
            }}
          >
            Continue →
          </Button>
        </div>

        <div className="card info-card">
          <span className="demo-badge">Demo data</span>

          <h3>Vendor Management</h3>

          <ul className="vendor-list">
            <li>Monitor GST return filing status of all vendors.</li>
            <li>Tag and categorise vendors based on compliance.</li>
            <li>Automated notifications to vendors for compliance lapses.</li>
          </ul>

          <Button
            type="primary"
            disabled
            style={{
              width: "100%",
              borderRadius: "8px",
              background: "#9DCDD4",
              color: "#fff",
              border: "none",
            }}
          >
            Continue →
          </Button>
        </div>
      </div>
    </div>
  );

  const navigate = useNavigate();

  const handleNavigation = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate(`/vendorfollowup/vendoreinvoice?workspace=${workspace}`);
  };

  const summarypage = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate(`/credential/gst/list`);
  };

  const handleNavigation1 = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate(`/vendorfollowup/vendorlist`);
  };

  const handleGstIntegration = () => {
    // Navigate to the next page with the hardcoded workspace query parameter
    navigate("/credential/gst/invite/user");
  };
  

  const handleAddPan = () => {
    // Navigate to the next page and pass 'pan' as state
    navigate(`/credential/gst/add/pan`, { state: { pan: "" } });
  };
  const opensettings = () => {
    // Navigate to the next page and pass 'pan' as state
    navigate(`/vendorfollowup/gstintegration/settings`,  );
  };

  const opensetting = () =>{
    navigate(`/vendorfollowup/memebersetting`,);
  }

  // useEffect(() => {
  //   // Fetch vendor data
  //   axios
  //     .get(
  //       `https://invoice-followup.finkraftai.com/vendor-data?workspace=${workspace}`
  //     )
  //     .then((response) => {
  //       const vendorList = response.data.map((vendor) => ({
  //         vendorName: vendor.enablement?.legalNm || "",
  //         gstin: vendor.gstin,
  //         eInvoice: vendor.einvoiceStatus,
  //         invoiceStatus: vendor.invoiceStatus, // Assuming invoiceStatus exists
  //       }));

  //       setTotalVendors(vendorList.length); // Set total vendors count

  //       // Calculate compliant and non-compliant vendors
  //       const compliant = vendorList.filter(
  //         (vendor) =>
  //           vendor.invoiceStatus === "Invoice Received" &&
  //           vendor.eInvoice === "Yes"
  //       ).length;
  //       setCompliantVendors(compliant);

  //       const nonCompliant = vendorList.filter(
  //         (vendor) =>
  //           vendor.invoiceStatus === "Invoice Not Received" &&
  //           vendor.eInvoice === "Yes"
  //       ).length;
  //       setNonCompliantVendors(nonCompliant);

  //       // Fetch contact data
  //       axios
  //         .get(
  //           `https://invoice-followup.finkraftai.com/get-contacts?workspace=${workspace}`
  //         )
  //         .then((contactResponse) => {
  //           const contactData = contactResponse.data;

  //           // Update missing contact count
  //           const missingContacts = vendorList.filter(
  //             (vendor) =>
  //               !contactData.find(
  //                 (contact) =>
  //                   contact.vendorName === vendor.vendorName &&
  //                   contact.gstin === vendor.gstin
  //               )
  //           );
  //           setMissingContactCount(missingContacts.length); // Set missing contacts count
  //         })
  //         .catch((contactError) => {
  //           console.error("Error fetching contact data:", contactError);
  //         });
  //     })
  //     .catch((error) => {
  //       console.error("Error fetching vendor data:", error);
  //     });
  // }, [workspace]);
  const [entities, setEntities] = useState([]);

  // Fetch data in useEffectc
  useEffect(() => {
    const fetchData = async () => {
      try {
        const currentWorkspace = localStorage.getItem('currentWorkspace');
        const response = await axios.get(
          `https://invoice-followup.finkraftai.com/fetch-gstins?workspace_name=${currentWorkspace}`
        );
        if (response.status === 200) {
          // Map the response data into the format required by the UI
          const formattedData = response.data.map((item) => ({
            pan: item.pan,
            tradeName: item.trade_name,
            gstin: item.gstin_count,
            credStatus: "✔ Completed", // Default Cred Status as Active
          }));
          setEntities(formattedData);
        }
      } catch (error) {
        console.error("Error fetching entities:", error);
        setEntities([]);
      }
    };
  
    if (workspace) {
      fetchData();
    }
  }, [workspace]);

 
  





  const [gstAmountData, setGstAmountData] = useState(null);


  const [gst2AData, setGst2AData] = useRecoilState(gst2ADataState); // State for 2A
  const [gst2BData, setGst2BData] = useRecoilState(gst2BDataState); // State for 2B
  const [loading, setLoading] = useState(true);
  const [is2A, setIs2A] = useState(true); // Default to 2A

  // useEffect(() => {
  //   const fetchGstData = async (tableType) => {
  //     console.log("2A",gst2AData)
  //     const apiUrl = "https://invoice-followup.finkraftai.com/sum-gst-eligible";
  //     setLoading(true);

  //     try {
  //       const response = await fetch(apiUrl, {
  //         method: "POST",
  //         headers: {
  //           "Content-Type": "application/json",
  //         },
  //         body: JSON.stringify({
  //           workspaceName: workspace,
  //           tableType: tableType, // Send table type based on toggle (2A or 2B)
  //         }),
  //       });

  //       if (response.ok) {
  //         const data = await response.json();

  //         if (tableType === "2A") {
  //           setGst2AData(data); // Store data for 2A
  //         } else {
  //           setGst2BData(data); // Store data for 2B
  //         }
  //       } else {
  //         console.error("Failed to fetch GST data");
  //       }
  //     } catch (error) {
  //       console.error("Error:", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   // If workspace is available and data is not already present for 2A, fetch 2A data
    
  //   if (workspace && !gst2AData) {
  //     fetchGstData("2A");
  //   }
    

  //   // If workspace is available and data is not already present for 2B, fetch 2B data
  //   if (workspace && !gst2BData) {
  //     fetchGstData("2B");
  //   }

  // }, [workspace, gst2AData, gst2BData, setGst2AData, setGst2BData]);

  const [isMonthsDropdownOpen, setIsMonthsDropdownOpen] = useState(false);
  // const [selectedMonths, setSelectedMonths] = useState([
  //   "All", // Initialize with "All" selected
  //   "042024",
  //   "052024",
  //   "062024",
  //   "072024",
  //   "082024",
  //   "092024",
  //   "102024",
  //   "112024",
  //   "122024",
  // ]);

  // const [monthOptions] = useState([
  //   { value: "All", label: "All Months" },
  //   { value: "042024", label: "Apr-2024" },
  //   { value: "052024", label: "May-2024" },
  //   { value: "062024", label: "Jun-2024" },
  //   { value: "072024", label: "Jul-2024" },
  //   { value: "082024", label: "Aug-2024" },
  //   { value: "092024", label: "Sep-2024" },
  //   { value: "102024", label: "Oct-2024" },
  //   { value: "112024", label: "Nov-2024" },
  //   { value: "122024", label: "Dec-2024" },
  // ]);


  const [selectedMonths, setSelectedMonths] = useState(["All"]);
  const [monthOptions, setMonthOptions] = useState([]);

  // Handle checkbox change
  const handleCheckboxChange = (month) => {
    setSelectedMonths((prev) => {
      if (month === "All") {
        // Toggle "All" checkbox
        if (prev.includes("All")) {
          return []; // Deselect all months if "All" is unchecked
        } else {
          return ["All", ...monthOptions.map((option) => option.value)];
        }
      } else {
        if (prev.includes(month)) {
          return prev.filter((item) => item !== month); // Deselect specific month
        } else {
          return [...prev, month]; // Select specific month
        }
      }
    });
  };

  // Prepare selected months for API payload
  const getPayloadMonths = () => {
    // If "All" is selected, use all months, otherwise return selected months excluding "All"
    return selectedMonths.includes("All")
      ? monthOptions.map((option) => option.value).filter((value) => value !== "All")
      : selectedMonths;
  };

  // Fetch GST data API
  const fetchGstData = async (tableType) => {
    const apiUrl = "https://invoice-followup.finkraftai.com/sum-gst-eligible";
    setLoading(true);

    try {
      const response = await fetch(apiUrl, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          workspaceName: workspace,
          tableType: tableType, // Send table type based on toggle (2A or 2B)
          month: getPayloadMonths(), // Send selected months (processed by getPayloadMonths)
        }),
      });

      if (response.ok) {
        const data = await response.json();

        if (tableType === "2A") {
          setGst2AData(data); // Store data for 2A
        } else {
          setGst2BData(data); // Store data for 2B
        }
      } else {
        console.error("Failed to fetch GST data");
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setLoading(false);
    }
  };

  const handleSubmit = () => {
    // Call fetch for both table types (2A and 2B)
    fetchGstData("2A");
    fetchGstData("2B");
    setIsMonthsDropdownOpen(false);
  };

  useEffect(() => {
    if (workspace) {
      handleSubmit(); // Initial fetch
    }
  }, [workspace]);
  
  const currentData = is2A ? gst2AData : gst2BData;

  const [userDetails, setUserDetails] = useRecoilState(userInfo || { workspaces: [] });



  const [isFetchingData, setIsFetchingData] = useState(true);

  useEffect(() => {
    if (!workspace) return;
  
    const fetchCounts = async () => {
      try {
        setIsFetchingData(true); // Show loader
        const response = await fetch(
          `https://invoice-followup.finkraftai.com/get-eligibility-count?workspace=${workspace}&tableType=${is2A ? "2A" : "2B"}`
        );
  
        if (!response.ok) {
          throw new Error(`Error: ${response.status} ${response.statusText}`);
        }
  
        const data = await response.json();
        console.log("API Data:", data);
  
        setCounts({
          exempted: data.counts?.exempted || 0,
          eligible: data.counts?.eligible || 0,
          notEligible: data.counts?.not_eligible || 0,
        });
  
        setPercentages({
          exempted: data.percentages?.exempted || 0,
          eligible: data.percentages?.eligible || 0,
          notEligible: data.percentages?.not_eligible || 0,
        });
      } catch (err) {
        console.error("Error fetching counts:", err);
        setCounts({});
        setPercentages({});
      }
      finally{
        setIsFetchingData(false); 
      }
    };
  
    fetchCounts();
  }, [workspace, is2A]); 


 
  
  
  useEffect(() => {
    // Fetch workspace data on mount
    const fetchUserInfo = async () => {
      try {
        const response = await apiGet(API_ENDPOINT_GET_USER_INFO, false);
        console.log("API Response:", response);
        if (response.status && response.data?.workspaces) {
          setWorkspaces(response.data.workspaces);
          setUserDetails({ ...userDetails, workspaces: response.data.workspaces });
          setWorkspace(response.data?.workspaces?.[0]?.name);
          setSelectedWorkspace(response.data?.workspaces?.[0]?.name);
          setFilteredOptions(response.data.workspaces); // Initialize filtered options
        }
      } catch (error) {
        console.error("Error fetching user info:", error);
      }
    };

    fetchUserInfo();
  }, []);

  // Filter options based on the search query
  const handleSearch = (e) => {
    const query = e.target.value.toLowerCase();
    setSearchQuery(query);
    setFilteredOptions(
      workspaces.filter((workspace) =>
        workspace.name.toLowerCase().includes(query)
      )
    );
  };

  // Handle selection of a workspace
  const handleSelectOption = (workspace) => {
    setSelectedWorkspace(workspace.name);
    setWorkspace(workspace.name);
    setIsDropdownOpen(false);
    setSearchQuery("");
    setFilteredOptions(workspaces); // Reset the filtered options
  };


  const toggleTable = () => {
    setIs2A((prev) => !prev); // Toggle between 2A and 2B
  };


  const [showPopup, setShowPopup] = useState(false);


  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/signin");
  };

  const handleCancel = () => {
    setShowPopup(false);
  };
  const [rowData, setRowData] = useState([]);


  const transformData = (apiData) => {
    // Extract InvoiceBreakdown safely
    const breakdown = apiData[0]?.InvoiceBreakdown || {};
  
    // Safely extract Invoice Received and Invoice Not Received breakdowns
    const receivedBreakdown = breakdown["Invoice Received"]?.EligiblityBreakdown || {};
    const notReceivedBreakdown = breakdown["Invoice Not Received"]?.EligiblityBreakdown || {};
  
    // Define a function to safely access nested properties
    const getSafeValue = (obj, key, defaultValue = 0) => obj?.[key] || defaultValue;
  
    return [
      {
        category: "Eligible",
        receivedInvoiceCount: getSafeValue(receivedBreakdown.Eligible, "InvoiceCount"),
        receivedTotalAmount: getSafeValue(receivedBreakdown.Eligible, "Amount"),
        receivedTotalGST: getSafeValue(receivedBreakdown.Eligible, "GSTAmount"),
        notReceivedInvoiceCount: getSafeValue(notReceivedBreakdown.Eligible, "InvoiceCount"),
        notReceivedTotalAmount: getSafeValue(notReceivedBreakdown.Eligible, "Amount"),
        notReceivedTotalGST: getSafeValue(notReceivedBreakdown.Eligible, "GSTAmount"),
      },
      {
        category: "Not Eligible",
        receivedInvoiceCount: getSafeValue(receivedBreakdown["Not Eligible"], "InvoiceCount"),
        receivedTotalAmount: getSafeValue(receivedBreakdown["Not Eligible"], "Amount"),
        receivedTotalGST: getSafeValue(receivedBreakdown["Not Eligible"], "GSTAmount"),
        notReceivedInvoiceCount: getSafeValue(notReceivedBreakdown["Not Eligible"], "InvoiceCount"),
        notReceivedTotalAmount: getSafeValue(notReceivedBreakdown["Not Eligible"], "Amount"),
        notReceivedTotalGST: getSafeValue(notReceivedBreakdown["Not Eligible"], "GSTAmount"),
      },
      {
        category: "Exempted",
        receivedInvoiceCount: getSafeValue(receivedBreakdown.Exempted, "InvoiceCount"),
        receivedTotalAmount: getSafeValue(receivedBreakdown.Exempted, "Amount"),
        receivedTotalGST: getSafeValue(receivedBreakdown.Exempted, "GSTAmount"),
        notReceivedInvoiceCount: getSafeValue(notReceivedBreakdown.Exempted, "InvoiceCount"),
        notReceivedTotalAmount: getSafeValue(notReceivedBreakdown.Exempted, "Amount"),
        notReceivedTotalGST: getSafeValue(notReceivedBreakdown.Exempted, "GSTAmount"),
      },
    ];
  };
  

  

  const columnDefs = [
    {
      headerName: "Category",
      field: "category",
    },
    {
      headerName: "Invoice Received",
      children: [
        {
          headerName: "Invoice Count",
          field: "receivedInvoiceCount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value); 
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
        {
          headerName: "Total Amount",
          field: "receivedTotalAmount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value); 
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
        {
          headerName: "Total GST Amount",
          field: "receivedTotalGST",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value); 
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
      ],
    },
    {
      headerName: "Invoice Not Received",
      children: [
        {
          headerName: "Invoice Count",
          field: "notReceivedInvoiceCount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value); 
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
        {
          headerName: "Total Amount",
          field: "notReceivedTotalAmount",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value); 
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
        {
          headerName: "Total GST Amount",
          field: "notReceivedTotalGST",
          valueFormatter: (params) => {
            if (params.value !== undefined && params.value !== null) {
              const roundedValue = Math.round(params.value); 
              return new Intl.NumberFormat('en-IN', { minimumFractionDigits: 0 }).format(roundedValue);
            }
            return '';
          },
          cellStyle: () => ({
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
          }),
        },
      ],
    },
  ];
  

  const defaultColDef = {
    sortable: true,
    filter: true,
    resizable: true,
    flex:1
  };

  useEffect(() => {
    const options = getMonthOptions();
    setMonthOptions(options);
    // Set selected months to include all available months (including "All")
    const allMonthsSelected = options.map(option => option.value);
    setSelectedMonths(allMonthsSelected); // Select all by default
  }, []);

  


  

useEffect(() => {
  const currentWorkspace = localStorage.getItem("currentWorkspace");
  const dataType = is2A ? "2A" : "2B"; // Determine the data type based on toggle
  setIsFetchingData(true);

  // API call
  fetch("https://invoice-followup.finkraftai.com/einvoice-pipeline", {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify({
      workspace_id: currentWorkspace,
      type: dataType, // Include the type (2A or 2B) in the payload
    }),
  })
    .then((response) => {
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      return response.json();
    })
    .then((data) => {
      const transformedData = transformData(data);
      setRowData(transformedData);
    })
    .catch((error) => console.error("Error fetching data:", error))
    .finally(() => {
      setIsFetchingData(false); // Ensure fetching state is reset
    });
}, [is2A]); // Re-run effect when is2A changes

const [isPivotTableModalOpen, setIsPivotTableModalOpen] = useState(false);

// Handle modal toggle
const handlePivotTableModalToggle = () => {
  setIsPivotTableModalOpen(!isPivotTableModalOpen);
};

  return hasGstin ? (
    <div className="dashboard">
      {/* Pivot Table Modal */}
      {isPivotTableModalOpen && (
        <div className="modal-overlay">
          <div className="modal-content-pivot">
            <span className="close" style={{marginBottom:'20px'}} onClick={handlePivotTableModalToggle}>
              &times;
            </span>
            {/* Display Ag-Grid table inside modal */}
            <div
              className="ag-theme-alpine"
              style={{ height: 210, width: '100%', marginTop: '50px' }}
            >
              <AgGridReact
                rowData={rowData}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
              />
            </div>
          </div>
        </div>
      )}
      {isModalOpen && (
        <div
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            backgroundColor: '#fff',
            padding: '20px',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            padding:'40px',
          }}
        >
          <h3 style={{ marginBottom: '40px',fontWeight:'500',color:'grey' }}>You will be Re-Directed to Onboarding.Finkratf our support portal Login with your organization credentials to raise a ticket" and the button will be "cancel" , "Raise Ticket"</h3>
          <div style={{ display: 'flex', justifyContent:'space-between' }}>
            <button
              style={{
                padding: '10px 60px',
                borderRadius: '5px',
                backgroundColor: '#007bff',
                color: '#fff',
                border: 'none',
                cursor: 'pointer',
                fontSize:'18px'
              }}
              onClick={() => window.open('https://onboarding.finkraft.ai/projects/450451/plan', '_blank')}
            >
              Redirect ↗️
            </button>
            <button
              onClick={closeModal}
              style={{
                padding: '10px 60px',
                borderRadius: '5px',
                backgroundColor: '#ccc',
                color: '#000',
                border: 'none',
                cursor: 'pointer',
                fontSize:'18px'
              }}
            >
              Cancel 🚫
            </button>
          </div>
        </div>
      )}
      <div className="dashboard-header">
        <img src={FinkraftLogo} alt="logo" className="finklogo" />
        <div className="dashboard-right-header">
        <div >
        <label style={{
  position: 'relative',
  display: 'inline-block',
  width: '220px', /* Adjust width for both names */
  height: '32px',
  backgroundColor: '#ffffff', /* White background */
  borderRadius: '10px',
  border: '2px solid #0A8394', /* Light green border */
  cursor: 'pointer',
  boxShadow: '0 4px 6px rgba(0, 0, 0, 0.1)', /* Subtle shadow */
  overflow: 'hidden',
  display: 'flex', /* Ensures no gap between elements */
} }>
  <input type="checkbox" checked={is2A} onChange={toggleTable} style={{ display: 'none' }} />
  <div style={{
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    position: 'relative',
    height: '100%',
    width: '100%',
    fontFamily: 'Arial, sans-serif',
    fontSize: '13px',
    fontWeight: '600',
  }}>
    <span style={{
      flex: '1',
      textAlign: 'center',
      color: is2A ? '#ffffff' : '#000', /* Default text color */
      backgroundColor: is2A ? '#0A8394' : 'transparent', /* Light green background */
      borderRadius: '50px',
      padding: '6px 20px', /* Reduced padding to eliminate gap */
      transition: 'all 0.3s ease',
    }}>
      2A
    </span>
    <span style={{
      flex: '1',
      textAlign: 'center',
      color: !is2A ? '#ffffff' : '#000', /* Default text color */
      backgroundColor: !is2A ? '#0A8394' : 'transparent', /* Light green background */
      borderRadius: '50px',
      padding: '6px 20px', /* Reduced padding to eliminate gap */
      transition: 'all 0.3s ease',
    }}>
      2B
    </span>
  </div>
</label>

  </div>
          {/* <div
            className="vendorfollowup-dropdown-container"
            style={{ marginTop: "25px" }}
          >
            <div
              className={`vendorfollowup-dropdown-header ${
                isDropdownOpen ? "active" : ""
              }`}
              onClick={() => setIsDropdownOpen(!isDropdownOpen)}
            >
              {selectedWorkspace || "Select a workspace"}
              <span className="vendorfollowup-dropdown-icon">
                {isDropdownOpen ? "▲" : "▼"}
              </span>
            </div>

            {isDropdownOpen && (
              <div className="vendorfollowup-dropdown-body">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={handleSearch}
                  placeholder="Search workspaces..."
                  className="vendorfollowup-dropdown-search"
                />
                {filteredOptions.length > 0 ? (
                  filteredOptions.map((workspace) => (
                    <div
                      key={workspace.id}
                      className="vendorfollowup-dropdown-item"
                      onClick={() => handleSelectOption(workspace)}
                    >
                      {workspace.name}
                    </div>
                  ))
                ) : (
                  <div className="vendorfollowup-dropdown-item no-options">
                    No workspaces found
                  </div>
                )}
              </div>
            )}
          </div> */}
          <Button
            className='header-btn-top'
            onClick={openModal}
          >
            <img src={phoneIcon} alt='' style={{ width: '19px', padding: '0px' }} />
            Contact Support
          </Button>
          <Button
            className='header-btn-top'
            onClick={opensetting}
          >
            <FontAwesomeIcon icon={faGear} />
            Settings
          </Button>
          <Button
            className='header-btn-top'
            onClick={() => setShowPopup(true)}
          >
            <FontAwesomeIcon icon={faRightFromBracket}  />
            Log Out
          </Button>
        </div>
      </div>
      <div className="container-first-row">
        <div className="header">
          <span className="integration-badge">Integration</span>

          <div className="title-container">
            {/* <div className="icon">G</div> */}
            <img
              src={GstinLogo}
              alt="logo"
              style={{ width: "35px", marginTop: "5px" }}
            />
            <h2>GST integration</h2>
          </div>

          <p className="description">
            We will match your invoices with GSTR 2B and generate a report for
            the claimable amount. To proceed, we require you to authenticate
            with GST Portal Credentials.
          </p>

          <div className="button-group">
            <button className="invite-button" onClick={handleGstIntegration}>
              <svg
                width="16"
                height="16"
                viewBox="0 0 24 24"
                fill="none"
                stroke="currentColor"
                strokeWidth="2"
              >
                <path d="M4 12h16M12 4l8 8-8 8" />
              </svg>
              Invite user who has GST credentials
            </button>

            <button className="add-pan-button" onClick={handleAddPan}>
              Add PAN / GSTIN
            </button>
          </div>

          <div className="first-row-info">
            ⓘ Invite user who has credentials
          </div>
        </div>

        <div className="entities-section">
          <div className="entities-header">
            <h3>
              {entities.length} entities added, Are you associated with more
              entities?
            </h3>
            <button className="view-detail" onClick={summarypage}>
              View in detail ↗{" "}
            </button>
          </div>

          <div className="entities-list">
            {entities.map((entity) => (
              <div key={entity.pan} className="entity-card">
                <div className="entity-header">
                  <img src={tickIcon} alt="tick" />
                  <span className="pan-label">PAN :</span>
                  <span className="pan-value">{entity.pan}</span>
                  <span className="status-active">● Active</span>
                </div>

                <div className="underline-1"></div>

                <div className="entity-details">
                  <div className="detail-group">
                    <p className="label">Trade name :</p>
                    <p className="value">{entity.tradeName}</p>
                  </div>
                  <div className="detail-group">
                    <p className="label">Your Associated GSTIN's :</p>
                    <p className="value">{entity.gstin}</p>
                  </div>
                  <div className="detail-group">
                    <p className="label">Cred Status :</p>
                    <span
                      className={`status-badge ${entity.credStatus.toLowerCase()}`}
                    >
                      {entity.credStatus}
                    </span>
                  </div>
                </div>
              </div>
            ))}
          </div>

        </div>
      </div>

      <div className="container-second-row">

  
    <>
      {/* Vendors Management Section */}
      <section className="dashboard-section" >
        <div className="section-header">
          <div className="header-tabs">
            <div> 
              <button className="tab-button active">Immediate action</button>
              </div>

              <div>
      <div className="months-dropdown-container">
        <button
          className="months-dropdown-button"
          onClick={() => setIsMonthsDropdownOpen(!isMonthsDropdownOpen)}
        >
          {selectedMonths.includes("All")
            ? "All Months Selected"
            : `${selectedMonths.length} Month(s) Selected`}
          <span className="dropdown-toggle">{isMonthsDropdownOpen ? "▼" : "▲"}</span>
        </button>

        {isMonthsDropdownOpen && (
          <div className="months-dropdown-menu">
            {monthOptions.map((month) => (
              <div key={month.value} className="months-checkbox-option">
                <input
                  type="checkbox"
                  id={month.value}
                  checked={selectedMonths.includes(month.value)}
                  onChange={() => handleCheckboxChange(month.value)}
                />
                <label htmlFor={month.value}>{month.label}</label>
              </div>
            ))}
            <button onClick={handleSubmit} className="months-submit-button">
              Submit
            </button>
          </div>
        )}
      </div>
    </div>
          </div>
        </div>
        {/* Date Range Section */}
        {/* <div className="date-range-section">
          From{" "}
          {new Date(new Date().setMonth(3, 1)).toLocaleDateString("en-GB", { day: "numeric", month: "long" })} 
          To{" "}
          {new Date(new Date().setDate(new Date().getDate() - 3)).toLocaleDateString("en-GB", { day: "numeric", month: "long" })} 
        </div> */}



        <div className="section-subheading">
          <div>Follow-ups </div>
          <a href="#" className="view-detail" onClick={handleNavigation}>
            View in detail ↗
          </a>
        </div>
       

        <div className="Amount-card">
          <div className="Amount-card-left">
            <img src={warninging} alt="Warning" />
            <p>
              Eligible but No E-Invoice generated :<br />
              (Total GST amount at risk)
            </p>
          </div>
          <div className="Amount-card-right">
        {loading ? (
          <div className="loading-container">
            <div className="spinner"></div> {/* Animated spinner */}
          </div>
        ) : currentData ? (
          <h1>₹ {currentData.totalGstAmountSum.toLocaleString("en-IN")}</h1>
        ) : (
          <h1>₹ No GST data available</h1>
        )}
      </div>
        </div>
        <div className="Amount-card" style={{marginTop:'20px'}}>
          <div className="Amount-card-left">
            <img src={pivot} style={{width:'30px',marginRight:'20px'}} />
            <h1>Summary </h1>
          </div>
          <div className="Amount-card-right" onClick={handlePivotTableModalToggle} style={{cursor:'pointer'}}>
       View Pivot Table
      </div>
        </div>

        
        {/* <div
      className="ag-theme-alpine"
      style={{ height: 210, width: "100%",marginTop:'20px' }}
    >
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
      />
    </div> */}
      </section>
    </>
  



        {/* Overview Section */}
        <section className="dashboard-section" onClick={handleNavigation1}>
          <div className="section-header">
            <div className="header-tabs">
              <button className="tab-button active">Overview</button>
            </div>
          </div>

          <div className="section-subheading">
            <div> Summary </div>
            <a href="#" className="view-detail">
              View in detail ↗
            </a>
          </div>

          <div className="section-content">
      <div className="eligibility-chart">
        <div className="chart-heading">Vendor E-invoice eligibility</div>
        <div className="chart-container">
          {isFetchingData && (
            <div className="loader-overlay">
              <div className="spinner"></div> {/* Animated spinner */}
            </div>
          )}
          <div ref={chartRef} style={{ width: "70%", height: "255px" }} />
          <div className="custom-legend">
            <ul>
              <li>
                <span
                  className="legend-marker"
                  style={{ backgroundColor: "#006D77" }}
                ></span>
                Eligible: {counts.eligible}
              </li>
              <li>
                <span
                  className="legend-marker"
                  style={{ backgroundColor: "#83C5BE" }}
                ></span>
                Not-eligible: {counts.notEligible}
              </li>
              <li>
                <span
                  className="legend-marker"
                  style={{ backgroundColor: "#EDF6F9" }}
                ></span>
                Exempted: {counts.exempted}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
        </section>
        {showPopup && (
        <div
          // style={{
          //   position: "fixed",
          //   top: 0,
          //   left: 0,
          //   width: "100vw",
          //   height: "100vh",
          //   backgroundColor: "rgba(0, 0, 0, 0.5)",
          //   display: "flex",
          //   justifyContent: "center",
          //   alignItems: "center",
          // }}
          style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: 1000,
            backgroundColor: '#fff',
            borderRadius: '8px',
            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
            padding:'40px',
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width:'400px',
            height:'10%'
          }}
        >
          <div
            style={{
              borderRadius: "10px",
              padding: "20px",
              textAlign: "center",
              maxWidth: "400px",
              width: "100%",
            }}
          >
            <h1 style={{ margin: "0 0 20px 0",fontWeight:'600' }}>Logout Confirmation</h1>
            <p style={{ margin: "0 0 20px 0" }}>
              Are you sure you want to log out?
            </p>
            <div style={{ display: "flex", justifyContent: "space-between" }}>
              <button
                onClick={handleCancel}
                style={{
                  backgroundColor: "#ccc",
                  color: "#333",
                  border: "none",
                  padding: "10px 35px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                No
              </button>
              <button
                onClick={handleLogout}
                style={{
                  backgroundColor: "#ff4d4f",
                  color: "white",
                  border: "none",
                  padding: "10px 20px",
                  borderRadius: "5px",
                  cursor: "pointer",
                }}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      )}
        
      </div>
    </div>
  ) : (
    <OnboardingView />
  );
};



export default React.memo(GSTIntegration);