import React from "react";
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise/styles/ag-grid.css';
import 'ag-grid-enterprise/styles/ag-theme-alpine.css';
import './gstrb_Tables.css';
import { Empty } from 'antd';

const GSTR1FilingDates = ({ gstr1FilingDates }) => {
  // Define column definitions for ag-Grid
  const columnDefs = [
    { headerName: "Month", field: "month", sortable: true, filter: true },
    { headerName: "Date of Filing", field: "dateOfFiling", sortable: true, filter: true },
  ];

  return (
    <div className="gstr3b-table-container">
      <h2 style={{ color: 'hsla(187, 87%, 31%, 1)' }}>GSTR3B Filing Status Summary</h2>
      <div className="header-line"></div>
      {gstr1FilingDates && gstr1FilingDates.length > 0 ? (
        <div className="ag-theme-alpine" style={{ height: 250, width: '100%' }}>
          <AgGridReact
            columnDefs={columnDefs}
            rowData={gstr1FilingDates}
            domLayout="autoHeight"
            headerHeight={50}
            rowHeight={30}
          />
        </div>
      ) : (
        <div style={{ height: '100%', width: '100%', display: 'flex', justifyContent: 'center', paddingTop: '68px' }}>
          <Empty description="No GSTR3B Summary Available" />
        </div>
      )}
    </div>
  );
};

export default GSTR1FilingDates;
