import { AgGridReact } from "ag-grid-react";
import "ag-grid-enterprise"; // Import Ag-Grid Enterprise features
import "ag-grid-enterprise/styles/ag-grid.css";
import "ag-grid-enterprise/styles/ag-theme-quartz.css";
import "../AgTable.scss";
import FinkraftLogo from "static/images/FinLogo.png";
// import "../AgTheme.css"
import { Button, message } from "antd";
import { DownloadOutlined } from "@ant-design/icons";
import { handleAgCellDataFormat, handleCellRenderer } from "../AgUtility";
import React, {
  forwardRef,
  memo,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import PDFViewerTool from "../HelperTools/PDFViewerTool";
import PDFViewerToolV2 from "../HelperTools/PDFViewerTool/pdfViewerV2";
import {
  API_ENDPOINT_AG_TABLE_LOAD_VIEW,
  API_ENDPOINT_AG_TABLE_SAVE_VIEW,
  API_ENDPOINT_AG_TABLE_UPDATE_VIEW,
} from "../AgTable.constants";
import { Workbook } from "exceljs";
import * as FileSaver from "file-saver";
import {
  CheckCircleFilled,
  CloseCircleFilled,
  RightCircleOutlined,
} from "@ant-design/icons";
import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import Loader from "app/shared/Loader";
import CRMTransfers from "app/shared/CRMTRansfer";
import { width } from "@mui/system";

const detailCellRendererParams = {
  detailGridOptions: {
    columnDefs: [{ field: "price", headerName: "Price" }],
  },
  getDetailRowData: (params) => {
    params.successCallback(params.data.details);
  },
};

const AgTableClient = forwardRef(function (props, ref) {
  // Column definitions
  const [gridApi, setGridApi] = useState(null);
  const [gridColumnApi, setGridColumnApi] = useState(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [loadingView, setLoadingView] = useState(false);
  const [initialColumnState, setInitialColumnState] = useState([]);

  const gridRef = useRef();
  const processColumnDefs = (columnDefs) => {
    return columnDefs.map((obj) => ({
      ...obj, // Spread the original object
      ...(obj.formatType ? handleCellRenderer() : {}), // Add the new key-value pair
      ...(obj.editRow
        ? {
            editFunction: (params, status, colKey) =>
              handleCellEditing(params, status, colKey),
          }
        : {}),
    }));
  };

  useEffect(() => {
    if (gridApi && gridColumnApi) {
      props.autoResize && gridApi.sizeColumnsToFit();
    }
  }, [props.columnDefs, gridApi, gridColumnApi]);

  const onGridReady = useCallback(async (params) => {
    setGridApi(params.api);
    setGridColumnApi(params.columnApi);

    params.api.autoSizeAllColumns();
  }, []);

  const onCellValueChanged = useCallback((event) => {}, []);

  const onRowValueChanged = useCallback((event) => {
    props.onRowSaved && props.onRowSaved(event.data);
  }, []);

  const handleCellEditing = (params, editingOn, colKey) => {
    if (!editingOn) {
      onBtStopEditing();
    } else {
      onBtStartEditing(params, colKey);
    }
  };
  const onBtStartEditing = useCallback((params, colKey) => {
    gridRef.current.api.setFocusedCell(params?.rowIndex, colKey);
    gridRef.current.api.startEditingCell({
      rowIndex: params?.rowIndex,
      colKey: colKey,
    });
  }, []);

  const onBtStopEditing = useCallback(() => {
    gridRef.current.api.stopEditing();
  }, []);

  const defaultExcelExportParams = useMemo(() => {
    return {
      autoConvertFormulas: true,
      processCellCallback: (params) => {
        const field = params.column.colDef;
        return field.cellClass === "hyperlinks"
          ? `=HYPERLINK("${params.value}")`
          : params.value;
      },
    };
  }, []);

  const exportDataToExcel = useCallback((params) => {
    // const performExport = async () => {
    //   const workbook = new Workbook();

    //   // Add a cover page sheet
    //   const coverSheet = workbook.addWorksheet("Cover");
    //   const imageId = workbook.addImage({
    //     base64: FinkraftLogo,
    //     extension: "png",
    //   });
    //   coverSheet.mergeCells("A1:G6");

    //   // Add the image with fixed size and positioning
    //   coverSheet.addImage(imageId, {
    //     tl: { col: 0, row: 0 },
    //     ext: { width: 300, height: 100 },
    //     editAs: "oneCell",
    //   });
    //   coverSheet.mergeCells("A8:J10");
    //   const titleCell = coverSheet.getCell("A8");
    //   titleCell.value = "Policy Bazar Recon Report";
    //   titleCell.font = { size: 24, bold: true };
    //   titleCell.alignment = { vertical: "middle", horizontal: "center" };
    //   titleCell.fill = {
    //     type: "pattern",
    //     pattern: "solid",
    //     fgColor: { argb: "FFFFA500" },
    //   };
    //   coverSheet.mergeCells("A11:J12");

    //   coverSheet.mergeCells("A13:C13");
    //   coverSheet.mergeCells("D13:G13");

    //   // Set the font style and size for the title
    //   coverSheet.addRow(["Created By", "Tabrez Ansari"]).font = {
    //     size: 16,
    //     bold: true,
    //   };
    //   coverSheet.addRow([]); // Empty row
    //   coverSheet.addRow(["Created On", "23/20/2024"]).font = {
    //     size: 16,
    //     bold: true,
    //   };
    //   coverSheet.addRow([]); // Placeholder row for positioning
    //   const headerRow = coverSheet.addRow([
    //     "Column",
    //     "Data Type",
    //     "Aggregation Type",
    //     "Aggregation Value",
    //   ]);
    //   const mergeOffsets = ["A", "C", "E", "G"];

    //   headerRow.eachCell((cell, colNumber) => {
    //     cell.font = { bold: true, color: { argb: "FF0000" }, size: 12 };
    //     const startCell = mergeOffsets[colNumber - 1]; // Calculate start cell address
    //     const endCell = String.fromCharCode(startCell.charCodeAt(0) + 1); // Get next column address
    //     coverSheet.mergeCells(
    //       `${startCell}${headerRow.number}:${endCell}${headerRow.number}`
    //     );
    //   });

    //   // coverSheet.mergeCells("E8:G8"); // Merge cells for the header row

    //   // Step 3: Generate the grid data sheet using AG-Grid's getSheetDataForExcel
    //   const gridDataSheet = gridRef.current.api.getSheetDataForExcel();

    //   // Check if gridDataSheet.data exists and is an array before proceeding
    //   if (gridDataSheet && Array.isArray(gridDataSheet.rowData)) {
    //     // Step 4: Use ExcelJS utils to convert AG Grid generated sheet data into a worksheet
    //     const agGridWorksheet = workbook.addWorksheet("Data");
    //     gridDataSheet.rowData.forEach((row) => {
    //       agGridWorksheet.addRow(
    //         row.map((cell) => (cell.data ? cell.data.value : ""))
    //       );
    //     });
    //   } else {
    //     console.error(
    //       "The grid data sheet is not in the expected format:",
    //       gridDataSheet
    //     );
    //   }

    //   // Step 5: Write the workbook as a Blob and save it
    //   const buffer = await workbook.xlsx.writeBuffer();
    //   const blob = new Blob([buffer], { type: "application/octet-stream" });
    //   FileSaver.saveAs(blob, "ag-grid.xlsx");
    // };
    gridRef.current.api.exportDataToExcel(params);

    // performExport();
  }, [gridRef]);

  const exportDataToCSV = useCallback(() => {
    const params = {
      suppressQuotes: true, // Suppress quotes around cell values
      processHeaderCallback: (params) => {
        // Convert column headers to lowercase
        return params.column.getColDef().headerName.toLowerCase();
      },

      allColumns: true, // Include all columns in the export
      skipPinnedBottom: false, // Ensure pinned bottom rows (like grand total) are included

      processCellCallback: (params) => {
        // Convert cell values to lowercase if they are strings

        return params.value;
      },
    };
    gridRef.current.api.exportDataAsCsv(params);
  }, [gridRef]);

  const getGridState = () => {
    const gridApi = gridRef.current?.api;
    const columnApi = gridRef.current.columnApi;
    console.log("getting grid state:", gridApi, gridColumnApi);
    return {
      columnState: columnApi.getColumnState(),
      sortState: columnApi
        .getAllGridColumns()
        .map((col) => ({
          colId: col.getColId(),
          sort: col.getSort(),
        }))
        .filter((col) => col.sort),
      filterState: gridApi.getFilterModel(),
      pivotMode: columnApi.isPivotMode(),
      rowGroupColumns: columnApi.getRowGroupColumns().map((col) => col.colId),
      pivotColumns: columnApi.getPivotColumns().map((col) => col.colId),
    };
  };

  const saveGridView = async (viewName, moduleId) => {
    console.log("saving veiw");
    const gridState = getGridState();
    console.log("grid state", gridState);
    const payload = {
      name: viewName,
      gridState: gridState,
      moduleId: moduleId,
      workspaceId: props.currentWorkspaceId,
    };
    const response = await apiPost(API_ENDPOINT_AG_TABLE_SAVE_VIEW, payload);
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Awesome👍 , your view is created",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not save view, please try again",
      });
    }
  };

  const updateGridView = async (viewId, moduleId) => {
    const gridState = getGridState();
    const payload = {
      viewId: viewId,
      gridState: gridState,
      moduleId: moduleId,
      workspaceId: props.currentWorkspaceId,
    };
    const response = await apiPut(
      `${API_ENDPOINT_AG_TABLE_UPDATE_VIEW}${viewId}/update`,
      payload
    );
    if (response.status) {
      messageApi.success({
        type: "success",
        content: "Awesome👍 , your view is updated",
      });
    } else {
      messageApi.error({
        type: "error",
        content: "Could not save view, please try again",
      });
    }
  };

  const loadGridView = async (viewId, moduleId) => {
    const gridApi = gridRef.current.api;
    const columnApi = gridRef.current.columnApi;
    setLoadingView(true);

    if (viewId === "DEFAULT") {
      if (columnApi) {
        gridApi.resetColumnState();

        columnApi.applyColumnState({
          state: initialColumnState,
          applyOrder: false,
        });
        setLoadingView(false);

        columnApi.setRowGroupColumns([]); // Set row group columns
        columnApi.setPivotColumns([]); // Set pivot columns
        columnApi.setPivotMode(false); // Set pivot mode
      }

      // Apply sort and filter states
      if (gridApi) {
        gridApi.setFilterModel({}); // Apply filter model

        gridApi.refreshClientSideRowModel(); // Refresh the grid to apply the sorting
      }

      return;
    }
    const response = await apiGet(
      `${API_ENDPOINT_AG_TABLE_LOAD_VIEW}${moduleId}/${viewId}`
    );
    if (response.status && response.data) {
      const view = response.data;

      // Apply column state
      if (columnApi) {
        columnApi.applyColumnState({
          state: view.state.columnState,
          applyOrder: true,
        }); // Apply column state
        columnApi.setRowGroupColumns(view.state.rowGroupColumns); // Set row group columns
        columnApi.setPivotColumns(view.state.pivotColumns); // Set pivot columns
        columnApi.setPivotMode(view.state.pivotMode); // Set pivot mode
      }

      // Apply sort and filter states
      if (gridApi) {
        gridApi.setFilterModel(view.state.filterState); // Apply filter model

        // Apply sorting manually
        view.state.sortState.forEach((sort) => {
          gridApi.getColumnState().forEach((column) => {
            if (column.colId === sort.colId) {
              column.sort = sort.sort;
            }
          });
        });
        gridApi.refreshClientSideRowModel(); // Refresh the grid to apply the sorting
      }
    }
    setLoadingView(false);
  };

  const getScrollPosition = () => {
    return gridRef.current && gridRef.current.api.getVerticalPixelRange();
  };
  const setScrollPosition = (position) => {
    return gridRef.current && gridRef.current.api.ensureIndexVisible(position);
  };

  const applyTransaction = (data) => {
    return gridRef.current && gridRef.current.api.applyTransaction(data);
  };

  const getFinalDataFromTable = (data) => {
    const updatedData = [];
    gridRef.current.api.forEachNode((node) => {
      updatedData.push(node.data);
    });
    return updatedData;
  };

  React.useImperativeHandle(ref, () => ({
    exportDataToExcel,
    exportDataToCSV,
    exportJSONData,
    getAllColumns,
    onTableSearch,
    updateGridView,
    getGridState,
    saveGridView,
    loadGridView,
    getScrollPosition,
    getFinalDataFromTable,
    applyTransaction,
    setScrollPosition,
    setFilterModel: (filterModel) => {
      gridRef.current.api.setFilterModel(filterModel);
    },
    setSortModel: (sortModel) => {
      if (gridRef.current) {
        gridRef.current.api.setSortModel(sortModel);
      }
    },
    applyColumnState: (sortModel) => {
      if (gridRef.current) {
        gridRef.current.api.applyColumnState({
          state: sortModel.map((s) => ({ colId: s.colId, sort: s.sort })),
        });
      }
    },
    setGroupModel: (groupModel) => {
      gridRef.current.columnApi.setRowGroupColumns(
        groupModel.map((group) => group.colId || group.field)
      );
    },
  }));

  const exportJSONData = () => {
    if (gridApi) {
      const rowData = [];
      gridApi.forEachNode((node) => rowData.push(node.data));

      const transformedData = rowData.map((row) => {
        const obj = {};
        props.columnDefs.forEach((column) => {
          obj[column.field] = row[column.field];
        });
        return obj;
      });

      return transformedData;
    }
  };

  const handleColumnVisibilityChange = () => {
    const columnState = gridApi.getColumnState();
    const groupedColumn = columnState.find((column) => column.hide);
    props.onColGroup && props.onColGroup(groupedColumn);
    // setGroupedColumn(groupedColumn ? groupedColumn.colId : null);
  };

  const getAllColumns = () => {
    console.log("all columsn", gridRef.current);
    // const allColumns = gridRef.current.api.getAllColumns();
    // console.log(allColumns);
  };

  const statusBar = {
    statusPanels: [
      {
        statusPanel: "agTotalAndFilteredRowCountComponent",
        align: "left",
      },
      {
        statusPanel: "agTotalRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agFilteredRowCountComponent",
        align: "center",
      },
      {
        statusPanel: "agSelectedRowCountComponent",
        align: "right",
      },
      {
        statusPanel: "agAggregationComponent",
        align: "right",
      },
    ],
  };

  const sideBar = useMemo(
    () => ({
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
        {
          id: "pdfRenderer",
          labelDefault: "PDF View",
          labelKey: "pdfRenderer",
          iconKey: "pdf-file",
          width: 500,
          isOpen: true,
          toolPanel: props.pdfV2 ? PDFViewerToolV2 : PDFViewerTool,
          toolPanelParams: {
            dataKey: props.pdfDataKey,
            pdfData: props.pdfData,
          },
        },
        {
          id: "crmTransfer",
          labelDefault: "CRM Transfer",
          labelKey: "crmTransfer",
          width: 500,
          toolPanel: CRMTransfers,
        },
      ],
    }),
    []
  );

  const onTableSearch = (event) => {
    gridRef.current && gridRef.current.api.setQuickFilter(event.target.value);
  };

  const getRowId = (params) => params.data?.[props.rowIdKey]; // Assuming 'id' is unique for each row

  const excelStyles = useMemo(() => {
    return [
      {
        id: "header",
        interior: {
          color: "#f5f6f9", // Gray background
          pattern: "Solid",
        },
        font: {
          bold: true,
          size: 12, // Font size
          color: "#283b5a", // Blue font color
        },
        alignment: {
          horizontal: "Center",
          vertical: "Center",
        },
      },
      {
        id: "hyperlinks",
        font: {
          underline: "Single",
          color: "#358ccb",
        },
      },
      {
        id: "coverSubHeader",
        interior: {
          color: "#2c3e50", // Gray background
          pattern: "Solid",
        },
        font: {
          bold: true,
          size: 16, // Font size
          color: "#ffffff", // Blue font color
        },
        border: {
          top: { style: "thin", color: "#000000" },
          // bottom: { style: 'thin', color: '#000000' },
          // // left: { style: 'thin', color: '#000000' },
          right: { style: "thin", color: "#000000" },
        },
        alignment: {
          horizontal: "Left",
          vertical: "Center",
        },
      },
      {
        id: "coverHeading",
        interior: {
          color: "#2c3e50", // Gray background
          pattern: "Solid",
        },
        font: {
          bold: true,
          size: 26, // Font size
          color: "#ffffff", // Blue font color
        },
        alignment: {
          horizontal: "Center",
          vertical: "Center",
        },
      },
      {
        id: "coverText",
        font: {
          size: 14,
        },
      },
    ];
  }, []);

  return (
    <div className="AgTable">
      {props.showExport || props.leftTopActions || props.rightTopActions ? (
        <div className="TableActionContainer">
          <div className="LeftContainer">{props.leftTopActions}</div>
          <div className="RightContainer">
            {props.rightTopActions}
            <div className="ActionButton">
              {props.showExport ? (
                <Button
                  type="primary"
                  icon={<DownloadOutlined />}
                  shape="round"
                  size="small"
                  style={{
                    fontSize: 12,
                  }}
                  id="agTableExportButton"
                  onClick={exportDataToExcel}
                >
                  {props.exportLabel || "Export"}
                </Button>
              ) : null}
            </div>
          </div>
        </div>
      ) : null}
      <div className="ag-theme-alpine">
        <div style={{ height: "100%", position: "relative" }}>
          {loadingView ? (
            <div className="CustomLoading">
              <Loader />
            </div>
          ) : null}
          <AgGridReact
            getRowId={props.useRowId && getRowId}
            rowData={props.rowData}
            columnDefs={processColumnDefs(props.columnDefs) || []}
            defaultColDef={{
              sortable: true,
              filter: true,
              resizable: props.autoResize,
            }}
            pagination={!props.footer ? false : true}
            paginationPageSize={1000}
            rowSelection="multiple"
            suppressCellSelection={true}
            resizable={true}
            enablePivot={true}
            ref={gridRef}
            enableSorting={true}
            enableFilter={true}
            statusBar={props.showStatusBar && statusBar}
            groupMultiAutoColumn={true}
            rowGroupPanelShow={props.groupPanel ? "always" : "never"}
            pivotPanelShow="always"
            onGridReady={onGridReady}
            grandTotalRow={props.grandTotalRow}
            pivotColumnGroupTotals="before"
            onCellValueChanged={onCellValueChanged}
            onRowValueChanged={onRowValueChanged}
            editType={"fullRow"}
            // excelStyles={excelStyles}
            onColumnVisible={handleColumnVisibilityChange}
            suppressClickEdit={true}
            suppressRowVirtualisation={props.disableLazy}
            autoSizeStrategy={
              props.autoResize
                ? {
                    type: "fitGridWidth",
                    defaultMinWidth: 100,
                  }
                : {}
            }
            rowDragManaged={props.enableDragging}
            animateRows={props.enableDragging}
            onRowDragEnd={(event) => {
              const allRows = [];
              const rowCount = event.api.getDisplayedRowCount();

              for (let i = 0; i < rowCount; i++) {
                const rowNode = event.api.getDisplayedRowAtIndex(i);
                allRows.push(rowNode.data);
              }

              console.log("Updated Row Data:", allRows);
            }}
            sideBar={props.hideToolbar ? {} : sideBar}
            autoGroupColumnDef={{
              headerName: "Group",
              cellRenderer: "agGroupCellRenderer",
              cellRendererParams: {
                innerRenderer: (params) => {
                  // Find the original column definition based on the field name
                  const originalColumn = props.columnDefs.find(
                    (colDef) => colDef.field === params.node.field
                  );
                  console.log("mainGroupRow:", params);
                  return (
                    <div
                      style={{
                        display: "flex",
                        width: "100%",
                        alignItems: "center",
                        justifyItems: "center",
                      }}
                    >
                      {handleAgCellDataFormat(
                        originalColumn?.formatType || "",
                        params.value,
                        originalColumn?.formatProps || "",
                        params.data || {}
                      )}
                      {`${
                        props.grandTotalRow && params.value === undefined
                          ? "Total"
                          : params.value === "NA"
                          ? "-"
                          : "-"
                      }`}
                    </div>
                  );
                },
              },
            }}
          />
        </div>
      </div>
      {contextHolder}
    </div>
  );
});
export default memo(AgTableClient);
