
import React from 'react';
import { SimpleTreeView, TreeItem } from '@mui/x-tree-view';
import { Typography } from '@mui/material';
import './Document.css';

const Document = ({ documents }) => {
  console.log("***************");
  console.log(documents, "documents");
  console.log(typeof(documents), "documents type");

  // Recursive function to render tree nodes
  const renderTree = (node, nodeIdPrefix = '') => {
    return Object.entries(node || {}).map(([key, value], index) => {
      const validKey = key || `unknown-key-${index}`;
      const nodeId = `${nodeIdPrefix}-${validKey}-${index}`;
      console.log("node id", nodeId);
      const isNested = typeof value === 'object' && value !== null;
      console.log(`Node: ${key}, Is Nested: ${isNested}`);

      return (
        <TreeItem
          key={nodeId}
          nodeId={nodeId}
          label={
            <Typography variant="body2">
              <strong>{key}:</strong> {isNested ? '' : JSON.stringify(value)}
            </Typography>
          }
          itemId={nodeId}
          className={isNested ? 'has-children' : ''}
        >
          {isNested && renderTree(value, nodeId)}
        </TreeItem>
      );
    });
  };

  const renderDocuments = () => {
    if (Array.isArray(documents)) {
      return documents.map((doc, index) => {
        const docNodeId = `doc-${index}`;
        const isNestedDoc = typeof doc === 'object' && doc !== null;
        console.log(isNestedDoc, "Nested doc");
        return (
          <TreeItem
            key={docNodeId}
            nodeId={docNodeId}
            label={`Document ${index + 1}`}
            itemId={docNodeId}
            className={isNestedDoc ? 'has-children' : ''}
          >
            {isNestedDoc ? renderTree(doc, docNodeId) : <Typography variant="body2">{doc}</Typography>} {/* Render nested or simple object */}
          </TreeItem>
        );
      });
    }
    if (typeof documents === 'string') {
      return (
        <TreeItem nodeId="doc-0" itemId='doc-0' label={documents} />
      );
    }

    return <TreeItem nodeId="doc-unknown" itemId="doc-0" label="No documents available" />;
  };

  return (
    <SimpleTreeView
      style={{ overflowY: 'auto', maxHeight: '80vh', padding: '20px', background: '#f7f7f7' }}
    >
      {renderDocuments()}
    </SimpleTreeView>
  );
}

export default Document;



