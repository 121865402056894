import PageHeader from "app/shared/PageHeader";
import { useLocation } from "react-router-dom";
import "./ReportLegacyView.scss";
import { useEffect, useState } from "react";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
export default function ReportLegacyView(props: any) {
  const location = useLocation();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [updatedLink, setUpdatedLink] = useState("");
  const { report_link, zoho_criteria } = location.state;

  const fetchLegacyReport = async () => {
    let link = report_link;
    if (userDetails.currentWorkspace?.name.includes("Admin")) {
      setUpdatedLink(link);
    } else {
      // Encode the workspace name to make it URL-safe
      const encodedWorkspaceName = encodeURIComponent(
        userDetails.currentWorkspace?.name
      );

      // Construct the new URL by adding the `ZOHO_CRITERIA` parameter
      const actualUrl = `${link}?ZOHO_CRITERIA=%22${
        zoho_criteria || "workspace"
      }%22%3D%27${encodedWorkspaceName}%27`;
      console.log("updatedLink:", actualUrl);

      setUpdatedLink(actualUrl);
    }
  };
  useEffect(() => {
    fetchLegacyReport();
  }, [userDetails.currentWorkspace]);
  return (
    <div className="ReportLegacyView" style={{ height: "100%" }}>
      <div className="PageHeaderContainer">
        <PageHeader title="Report View" goBack />
      </div>
      <div className="MainContainer">
        <iframe
          height="100%"
          style={{ width: "100%" }}
          scrolling="no"
          title="Iframe"
          src={updatedLink || report_link}
        ></iframe>
      </div>
    </div>
  );
}
