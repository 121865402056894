import Typography from "app/shared/Typography";
import "./GSTAddReviewFinal.scss";
import { colorPicker } from "app/utils/color.helper";

import {
  Button,
  Checkbox,
  message,
  Segmented,
  Statistic,
  StatisticProps,
  Tag,
  Upload,
  Modal,
} from "antd";
import {
  SwapOutlined,
  DownloadOutlined,
  InfoCircleOutlined,
  ArrowRightOutlined,
  SendOutlined,
  LoadingOutlined,
  QuestionCircleOutlined,
  DeleteOutlined,
  CheckCircleFilled,
  CloseCircleFilled,
} from "@ant-design/icons";

import { AgTableClient } from "app/shared/AgTable";
import { STATE_GSTIN_ICON } from "app/shared/AgTable/stateIconMap";
import Uploader from "app/shared/Uploader";
import { useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
  API_ENDPOINT_GST_CREDENTIAL_ADD_TEMP_CREDS,
  API_ENDPOINT_GST_CREDENTIAL_BULK_CREATE_NEW,
  API_ENDPOINT_GST_CREDENTIAL_ENTITY_INFO,
} from "../../gstcredflow.constants";
import { apiGet, apiPost, apiPut } from "app/services/apiServices";
import CountUp from "react-countup";
import * as csv from "csvtojson";
import * as XLSX from "xlsx";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import AppLogo from "static/images/FinLogo.png";
import color from "theme/color";
import TextArea from "antd/es/input/TextArea";
import checks from "../../../AirlineCredentialFlow/components/AirlineConfirmPage/checks.svg";
import select from "../../../AirlineCredentialFlow/components/AirlineConfirmPage/select.svg";
import Logo from "static/images/Finkr@ftLogo.png";
import { downloadSampelCrendentialCSV } from "app/utils/generic.helper";
import {
  uploadFileToFirebase,
  uploadJsonToFirestore,
} from "app/config/firebase";
import {
  API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST,
  API_ENDPOINT_GST_CREDENTIAL_UPDATE,
} from "app/scenes/Credentials/credentials.constants";
import AgTableEditButton from "app/shared/AgTable/AgTableClient/HelperTools/EditComponent";
import Loader from "app/shared/Loader";

export default function GSTListView(props: any) {
  const location = useLocation();
  const stateInfo = location.state || "";
  const [gstinData, setGSTINData] = useState<any[]>([]);
  const [totalPan, setTotalPan] = useState<any[]>([]);
  const navigate = useNavigate();
  const [isLoading, setLoading] = useState(false);
  const [messageApi, contextHolder] = message.useMessage();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [tableData, setTableData] = useState<any[]>([]);
  const [userDetails, _] = useRecoilState<any>(userInfo);
  const [gstSummary, setGSTSummary] = useState<any>({});
  const gridRef: any = useRef();
  useEffect(() => {
    fetchGSTCredentials();
  }, []);

  const formatter: StatisticProps["formatter"] = (value) => (
    <CountUp end={value as number} separator="," />
  );

  function extractPANFromGSTIN(gstin: string) {
    if (gstin.length === 15) {
      return gstin.substring(2, 12); // Characters 3 to 12
    } else {
      throw new Error(
        "Invalid GSTIN length. GSTIN must be 15 characters long."
      );
    }
  }

  const createGSTSummary = (gstList: any) => {
    let summaryInfo = { pan: 0, gstin: 0, active: 0, failed: 0, pending: 0 };
    let uniquePan: any = [];

    gstList.forEach((gstInfo: any) => {
      let extractedPAN = extractPANFromGSTIN(gstInfo.gstin);
      if (gstInfo.status === "ACTIVE") {
        summaryInfo.active += 1;
      }
      if (gstInfo.status === "INVALID") {
        summaryInfo.failed += 1;
      }
      if (gstInfo.status === "PENDING") {
        summaryInfo.pending += 1;
      }
      summaryInfo.gstin += 1;

      if (!uniquePan.includes(extractedPAN)) {
        summaryInfo.pan += 1;
      }
    });
    setGSTSummary(summaryInfo);
  };
  const fetchGSTCredentials = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_FETCH_GST_CREDENTIALS_LIST);
    if (response.status) {
      setTableData(response.data?.credentials);
      createGSTSummary(response.data?.credentials || []);
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
    setLoading(false);
  };

  const showModal = () => setIsModalVisible(true);

  const handleModalClose = () => setIsModalVisible(false);

  const issues = [
    "Issue_01_regarding some common thing",
    "Issue_02_regarding GST cred",
    "Issue_03_regarding Airline",
    "Issue_04_regarding sign up",
    "Issue_05_regarding some common thing",
    "Custom",
  ];
  const handleBack = () => {
    navigate(-1);
  };

  const updateGSTCredential = async (rowPayload: any) => {
    const response = await apiPut(API_ENDPOINT_GST_CREDENTIAL_UPDATE, {
      credentials: rowPayload,
    });
    if (response.status) {
      messageApi.success({
        type: "success",
        content: response.message,
      });
      fetchGSTCredentials();
    } else {
      messageApi.error({
        type: "error",
        content: response.message,
      });
    }
  };

  const handleRowEdit = (rowData: any) => {
    updateGSTCredential(rowData);
  };

  console.log("workspaceinfo", userDetails);

  return (
    <div className="GSTAddReviewFinal">
      <div
        className="MainContainer"
        style={{ display: "flex", flexDirection: "column" }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            margin: "5px 30px",
          }}
        >
          <div
            style={{ color: "#000", cursor: "pointer" }}
            onClick={handleBack}
          >
            Back
          </div>
        </div>
        <div className="TopLogo">
          <img alt="AppLogo" src={Logo} />
          {/* <div className="MiniDivder" /> */}
          <Typography
            style={{
              // marginBottom: '2px',
              color: colorPicker("neutral.700"),
              textAlign: "right",
            }}
          >
            {/* Having trouble?{" "}
            <a
              style={{ color: colorPicker("primary.700"), cursor: "pointer" }}
              onClick={showModal}
            >
              Get help
            </a> */}
          </Typography>
        </div>

        <div className="RightContainer">
          <Tag color="cyan">GSTIN credentials</Tag>
          <Typography variant="h5" style={{ marginTop: 12, color: "#212121" }}>
            Credential Summary
          </Typography>

          <div className="SummaryContainer">
            <div
              className="SummaryCard"
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <Typography
                variant="h4"
                style={{ color: colorPicker("primary.700") }}
                weight={600}
              >
                <Statistic value={gstSummary?.pan} formatter={formatter} />
              </Typography>
              <Typography variant="caption">Number of PAN</Typography>
            </div>

            <div
              className="SummaryCard"
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <Typography
                variant="h4"
                style={{ color: colorPicker("primary.700") }}
                weight={600}
              >
                <Statistic value={gstSummary?.gstin} formatter={formatter} />
                {/* {gstinData.length} */}
              </Typography>
              <Typography variant="caption">Total GSTIN</Typography>
            </div>

            <div
              className="SummaryCard"
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <Typography
                variant="h4"
                style={{ color: colorPicker("primary.700") }}
                weight={600}
              >
                <Statistic value={gstSummary?.active} formatter={formatter} />
              </Typography>
              <Typography variant="caption">Active</Typography>
            </div>

            <div
              className="SummaryCard"
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <Typography
                variant="h4"
                style={{ color: "#d35400" }}
                weight={600}
              >
                <Statistic value={gstSummary?.pending} formatter={formatter} />
              </Typography>
              <Typography variant="caption" style={{ color: "#d35400" }}>
                Pending
              </Typography>
            </div>

            <div
              className="SummaryCard"
              style={{ display: "flex", alignItems: "center", gap: "15px" }}
            >
              <Typography
                variant="h4"
                style={{ color: "#E53232" }}
                weight={600}
              >
                <Statistic value={gstSummary?.failed} formatter={formatter} />
              </Typography>
              <Typography variant="caption" style={{ color: "#E53232" }}>
                Failed
              </Typography>
            </div>

            <div
              className="SummaryCard"
              style={{
                display: "flex",
                alignItems: "center",
                gap: "15px",
                background: colorPicker("primary.700"),
                cursor: "pointer",
              }}
              onClick={() => navigate("/credential/gst/add/review/final")}
            >
              <Typography variant="caption" style={{ color: "white" }}>
                Add More
              </Typography>
            </div>
          </div>
          <div className="TableContainer">
            {isLoading ? (
              <Loader />
            ) : (
              <AgTableClient
                // @ts-ignore
                rowData={tableData}
                // ref={gridRef}
                columnDefs={[
                  {
                    field: "gstin",
                    headerName: "GSTIN",
                    editable: true,
                    enableRowGroup: true,
                    cellRenderer: (params: any) => {
                      return (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <img
                            style={{ width: 24, marginRight: 6 }}
                            src={
                              STATE_GSTIN_ICON[
                                params?.data?.gstin?.substring(0, 2)
                              ]
                            }
                          />
                          {params.data?.gstin}
                        </div>
                      );
                    },
                  },
                  { field: "username", headerName: "Username", editable: true },
                  { field: "password", headerName: "Password", editable: true },
                  {
                    field: "status",
                    headerName: "Status",
                    enableRowGroup: true,
                    minWidth: 200,
                    formatType: "CUSTOM_RENDER",
                    formatProps: {
                      cellRenderer: (data: any, params: any) => {
                        return params.status === "ACTIVE" ? (
                          <Tag color="green" icon={<CheckCircleFilled />}>
                            Active
                          </Tag>
                        ) : params.status === "INVALID" ? (
                          <Tag color="red" icon={<CloseCircleFilled />}>
                            Invalid
                          </Tag>
                        ) : params.username ? (
                          <Tag
                            color="orange"
                            icon={
                              <LoadingOutlined
                                style={{
                                  fontSize: 12,
                                  marginRight: 6,
                                  color: "rgb(143 121 24)",
                                }}
                                spin
                              />
                            }
                          >
                            Pending
                          </Tag>
                        ) : (
                          <Tag
                            color={
                              data === "ACTIVE"
                                ? "green"
                                : data === "INVALID"
                                ? "red"
                                : "orange"
                            }
                          >
                            {data || "NA"}
                          </Tag>
                        );
                      },

                      valueGetterData: (params: any) => {
                        return params.status;
                      },
                    },
                  },

                  {
                    field: "action",
                    headerName: "Action",
                    enableRowGroup: true,
                    editRow: true,
                    focusKey: "username",
                    cellRenderer: AgTableEditButton,
                    cellRendererParams: {
                      onClick: (params: any) => console.log(params), // Define your edit logic here
                    },
                  },
                ]}
                hideToolbar
                autoResize
                rowIdKey={"id"}
                useRowId
                onRowSaved={handleRowEdit}
                groupPanel
              />
            )}
          </div>
        </div>
      </div>
      {contextHolder}
    </div>
  );
}
