import TextField from "app/shared/TextField";
import React, { useEffect, useState } from "react";
import "./InviteMember.scss";
import { Avatar, Button, Popover, Select, Tag, message } from "antd";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/color.helper";
import { cursorTo } from "readline";
import {
  EditOutlined,
  CloseOutlined,
  DeleteTwoTone,
  ClusterOutlined,
  GlobalOutlined,
} from "@ant-design/icons";
import MemberSearch from "app/shared/MemberSearch";
import { useRecoilState } from "recoil";
import { userInfo } from "app/config/States/users";
import { apiPost, apiPut } from "app/services/apiServices";
import {
  API_ENDPOINT_SETTING_INVITE_MEMBER,
  API_ENDPOINT_SETTING_MEMBER_UPDATE_WORKSPACE_ROLE,
} from "../../settings.constants";
const OPTIONS = [
  "Apples",
  "Nails",
  "Bananas",
  "Helsicopters",
  "Appales",
  "Naisls",
  "Bansanas",
  "Heliacopters",
];
// const filteredOptions = OPTIONS.filter((o) => !selectedItems.includes(o));

export default function UpdateMember(props: any) {
  const [memberInfo, setMemberInfo] = useState<any>(null);
  const [messageApi, contextHolder] = message.useMessage();
  const [userDetails, setUserDetails] = useRecoilState<any>(userInfo);
  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  const [newRole, setNewRole] = useState("");
  const [newMainRole, setNewMainRole] = useState("");
  const [userName, setUserName] = useState("");
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    setUserName(props.data.name || props.data.email);
  }, [props.data]);

  const validateEmail = (email: string) => {
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };
  console.log("props.data;", props.data);
  const handleUpdateMember = async () => {
    const payload = {
      role: newRole || props.data?.workspacerole,
      accountType: newMainRole || props.data?.account_type,
      name: userName || props.data.email,
    };
    const response = await apiPut(
      API_ENDPOINT_SETTING_MEMBER_UPDATE_WORKSPACE_ROLE + props.data?.id,
      payload
    );
    if (response.status) {
      message.success("Member updated");
      props.success && props.success();
    } else {
      message.error("Could not update member info");
    }
  };

  console.log("props:", props);

  return (
    <div className="InviteMember">
      <div className="AddUserContainer" style={{ marginBottom: 12 }}>
        <TextField
          placeholder="Name"
          value={userName}
          defaultValue={userName}
          style={{ height: 34 }}
          onChange={(e: any) => setUserName(e.target.value)}
        />
      </div>

      <div className="AddUserContainer">
        <TextField
          placeholder="Email"
          defaultValue={props.data?.email}
          style={{ height: 34 }}
          disabled
        />
      </div>
      {userDetails.currentWorkspace.role === "ADMIN" &&
      userDetails.account_type === "PARTNER" ? (
        <>
          <Typography style={{ marginTop: 16 }} variant="xs">
            Workspace Role
          </Typography>

          <Select
            style={{ width: "100%", marginTop: 2 }}
            value={newRole || props.data?.workspacerole}
            onChange={(item: any) => setNewRole(item)}
          >
            <Select.Option value="GENERAL">User</Select.Option>
            <Select.Option value="ADMIN">Admin</Select.Option>
          </Select>
        </>
      ) : null}

      {userDetails.currentWorkspace?.role === "ADMIN" &&
      userDetails.account_type === "PARTNER" ? (
        <>
          <Typography style={{ marginTop: 16 }} variant="xs">
            Account Type
          </Typography>

          <Select
            style={{ width: "100%", marginTop: 2 }}
            value={newMainRole || props.data?.account_type}
            onChange={(item: any) => setNewMainRole(item)}
          >
            <Select.Option value="PARTNER">Partner</Select.Option>
            <Select.Option value="CLIENT">Client</Select.Option>
          </Select>
        </>
      ) : null}

      {/* <Select
        style={{ width: "100%", marginTop: 16 }}
        value={newMainRole || props.data?.mainrole}
        onChange={(item: any) => setNewMainRole(item)}
      >
        <Select.Option value="GENERAL">General</Select.Option>
        <Select.Option value="ADMIN">Admin</Select.Option>
      </Select> */}
      <div className="BottomAction">
        <Button onClick={props.onClose}>Cancel</Button>
        <Button
          type="primary"
          style={{ marginLeft: 6 }}
          onClick={handleUpdateMember}
          loading={isLoading}
        >
          Add
        </Button>
      </div>
      {contextHolder}
    </div>
  );
}
